import React from 'react'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-autosize-textarea'
import _ from 'lodash'
import commentFeedbackMap from '../../helper/commentFeedbackMap'
import commentSubFieldsMap from '../../helper/commentSubFieldsMap'
import CommentSendBtn from '../../../../assets/svg/comment-send.svg'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import * as actionCreators from '../../../../store/actions'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class AddComment extends React.Component {
  constructor(props) {
    super(props)

    const { nodeDefId, newComments } = this.props

    this.state = {
      commentText: '',
      newCommentObj: newComments[nodeDefId],
    }
  }

  componentDidUpdate(oldProps) {
    const { nodeDefId, newComments } = this.props

    if (newComments !== oldProps.newComments) {
      // Disabling the following error check is not an issue according to the React doc
      // @see https://it.reactjs.org/docs/react-component.html#componentdidupdate
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ newCommentObj: newComments[nodeDefId] })
    }
  }

  handleCommentFocus = e => {
    e.stopPropagation()
    const { updateLockNodeCliksOnCommentFocus, nodeDefId } = this.props
    updateLockNodeCliksOnCommentFocus({ nodeDefId, status: 1 })
  }

  handleStopPropagation = e => {
    e.stopPropagation()
  }

  updateNewCommentValueInReduxOnly = (subField, value) => {
    const {
      nodeDefId,
      newComments,
      newCommentsForecast,
      scoreSet,
      updateNewCommentsObj,
      updateNewCommentsForecastObj,
    } = this.props

    const newCommentsList = scoreSet && scoreSet === 'forecast' ? { ...newCommentsForecast } : { ...newComments }

    const commentObj = newCommentsList[nodeDefId]

    if (subField === commentSubFieldsMap.text) commentObj.text = value
    else if (subField === commentSubFieldsMap.locale) commentObj.locale = value
    else if (subField === commentSubFieldsMap.feedback) commentObj.feedback = value

    if (scoreSet && scoreSet === 'forecast') updateNewCommentsForecastObj(newCommentsList)
    else updateNewCommentsObj(newCommentsList)
  }

  validateComment = () => {
    const { commentText } = this.state
    return !(!commentText || commentText === '')
  }

  handleCommentTextBlur = e => {
    const text = e.target.value
    this.updateNewCommentValueInReduxOnly(commentSubFieldsMap.text, text)
  }

  handleCommentLangSelected = e => {
    const { environment } = this.props
    const locale = _.find(environment.locales, item => item.code === e.target.value)
    this.updateNewCommentValueInReduxOnly(commentSubFieldsMap.locale, locale)
  }

  sendComment = async () => {
    const {
      device,
      scoreSet,
      nodeDefId,
      newComments,
      newCommentsForecast,
      scoringTree,
      moveNewCommentIntoStorageAndUpdateRedux,
      moveNewCommentForecastIntoStorageAndUpdateRedux,
      processSyncQueue,
    } = this.props

    if (!this.validateComment()) {
      return
    }

    if (scoreSet && scoreSet === 'forecast') {
      await moveNewCommentForecastIntoStorageAndUpdateRedux(scoringTree, newCommentsForecast, nodeDefId)
    } else {
      await moveNewCommentIntoStorageAndUpdateRedux(scoringTree, newComments, nodeDefId)
    }

    if (device.isOnline) {
      processSyncQueue()
    }

    this.setState({ commentText: '' })
  }

  handleSendCommentClicked = async e => {
    e.stopPropagation()
    await this.sendComment()
  }

  handleMarkBtnSelected = async (e, feedback) => {
    e.stopPropagation()
    this.updateNewCommentValueInReduxOnly(commentSubFieldsMap.feedback, feedback)

    await this.sendComment()
  }

  render() {
    const { device, texts, environment } = this.props
    const { commentText, newCommentObj } = this.state

    return (
      <div>
        <div
          className={classNames({
            sctree_rp_add_edit_comment_box: true,
          })}
        >
          <TextareaAutosize
            className={classNames({
              sctree_rp_add_edit_comment_textarea: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
            maxRows={5}
            value={commentText}
            placeholder={texts.add_comment}
            onKeyPress={e => this.handleStopPropagation(e)}
            onChange={e => {
              this.setState({ commentText: e.target.value })
            }}
            onClick={e => this.handleCommentFocus(e)}
            onBlur={this.handleCommentTextBlur}
          />
          <select
            className={classNames({
              va_select: true,
              sctree_rp_add_edit_comment_lang_select: true,
            })}
            value={newCommentObj[commentSubFieldsMap.locale].code}
            onChange={this.handleCommentLangSelected}
          >
            {environment.locales.map(locale => {
              return (
                <option key={uuidv4()} value={locale.code}>
                  {locale.code}
                </option>
              )
            })}
          </select>
          <div
            className={classNames({
              sctree_rp_add_edit_comment_feedback: true,
            })}
          >
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_positive: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  newCommentObj[commentSubFieldsMap.feedback] === commentFeedbackMap.positive,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => this.handleMarkBtnSelected(e, commentFeedbackMap.positive)}
              onKeyPress={e => this.handleMarkBtnSelected(e, commentFeedbackMap.positive)}
            />
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_neutral: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  newCommentObj[commentSubFieldsMap.feedback] === commentFeedbackMap.neutral,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => this.handleMarkBtnSelected(e, commentFeedbackMap.neutral)}
              onKeyPress={e => this.handleMarkBtnSelected(e, commentFeedbackMap.neutral)}
            />
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_negative: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  newCommentObj[commentSubFieldsMap.feedback] === commentFeedbackMap.negative,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => this.handleMarkBtnSelected(e, commentFeedbackMap.negative)}
              onKeyPress={e => this.handleMarkBtnSelected(e, commentFeedbackMap.negative)}
            />
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_item_node_exp_send_comment_btn_container: true,
            disabled: !this.validateComment(),
            theme_svg: true,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            this.handleSendCommentClicked(e)
          }}
          onKeyPress={e => {
            this.handleSendCommentClicked(e)
          }}
        >
          <CommentSendBtn />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    newComments: state.comments.newComments,
    newCommentsForecast: state.comments.newCommentsForecast,
    comments: state.comments.comments,
  }
}

export default connect(mapStateToProps, actionCreators)(AddComment)
