import React from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import * as actionCreators from '../../store/actions'
import LeftPanel from './_parts/leftPanel'
import RightPanel from './_parts/rightPanel'
import ItemsNavigator from './_parts/itemsNavigator'
import RightPanelProduct from './_parts/rightPanelProduct'
import ScoringSubHeader from './_parts/scoringSubHeader'
import screenTypesMap from '../../layout/_parts/screenTypesMap'
import ScoringModeMap from './helper/scoringModeMap'
import { checkIfScoringExistsOnServer, invisibleDownloadScoringFromServer } from '../../../va-corejs-v3/actions/scoring'
import { isEmptyObject } from '../../../va-corejs-v3/utils'
import { LoadingBar } from '../loading_bar'
import leftPanelModeMap from './helper/leftPanelModeMap'
import { isProjectModeEnabled } from './_parts/projectMode/utils'
import { updateScorePanelOnLocalStorage } from '../../../va-corejs-v3/actions/scoring_tree'

const queryString = require('query-string')
const classNames = require('classnames')

export class ScoringTreeComponent extends React.Component {
  state = {
    loaded: false,
  }

  componentDidMount = async () => {
    const {
      getScoringTreeById,
      updateScoringSelectedMode,
      updateDownloadedScorings,
      device,
      processSyncQueue,
      authentication,
      updateUserLastScoring,
    } = this.props

    if (typeof window !== 'undefined') {
      const qs = queryString.parse(window.location.search)
      if (qs.id) {
        // On refresh it check if scoring is new then store all nodes into sync_queue to sync them

        let scoringExistsOnServer = false

        if (device.isOnline) {
          scoringExistsOnServer = await checkIfScoringExistsOnServer(qs.id)
          if (scoringExistsOnServer) {
            await invisibleDownloadScoringFromServer(qs.id)
            await updateDownloadedScorings(qs.id)
          } else {
            processSyncQueue()
          }
        }

        await getScoringTreeById(qs.id)

        updateUserLastScoring(qs.id)

        if (qs.view) {
          switch (parseInt(qs.view, 10)) {
            case ScoringModeMap.product:
              await updateScoringSelectedMode(ScoringModeMap.product)
              break
            case ScoringModeMap.analytics:
              await updateScoringSelectedMode(ScoringModeMap.analytics)
              break
            case ScoringModeMap.clipboard:
              await updateScoringSelectedMode(ScoringModeMap.clipboard)
              break
            default:
              await updateScoringSelectedMode(ScoringModeMap.product)
          }
        }

        const { scoringTree, updateScoringIntoScoringTree } = this.props

        if (authentication.user.parsedPermissions.canEditProduct && !scoringTree.scoring.is_locked) {
          updateScoringIntoScoringTree(await updateScorePanelOnLocalStorage(scoringTree, scoringExistsOnServer))

          if (device.isOnline) {
            processSyncQueue()
          }
        }
      }
    }

    this.setState({ loaded: true })
  }

  render() {
    const { loaded } = this.state
    const { scoringTree, device, texts } = this.props
    const leftPanelViewMode =
      scoringTree.scoringMode === ScoringModeMap.scoring ? leftPanelModeMap.full : leftPanelModeMap.light

    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    return (
      <div>
        {!loaded && (
          <div
            className={classNames({
              scoring_mode_loader_outer_container: true,
            })}
          >
            <div
              className={classNames({
                scoring_mode_loader_inner_container: true,
              })}
            >
              <LoadingBar />
            </div>
          </div>
        )}
        {loaded && !isEmptyObject(scoringTree.scoring) && (
          <div>
            <div>
              <ScoringSubHeader />
            </div>
            <div
              className={classNames({
                scoring_mode_container: true,
                scoring_project_mode_container: isProjectMode,
              })}
            >
              <div
                className={classNames({
                  scoring_tree_left_panel_conainer: true,
                  scoring_tree_left_panel_conainer_with_sticky: device.isStickyHeader,
                  scoring_tree_left_panel_container_visible:
                    scoringTree.sidebarVisible && scoringTree.scoringMode !== ScoringModeMap.product,
                  scoring_tree_left_panel_container_hidden:
                    !scoringTree.sidebarVisible ||
                    scoringTree.scoringMode === ScoringModeMap.product ||
                    scoringTree.scoringMode === ScoringModeMap.clipboard,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <LeftPanel leftPanelViewMode={leftPanelViewMode} />
              </div>
              <div
                className={classNames({
                  sctree_rp_container: true,
                  sctree_rp_container_with_sticky: device.isStickyHeader,
                  sctree_rp_head_full_width:
                    !scoringTree.sidebarVisible ||
                    scoringTree.scoringMode === ScoringModeMap.product ||
                    scoringTree.scoringMode === ScoringModeMap.clipboard,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <>
                  {scoringTree.scoringMode === ScoringModeMap.scoring && <RightPanel />}
                  {scoringTree.scoringMode !== ScoringModeMap.scoring && <RightPanelProduct />}
                </>
              </div>
              {scoringTree.scoringMode === ScoringModeMap.scoring && <ItemsNavigator />}
            </div>
          </div>
        )}
        {loaded && isEmptyObject(scoringTree.scoring) && (
          <div className={classNames({ scoring_not_found_container: true })}>
            <div className={classNames({ scoring_not_found_404: true })}>{texts.error_404}</div>
            <div className={classNames({ scoring_not_found_message: true })}>{texts.product_not_found_message}</div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringTreeComponent)
