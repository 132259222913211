import React from 'react'
import { connect } from 'react-redux'

import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'
import {
  getProductName,
  calculatePopupChartHeight,
  getCriterionTypeList,
  getCriterionTypeShortLabels,
  doCalculationForAnalytics,
} from './common/utils'

const classNames = require('classnames')

export class CTypeGlobalPerformanceChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      productName: '',
      cTypesPerPerimeter: {},
      data: [],
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  filterCriterionTypeForFamilies = async ctype => {
    const { scoringTree, environment } = this.props
    const { nodeDefsObj } = scoringTree

    const { nodes } = scoringTree.scoring
    const filteredNodesInterior = { ...nodes }
    const filteredNodesExterior = { ...nodes }

    const idList = Object.keys(nodes)
    const nodeToExcludeInterior = []
    const nodeToExcludeExterior = []
    const nodeToExclude = []

    const perimeters = []

    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def

      if (def.parent_id === null) perimeters.push(nodeId)

      if (type === nodeDefinitionTypeMap.criterion) {
        const itemDef = nodeDefsObj[def.parent_id]
        const subFamDef = nodeDefsObj[itemDef.parent_id]
        const famDef = nodeDefsObj[subFamDef.parent_id]
        const familyDefName = famDef.name[environment.defaultLang]
        const nodeType = def.criterion_template.slug

        if (familyDefName.indexOf('Interior') === 0) {
          nodeToExcludeExterior.push(nodeId)
        }

        if (familyDefName.indexOf('Exterior') === 0) {
          nodeToExcludeInterior.push(nodeId)
        }

        if (familyDefName.indexOf('Cargo') === 0) {
          nodeToExcludeInterior.push(nodeId)
          nodeToExcludeExterior.push(nodeId)
        }

        if (nodeType !== ctype) {
          nodeToExclude.push(nodeId)
        }

        // const shortLabel = cTemplate.short_label[environment.defaultLang]
        // shortLabelList[ctype] = shortLabel
      }
    }

    for (let i = 0; i !== nodeToExclude.length; i += 1) {
      const nodeId = nodeToExclude[i]
      delete filteredNodesInterior[nodeId]
      delete filteredNodesExterior[nodeId]
    }

    for (let i = 0; i !== nodeToExcludeInterior.length; i += 1) {
      const nodeId = nodeToExcludeInterior[i]
      delete filteredNodesInterior[nodeId]
    }

    for (let i = 0; i !== nodeToExcludeExterior.length; i += 1) {
      const nodeId = nodeToExcludeExterior[i]
      delete filteredNodesExterior[nodeId]
    }

    await doCalculationForAnalytics(nodeDefsObj, filteredNodesInterior)
    const interiorPerformance = await this.extractPerformanceValue(perimeters, filteredNodesInterior)

    await doCalculationForAnalytics(nodeDefsObj, filteredNodesExterior)
    const exteriorPerformance = await this.extractPerformanceValue(perimeters, filteredNodesExterior)

    return {
      criteriaType: ctype,
      interiorPerformance,
      exteriorPerformance,
    }
  }

  extractPerformanceValue = async (perimeters, nodes) => {
    const { cTypesPerPerimeter } = this.state
    const { scoringTree } = this.props
    const { nodeDefsObj } = scoringTree

    // let sum = 0
    let initialSum = 0
    let detailedSum = 0
    for (let i = 0; i !== perimeters.length; i += 1) {
      const p = perimeters[i]
      const pDef = nodeDefsObj[p]
      const pName = pDef.name.en
      const { percentage } = nodes[p]
      if (pName.indexOf('Initial') === 0) {
        initialSum += percentage
      } else if (pName.indexOf('Detailed') === 0) {
        detailedSum += percentage
      }
      // sum += percentage
    }

    const parsedInitalSum = (initialSum * cTypesPerPerimeter.detailed.length) / cTypesPerPerimeter.initial.length

    // const performance = sum / perimeters.length
    const performance = (parsedInitalSum + detailedSum) / perimeters.length
    return performance
  }

  recursivelyFilterCriterionType = async (cTypeList, trees) => {
    if (cTypeList.length === 0) return

    const ctype = cTypeList.pop()
    const filteredNodesTree = await this.filterCriterionTypeForFamilies(ctype)
    trees[ctype] = filteredNodesTree

    await this.recursivelyFilterCriterionType(cTypeList, trees)
  }

  groupCriterionTypePerPerimeter = () => {
    const { scoringTree } = this.props
    const { nodeDefsObj } = scoringTree

    const { nodes } = scoringTree.scoring
    const idList = Object.keys(nodes)

    const criterionTypePerPerimeter = {
      initial: [],
      detailed: [],
    }

    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def

      if (type === nodeDefinitionTypeMap.criterion) {
        const itemDef = nodeDefsObj[def.parent_id]
        const subFamDef = nodeDefsObj[itemDef.parent_id]
        const famDef = nodeDefsObj[subFamDef.parent_id]
        const perimeterDef = nodeDefsObj[famDef.parent_id]
        const nodeType = def.criterion_template.slug

        if (perimeterDef.name.en.indexOf('Initial') === 0) {
          if (!criterionTypePerPerimeter.initial.includes(nodeType)) {
            criterionTypePerPerimeter.initial.push(nodeType)
          }
        } else if (perimeterDef.name.en.indexOf('Detailed') === 0) {
          if (!criterionTypePerPerimeter.detailed.includes(nodeType)) {
            criterionTypePerPerimeter.detailed.push(nodeType)
          }
        }
      }
    }

    return criterionTypePerPerimeter
  }

  componentDidMount = async () => {
    this.setState({ loading: true })

    const { scoringTree, environment } = this.props
    const { props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setState({ productName })

    const shortLabels = await getCriterionTypeShortLabels(scoringTree, environment.defaultLang)

    const cTypesPerPerimeter = this.groupCriterionTypePerPerimeter()
    this.setState({ cTypesPerPerimeter })

    const criterionTypeList = getCriterionTypeList()
    const cTypeListDuplicate = [...criterionTypeList]
    const treesByCType = {}
    await this.recursivelyFilterCriterionType(cTypeListDuplicate, treesByCType)

    const data = []
    for (let i = 0; i !== criterionTypeList.length; i += 1) {
      const cType = criterionTypeList[i]
      const { criteriaType, interiorPerformance, exteriorPerformance } = treesByCType[cType]
      data.push({
        type: shortLabels[criteriaType],
        exteriorPerformance,
        interiorPerformance,
      })
    }

    this.setState({ loading: false, data })
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const { loading, showPopup, productName, data } = this.state

    const renderLegend = props => {
      const { payload } = props
      return (
        <div className={classNames({ custom_legends_container: true })}>
          {payload.map((entry, index) => (
            <div key={`legend_${index}`} className={classNames({ custom_legend_item: true })}>
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames({
                  custom_legend_marker: true,
                  small_dot_marker: true,
                })}
              />
              <div className={classNames({ custom_legend_value: true })}>
                {entry.value.indexOf('exterior') === 0 ? 'Exterior' : 'Interior'}
              </div>
            </div>
          ))}
        </div>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer width={width} height={height}>
          <RadarChart data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="type" />
            <PolarRadiusAxis angle={73} domain={[0, 100]} />
            <Radar
              dataKey="exteriorPerformance"
              stroke="#b536ff"
              strokeWidth="2.5"
              fill="transparent"
              fillOpacity={1}
            />
            <Radar
              dataKey="interiorPerformance"
              stroke="#39eb9d"
              strokeWidth="2.5"
              fill="transparent"
              fillOpacity={1}
            />
            <Legend content={renderLegend} />
          </RadarChart>
        </ResponsiveContainer>
      )
    }

    return (
      <>
        {(loading === true || Object.keys(data).length === 0) && (
          <div
            className={classNames({
              chart_loading_container: true,
              ctype_global_performance_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              ctype_global_performance_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', 230)}</div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={`${productName} : ${texts.percentage_performance_per_criteria_type}`}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CTypeGlobalPerformanceChart)
