import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class CanvasImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: null,
    }
  }

  componentDidMount = async () => {
    await this.setUrl()
  }

  componentDidUpdate = async prevProps => {
    const { thumbUrl } = this.props
    if (prevProps.thumbUrl !== thumbUrl) {
      await this.setUrl()
    }
  }

  setUrl = async () => {
    const { thumbUrl } = this.props
    if (thumbUrl && !thumbUrl.startsWith('data:')) {
      const blobUrl = await getMediaBlobUrl(thumbUrl)
      this.setState({ url: blobUrl })
    } else if (thumbUrl && thumbUrl.startsWith('data:')) {
      this.setState({ url: thumbUrl })
    }
  }

  render() {
    const { id, idx, updateScrollDisabledStatus, openCloseMediaGallery } = this.props
    const { url } = this.state

    return (
      <>
        <img
          src={url}
          alt=""
          id={id}
          className={classNames({ sctree_rp_gallery_item: true })}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="button"
          tabIndex={0}
          onClick={e => {
            updateScrollDisabledStatus(true)
            openCloseMediaGallery(e, true, idx)
          }}
          onKeyPress={e => {
            updateScrollDisabledStatus(true)
            openCloseMediaGallery(e, true, idx)
          }}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    appVersion: state.appVersion,
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CanvasImage)
