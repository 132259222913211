import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import itemViewsMap from '../../helper/itemViewsMap'

import { getTextWidth } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

export class ViewSwitcher extends React.Component {
  render() {
    const { texts, scoringTree, applyExpandedStatusOnItemsAndCriteria } = this.props

    function getSelectWidth(view) {
      const fontSize = '14.2px'
      switch (view) {
        case itemViewsMap.all:
          return getTextWidth(texts.items_list, fontSize)
        case itemViewsMap.allExpanded:
          return getTextWidth(texts.all_expanded, fontSize)
        case itemViewsMap.itemsExpanded:
          return getTextWidth(texts.items_expanded, fontSize)
        case itemViewsMap.unscored:
          return getTextWidth(texts.unscored, fontSize)
        case itemViewsMap.toReview:
          return getTextWidth(texts.to_review, fontSize)
        case itemViewsMap.mandatoryCriterion:
          return getTextWidth(texts.mandatory, fontSize)
        default:
          return getTextWidth(texts.items_list, fontSize)
      }
    }

    const selectWidth = getSelectWidth(parseInt(scoringTree.itemView, 10))

    function handleSelectChanged(e) {
      const { scoring, itemsFather } = scoringTree
      const selectedItemViewIndex = e.target.value

      applyExpandedStatusOnItemsAndCriteria(scoring, selectedItemViewIndex, itemsFather)
    }

    return (
      <div
        className={classNames({
          sctree_rp_itm_viewmode_container: true,
        })}
      >
        <div
          className={classNames({
            sctree_rp_itm_vmode_label_container: true,
          })}
        >
          <div className={classNames({ sctree_rp_itm_vmode_label: true })}>{texts.see}</div>

          <select
            className={classNames({
              va_select: true,
              sctree_rp_itm_viewmode_select: true,
            })}
            style={{ width: `${selectWidth}px` }}
            onChange={handleSelectChanged}
            value={parseInt(scoringTree.itemView, 10)}
          >
            <option
              className={classNames({
                sctree_rp_itm_viewmode_item: true,
              })}
              value={itemViewsMap.all}
            >
              {texts.items_list}
            </option>
            <option
              className={classNames({
                sctree_rp_itm_viewmode_item: true,
              })}
              value={itemViewsMap.itemsExpanded}
            >
              {texts.items_expanded}
            </option>
            <option
              className={classNames({
                sctree_rp_itm_viewmode_item: true,
              })}
              value={itemViewsMap.allExpanded}
            >
              {texts.all_expanded}
            </option>
            <option
              className={classNames({
                sctree_rp_itm_viewmode_item: true,
              })}
              value={itemViewsMap.unscored}
            >
              {texts.unscored}
            </option>
            <option
              className={classNames({
                sctree_rp_itm_viewmode_item: true,
              })}
              value={itemViewsMap.toReview}
            >
              {texts.to_review}
            </option>
            {JSON.parse(process.env.hasMandatoryCriterion) === true && (
              <option
                className={classNames({
                  sctree_rp_itm_viewmode_item: true,
                })}
                value={itemViewsMap.mandatoryCriterion}
              >
                {texts.mandatory}
              </option>
            )}
          </select>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ViewSwitcher)
