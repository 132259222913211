import React from 'react'
import { connect } from 'react-redux'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ReferenceLine, ResponsiveContainer, LabelList } from 'recharts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

import {
  getAverageOfSegmentPerNode,
  getBestOfSegmentPerNode,
  getBestOfAllPerNode,
} from '../../../../../../va-corejs-v3/actions/charts'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import {
  getProductName,
  calculatePopupChartHeight,
  calulateTickCharactersPerLine,
  calculateInnerContainerWidth,
  doCalculationForAnalytics,
  calculateChartWidthPerPopup,
} from './common/utils.js'
import { splitTextToLines } from '../../../../../../va-corejs-v3/utils/index.js'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class BreakdownChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.chartRef = React.createRef()

    this.state = {
      loading: false,
      showPopup: false,
      benchmarkExists: false,
      benchmarkSelected: false,
      productName: '',
      data: [],
      deltaBenchmarkData: [],
      dataLabels: [],
      dataLabelsBenchmark: [],
      target: 0,
      colors: ['#37EA45', '#FC4D38'],
      colorsBenchmark: ['#00BBFF', '#aaDDFF', '#0099FF'],
      settings: {
        chartContainerHeight: 230,
        xAxisDataKey: 'name',
        xAxisHeight: 60,
      },
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount = async () => {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })
    const { scoringTree, environment, root } = this.props
    const { analytics, nodeDefsObj, scoring } = scoringTree
    const { selectedReferenceType } = analytics

    const { nodes, props } = scoring

    const children = nodeDefsObj[root].children_ids

    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const duplicatedNodes = { ...nodes }
    await doCalculationForAnalytics(nodeDefsObj, duplicatedNodes)
    const data = []
    const dataLabels = []
    for (let i = 0; i !== children.length; i += 1) {
      const child = children[i]
      const childDef = nodeDefsObj[child]
      const childInstance = duplicatedNodes[child]
      const isDemerit = childDef.bonus_demerit
      const isEnabled = childInstance?.is_enabled
      if (!isDemerit && isEnabled) {
        const { normalizedScore } = childInstance
        const childName = childDef.name[environment.defaultLang]
        dataLabels.push(childName)

        // The name value is set to empty so we can have a 0 lenght line to render.
        // In this way rechart allow to render this label.
        // At render time we use dataLabels to map the right name value
        data.push({
          name: '',
          normalizedScore,
        })
      }
    }

    this.setStateIfMounted({ data, dataLabels })

    setTimeout(() => {
      this.updateBenchmarkData()
      this.performActionOnReferenceUpdated(selectedReferenceType)
    }, 200)
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.scored) === 0) {
      const scoreValue = parseInt(referenceType.replace(`${analyticsRefsTypesMap.scored}_`, ''), 10)
      this.updateTargetDataByScoreValue(scoreValue)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setStateIfMounted({ loading: true })

    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score

    this.redesignHitsBasedOnTarget(calculatedScoreValue)
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props

    const averageValue = await getAverageOfSegmentPerNode(scoringTree.scoring.template_id, segment, root)
    this.redesignHitsBasedOnTarget(averageValue)
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const bestValue = await getBestOfSegmentPerNode(scoringTree.scoring.template_id, mainSegment.value.slug, root)

    this.redesignHitsBasedOnTarget(bestValue)
  }

  updateTargetValueByBestOfAll = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props

    const bestValue = await getBestOfAllPerNode(scoringTree.scoring.template_id, root)

    this.redesignHitsBasedOnTarget(bestValue)
  }

  redesignHitsBasedOnTarget = target => {
    const { data } = this.state
    const duplicatedData = [...data]

    for (let i = 0; i !== data.length; i += 1) {
      const hit = duplicatedData[i]
      const { normalizedScore } = hit
      hit.target = target
      hit.negative = normalizedScore > target ? 0 : 0 - (target - normalizedScore)
      hit.positive = normalizedScore > target ? normalizedScore - target : 0
      hit.absolute = normalizedScore > target ? normalizedScore - target : 0 - (target - normalizedScore)
    }
    this.setStateIfMounted({
      data: duplicatedData,
      target,
      loading: false,
    })
  }

  updateBenchmarkData = async () => {
    const { scoringTree, root, environment } = this.props
    const { nodeDefsObj } = scoringTree
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { nodes } = scoringTree.scoring
    const updatedataLabelsBenchmark = []

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })

      const children = nodeDefsObj[root].children_ids

      const duplicatedNodesBenchmark = { ...benchmarkNodes }
      await doCalculationForAnalytics(nodeDefsObj, duplicatedNodesBenchmark)

      const dataBenchmark = []

      for (let i = 0; i !== children.length; i += 1) {
        const child = children[i]
        const childDef = nodeDefsObj[child]
        const childInstance = duplicatedNodesBenchmark[child]
        const childInstanceCurr = nodes[child]

        const isDemerit = childDef.bonus_demerit
        const isEnabled = childInstance?.is_enabled
        const isEnabledCurr = childInstanceCurr?.is_enabled
        if (!isDemerit && isEnabledCurr && isEnabled) {
          const normalizedScore = childInstance
          const childName = childDef.name[environment.defaultLang]
          dataBenchmark.push({ normalizedScore })
          updatedataLabelsBenchmark.push(childName)
        } else if (!isDemerit && isEnabledCurr && !isEnabled) {
          dataBenchmark.push(null)
        }
      }

      await this.redesignHitsBasedOnBenchmark(dataBenchmark)

      this.setState({
        loading: false,
        dataLabelsBenchmark: updatedataLabelsBenchmark,
        benchmarkExists: true,
        benchmarkSelected: true,
      })
    } else if (selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: false,
      })
  }

  redesignHitsBasedOnBenchmark = benchmarkData => {
    const { data } = this.state
    const duplicatedData = [...data]
    const duplicatedBenchmarkData = [...benchmarkData]
    const updateDeltaBenchmarkData = []

    for (let i = 0; i !== data.length; i += 1) {
      const hit = duplicatedData[i]

      // if benchmarkScore  exists calculate delta

      if (duplicatedBenchmarkData[i]) {
        const benchmarkScore = duplicatedBenchmarkData[i].normalizedScore.normalizedScore
        const { normalizedScore } = hit
        const delta = {}

        delta.negative = normalizedScore > benchmarkScore ? 0 : 0 - (benchmarkScore - normalizedScore)
        delta.positive = normalizedScore > benchmarkScore ? normalizedScore - benchmarkScore : 0
        delta.absolute =
          normalizedScore > benchmarkScore ? normalizedScore - benchmarkScore : 0 - (benchmarkScore - normalizedScore)

        updateDeltaBenchmarkData.push(delta)
      }
    }

    this.setStateIfMounted({
      deltaBenchmarkData: updateDeltaBenchmarkData,
      loading: false,
    })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, root } = this.props

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    const prevRoot = prevProps.root

    const reinitComponent = root !== prevRoot
    const updateUi = currRefType && currRefType !== prevRefType

    if (reinitComponent) {
      await this.initComponent()
    } else if (updateUi) {
      await this.performActionOnReferenceUpdated(currRefType)
      await this.updateBenchmarkData()
    }

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes
    if (benchmarkNodes !== prevBenchmarkNodes) {
      await this.updateBenchmarkData()
    }
  }

  handleShowPopupClicked = async () => {
    const { data } = this.state
    if (data.length === 0) return
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    await this.initComponent()

    this.setStateIfMounted({ showPopup: true })
  }

  handleClosePopupClicked = async () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    await this.initComponent()

    this.setStateIfMounted({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      benchmarkExists,
      benchmarkSelected,
      productName,
      data,
      deltaBenchmarkData,
      dataLabelsBenchmark,
      dataLabels,
      target,
      colors,
      colorsBenchmark,
      settings,
    } = this.state
    const { chartContainerHeight, xAxisDataKey, xAxisHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const renderCustomizedLabel = props => {
      const { x, y, width, value } = props
      const touchZeroLine = target + value === 0
      const yPosition = value > 0 || touchZeroLine ? y - 10 : y + 15
      const fillColor = touchZeroLine ? '#fff' : '#000'

      return (
        <>
          {yPosition && width && x && (
            <text x={x + width / 2} y={yPosition} fill={fillColor} textAnchor="middle" dominantBaseline="middle">
              {value ? Math.round(value) : ''}
            </text>
          )}
        </>
      )
    }

    const renderCustomizedAxisTick = props => {
      const { x, y, payload } = props
      const { index } = payload

      const lineHeigh = showPopup === true ? '12px' : '10px'

      const ticksNumber = dataLabels.length
      const { offsetWidth } = this.chartRef.current.container
      const chartPadding = 70

      const maxLineNumber = showPopup === true ? parseInt(xAxisHeight / 12, 10) - 1 : parseInt(xAxisHeight / 10, 10) - 1

      const charactersPerLine = calulateTickCharactersPerLine(offsetWidth, chartPadding, showPopup, ticksNumber)

      const lines = !benchmarkExists
        ? splitTextToLines(dataLabels[index], charactersPerLine)
        : splitTextToLines(dataLabelsBenchmark[index], charactersPerLine)

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {lines.map((line, idx) => (
              <tspan
                key={uuidv4()}
                className={classNames({
                  hidden: idx >= maxLineNumber,
                })}
                x={0}
                dy={lineHeigh}
              >
                {line}
              </tspan>
            ))}
          </text>
        </g>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer ref={this.chartRef} width={width} height={height}>
          <BarChart data={!benchmarkExists ? data : deltaBenchmarkData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={xAxisDataKey} height={xAxisHeight} tick={renderCustomizedAxisTick} />

            <YAxis domain={[dataMin => (dataMin > -200 ? -300 : -600), dataMax => (dataMax < 240 ? 300 : 600)]} />

            {!benchmarkExists && <Bar dataKey="target" stackId="a" fill="transparent" />}

            {!(benchmarkSelected && !benchmarkExists) && (
              <Bar
                dataKey="negative"
                stackId="a"
                fill={!benchmarkExists ? colors[1] : colorsBenchmark[1]}
                radius={[0, 0, 0, 0]}
              />
            )}

            {!(benchmarkSelected && !benchmarkExists) && (
              <Bar
                dataKey="positive"
                stackId="a"
                fill={!benchmarkExists ? colors[0] : colorsBenchmark[2]}
                radius={[0, 0, 0, 0]}
              >
                <LabelList dataKey="absolute" content={renderCustomizedLabel} />
              </Bar>
            )}

            {!benchmarkSelected && <ReferenceLine y={target} stroke="#000000" strokeWidth={3} />}

            {benchmarkExists && <Bar dataKey="0" stackId="a" fill="transparent" />}

            {benchmarkSelected && <ReferenceLine y={0} stroke={colorsBenchmark[0]} strokeWidth={3} />}
          </BarChart>
        </ResponsiveContainer>
      )
    }

    const innerContainerWidth = calculateInnerContainerWidth(data)

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              breakdown_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && data.length === 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              breakdown_loading_outer_container: true,
            })}
          >
            <div className={classNames({ chart_inner_container: true })} style={{ height: chartContainerHeight }}>
              <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
            </div>
          </div>
        )}
        {loading === false && data.length > 0 && showPopup === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              breakdown_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div
              style={{
                width: innerContainerWidth,
                height: chartContainerHeight,
              }}
              className={classNames({ chart_inner_container: true })}
            >
              {theChart('100%', chartContainerHeight)}
            </div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.score_breakdown}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            width={calculateChartWidthPerPopup(data)}
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(BreakdownChart)
