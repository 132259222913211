import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

import PhotoDB from './photoDB/index.js'
import ScoringModeMap from '../helper/scoringModeMap'
import ProductMode from './productMode'
import Analytics from './analytics'
import Documents from './documents'

import HideSideBarIcon from '../../../assets/svg/hide-sidebar.svg'
import ShowSideBarIcon from '../../../assets/svg/show-sidebar.svg'

import { updateQuerystringWithoutReloadPage } from '../../../../va-corejs-v3/utils'

// import { getTextWidth } from '../../../../../va-corejs-v3/utils/index'

import screenTypesMap from '../../../layout/_parts/screenTypesMap'

const classNames = require('classnames')

export class RightPanelProduct extends React.Component {
  render() {
    const { texts, device, scoringTree, updateSidebarVisibile, updateScoringSelectedMode } = this.props

    function handleShowHideSideBar() {
      updateSidebarVisibile(!scoringTree.sidebarVisible)
    }

    function handleProductModeSelected(mode) {
      updateScoringSelectedMode(mode)
      updateQuerystringWithoutReloadPage('view', mode)
    }

    return (
      <div>
        <div
          className={classNames({
            sctree_rp: true,
          })}
        >
          {/* Right Panel Product HEAD */}
          <div
            className={classNames({
              sctree_rp_header: true,
              sctree_rp_head_full_width:
                !scoringTree.sidebarVisible ||
                scoringTree.scoringMode === ScoringModeMap.product ||
                scoringTree.scoringMode === ScoringModeMap.clipboard,
              sctree_rp_sticky_header: device.isStickyHeader,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {scoringTree.scoringMode !== ScoringModeMap.product && (
              <div
                className={classNames({
                  sctree_rp_show_hide_sidebar_bnt: true,
                })}
                role="button"
                tabIndex={0}
                onClick={handleShowHideSideBar}
                onKeyPress={handleShowHideSideBar}
              >
                {scoringTree.sidebarVisible && scoringTree.scoringMode !== ScoringModeMap.product ? (
                  <div
                    className={classNames({
                      sctree_rp_hide_sidebar_bnt: true,
                      theme_background_color: true,
                    })}
                  >
                    <HideSideBarIcon />
                  </div>
                ) : (
                  <div
                    className={classNames({
                      sctree_rp_show_sidebar_bnt: true,
                      theme_svg: true,
                      theme_color: true,
                    })}
                  >
                    <ShowSideBarIcon />
                    <span
                      className={classNames({
                        sctree_rp_show_sidebar_label: true,
                      })}
                    >
                      {texts.show_sidebar}
                    </span>
                  </div>
                )}
              </div>
            )}
            <div
              className={classNames({
                right_panel_product_menu_container: true,
                right_panel_product_menu_container_small_btn:
                  !scoringTree.sidebarVisible && scoringTree.scoringMode !== ScoringModeMap.product,
                right_panel_product_menu_container_no_btn: scoringTree.scoringMode === ScoringModeMap.product,
              })}
            >
              <div className="right_panel_product_menu">
                <div
                  className={classNames({
                    right_panel_product_menu_btn: true,
                    right_panel_product_menu_btn_selected: scoringTree.scoringMode === ScoringModeMap.product,
                  })}
                  role="button"
                  tabIndex="0"
                  onClick={() => handleProductModeSelected(ScoringModeMap.product)}
                  onKeyDown={() => handleProductModeSelected(ScoringModeMap.product)}
                >
                  {texts.identity}
                </div>
                {JSON.parse(process.env.analyticsEnabled.toLowerCase()) === true && (
                  <div
                    className={classNames({
                      right_panel_product_menu_btn: true,
                      right_panel_product_menu_btn_selected: scoringTree.scoringMode === ScoringModeMap.analytics,
                    })}
                    role="button"
                    tabIndex="0"
                    onClick={() => handleProductModeSelected(ScoringModeMap.analytics)}
                    onKeyDown={() => handleProductModeSelected(ScoringModeMap.analytics)}
                  >
                    {texts.analytics}
                  </div>
                )}
                {JSON.parse(process.env.mediaTabEnabled.toLowerCase()) === true && (
                  <div
                    className={classNames({
                      right_panel_product_menu_btn: true,
                      right_panel_product_menu_btn_selected: scoringTree.scoringMode === ScoringModeMap.photoDB,
                    })}
                    role="button"
                    tabIndex="0"
                    onClick={() => handleProductModeSelected(ScoringModeMap.photoDB)}
                    onKeyDown={() => handleProductModeSelected(ScoringModeMap.photoDB)}
                  >
                    {texts.media}
                  </div>
                )}
                <div
                  className={classNames({
                    right_panel_product_menu_btn: true,
                    right_panel_product_menu_btn_selected: scoringTree.scoringMode === ScoringModeMap.clipboard,
                  })}
                  role="button"
                  tabIndex="0"
                  onClick={() => handleProductModeSelected(ScoringModeMap.clipboard)}
                  onKeyDown={() => handleProductModeSelected(ScoringModeMap.clipboard)}
                >
                  {JSON.parse(process.env.reportEnabled) === true ? texts.reports_and_documents : texts.documents}
                </div>
              </div>
            </div>
          </div>
          {/* End of Right Panel Product HEAD */}

          {scoringTree.scoringMode === ScoringModeMap.photoDB && <PhotoDB />}
          {scoringTree.scoringMode === ScoringModeMap.product && <ProductMode />}
          {JSON.parse(process.env.analyticsEnabled.toLowerCase()) === true &&
            scoringTree.scoringMode === ScoringModeMap.analytics && <Analytics />}
          {scoringTree.scoringMode === ScoringModeMap.clipboard && <Documents />}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(RightPanelProduct)
