/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { ResponsiveContainer, Cell, PieChart, Pie, LabelList } from 'recharts'
import * as actionCreators from '../../../../../store/actions'
import MediaIcon from '../../../../../assets/svg/media-outline.svg'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'

const classNames = require('classnames')

export class ChartMedia extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount = async () => {
    const { scoringTree, updateScoringTreeMedia } = this.props

    await updateScoringTreeMedia(scoringTree.scoring.id)

    this.setState({
      loaded: true,
    })
  }

  getData = () => {
    const { scoringTree, media, environment } = this.props
    const { scoring, nodeDefsObj: nodeDefinitions } = scoringTree
    const { lang } = environment

    const data = []

    const families = _.filter(nodeDefinitions, item => item.type === nodeDefinitionTypeMap.family)
    _.each(families, family => {
      const familyData = {
        name: family.trailer[lang],
        value: 0,
      }

      const children = _.filter(nodeDefinitions, item => family._left < item._left && item._right < family._right)
      _.each(children, child => {
        const node = scoring.nodes[child.id]
        const childMedia = node ? _.filter(media, medium => medium.node_id === node.id) : []
        familyData.value += childMedia.length
      })

      if (familyData.value > 0) {
        data.push(familyData)
      }
    })

    return data
  }

  renderCustomizedLabel = props => {
    // Some parameters
    const RADIAN = Math.PI / 180
    const SEPARATOR = '>'

    const { value, viewBox, offset, fill, style } = props
    const { cx, cy, startAngle, endAngle, outerRadius } = viewBox
    const midAngle = (endAngle - startAngle) / 2 + startAngle

    if (value === 0) {
      return ''
    }

    // Parameters to draw the label
    let labelRadius = outerRadius + offset
    let labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN)
    let labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN)

    // Offset correction
    // Necessary because the label is supposed to be printed in two rows
    if (labelX < cx) {
      const correctedOffset = offset + (offset * 230) / 100
      labelRadius = outerRadius + correctedOffset
      labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN)
      labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN)
    }

    // Make the array with the pieces that compose the label (which is a node trailer: "initial impression > exterior")
    const pieces = value ? value.split(SEPARATOR) : ['--']

    return (
      <text x={labelX} y={labelY} textAnchor={labelX > cx ? 'start' : 'end'} fill={fill} style={style}>
        {pieces.map((piece, index) => {
          let pieceToPrint = piece.trim()
          pieceToPrint += index < pieces.length - 1 ? ` ${SEPARATOR}` : ''
          return (
            <tspan x={labelX} dy="1em" key={index}>
              {pieceToPrint}
            </tspan>
          )
        })}
      </text>
    )
  }

  render() {
    const { texts, media } = this.props
    const { loaded } = this.state

    const data = this.getData()
    const totalMedia = media.length
    const colors = ['#695186', '#4698ae', '#245967', '#beafd3']

    if (!loaded) {
      return <></>
    }

    return (
      <div
        className={classNames({
          product_mode_chart_container: true,
        })}
      >
        <h3
          className={classNames({
            product_mode_chart_title: true,
          })}
        >
          <span
            className={classNames({
              product_mode_chart_title_icon: true,
            })}
          >
            <MediaIcon />
          </span>
          <span>
            {texts.media} ({totalMedia})
          </span>
        </h3>

        {totalMedia > 0 && (
          <div style={{ width: '100%', height: 157 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={25}
                  outerRadius={60}
                  paddingAngle={2}
                  isAnimationActive={false}
                >
                  {data.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={colors[index] ?? 'black'} />
                  })}
                  <LabelList
                    dataKey="name"
                    content={this.renderCustomizedLabel}
                    fill="black"
                    offset={6}
                    style={{
                      stroke: 'none',
                      fill: 'black',
                      fontSize: '9px',
                      fontWeight: '500',
                      letterSpacing: '-0.57px',
                    }}
                  />
                  <LabelList
                    dataKey="value"
                    position="inside"
                    style={{ stroke: 'none', fill: 'white', fontSize: '10px', fontWeight: '500' }}
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
    media: state.media.list,
  }
}

export default connect(mapStateToProps, actionCreators)(ChartMedia)
