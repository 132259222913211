import React from 'react'
import { connect } from 'react-redux'
import * as DOMPurify from 'dompurify'
import CommentFeedbackPositive from '../../../../assets/svg/comment-feedback-positive.svg'
import CommentFeedbackNeutral from '../../../../assets/svg/comment-feedback-neutral.svg'
import CommentFeedbackNegative from '../../../../assets/svg/comment-feedback-negative.svg'
import CommentEdit from '../../../../assets/svg/comment-edit.svg'
import CommentDelete from '../../../../assets/svg/comment-delete.svg'
import commentFeedbackMap from '../../helper/commentFeedbackMap'
import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class ViewComment extends React.Component {
  render() {
    const {
      comment,
      device,
      scoringTree,
      deleteComment,
      updateCommentEditingValueIntoStorageAndUpdateRedux,
      authentication,
      processSyncQueue,
      DragHandle,
    } = this.props

    const productIsLocked = scoringTree.scoring.is_locked
    const userAnonymous = process.env.userAnonymous !== undefined ? JSON.parse(process.env.userAnonymous) : false

    const FeedbackIcon = () => {
      switch (comment.feedback) {
        case commentFeedbackMap.positive:
          return <CommentFeedbackPositive />
        case commentFeedbackMap.negative:
          return <CommentFeedbackNegative />

        default:
          return <CommentFeedbackNeutral />
      }
    }

    async function handleEditCommentClicked(e) {
      e.stopPropagation()
      await updateCommentEditingValueIntoStorageAndUpdateRedux(comment.id, !comment.editing)
    }

    async function handleDeleteCommentClicked(e) {
      e.stopPropagation()
      await deleteComment(scoringTree, comment)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    const renderCommentText = text => {
      let output

      // remove html tag
      output = text.replace(/(<([^>]+)>)/gi, '')

      // let break space in html tag as before
      output = output.replace(/\n/g, '<br />')
      return output
    }

    return (
      <div>
        <div
          className={classNames({
            sctree_rp_item_node_exp_comment_feedback: true,
            sctree_rp_item_node_exp_comment_feedback_positive: comment.feedback === commentFeedbackMap.positive,
            sctree_rp_item_node_exp_comment_feedback_neutral: comment.feedback === commentFeedbackMap.neutral,
            sctree_rp_item_node_exp_comment_feedback_negative: comment.feedback === commentFeedbackMap.negative,
          })}
        >
          <FeedbackIcon />
        </div>
        <div
          className={classNames({
            sctree_rp_item_node_exp_comment_author: true,
          })}
        >
          {comment.author && !userAnonymous ? comment.author.name : '-'}
        </div>
        <div
          className={classNames({
            sctree_rp_item_node_exp_comment_locale: true,
          })}
        >
          {comment.locale.code}
        </div>
        {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
          <div
            className={classNames({
              sctree_rp_item_node_exp_comment_actions: true,
            })}
          >
            <div
              className={classNames({
                sctree_rp_item_node_exp_comment_action_btn: true,
                sctree_rp_item_node_exp_comment_action_edit: true,
              })}
              role="button"
              tabIndex={0}
              onClick={handleEditCommentClicked}
              onKeyPress={handleEditCommentClicked}
            >
              <CommentEdit />
            </div>
            <div
              className={classNames({
                sctree_rp_item_node_exp_comment_action_btn: true,
                sctree_rp_item_node_exp_comment_action_delete: true,
              })}
              role="button"
              tabIndex={0}
              onClick={handleDeleteCommentClicked}
              onKeyPress={handleDeleteCommentClicked}
            >
              <CommentDelete />
            </div>
            <DragHandle />
          </div>
        )}
        <div
          className={classNames({
            sctree_rp_item_node_exp_comment_text: true,
          })}
          dangerouslySetInnerHTML={{
            __html: comment?.text ? DOMPurify.sanitize(renderCommentText(comment?.text)) : '',
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    comments: state.comments.comments,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ViewComment)
