import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { ResponsiveContainer, Cell, PieChart, Pie, LabelList } from 'recharts'
import * as actionCreators from '../../../../../store/actions'
import CommentsIcon from '../../../../../assets/svg/comments-outline.svg'

const classNames = require('classnames')

export class ChartComments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount = async () => {
    const { scoringTree, getAllCommentsFromStorage } = this.props

    await getAllCommentsFromStorage(scoringTree.scoring.id)

    this.setState({
      loaded: true,
    })
  }

  getCommentsByFeedback = feedback => {
    const { scoringTree } = this.props
    const { comments } = scoringTree

    return _.filter(comments, item => item.feedback === feedback)
  }

  render() {
    const { texts, scoringTree } = this.props
    const { loaded } = this.state
    const { comments } = scoringTree

    const positiveComments = this.getCommentsByFeedback('positive').length
    const negativeComments = this.getCommentsByFeedback('negative').length
    const neutralComments = this.getCommentsByFeedback('neutral').length
    const totalComments = comments.length

    const data = [
      {
        name: texts.positive,
        value: positiveComments,
        fill: '#96eaac',
      },
      {
        name: texts.negative,
        value: negativeComments,
        fill: '#f89697',
      },
      {
        name: texts.neutral,
        value: neutralComments,
        fill: '#c9c9d0',
      },
    ]

    if (!loaded) {
      return <></>
    }

    return (
      <div
        className={classNames({
          product_mode_chart_container: true,
        })}
      >
        <h3
          className={classNames({
            product_mode_chart_title: true,
          })}
        >
          <span
            className={classNames({
              product_mode_chart_title_icon: true,
            })}
          >
            <CommentsIcon />
          </span>
          <span>
            {texts.comments} ({totalComments})
          </span>
        </h3>

        {comments.length > 0 && (
          <div style={{ width: '100%', height: 157 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={25}
                  outerRadius={60}
                  paddingAngle={2}
                  labelLine={false}
                  isAnimationActive={false}
                >
                  {data.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={entry.fill} />
                  })}
                  <LabelList
                    dataKey="value"
                    position="inside"
                    style={{ stroke: 'none', fill: 'white', fontSize: '10px', fontWeight: '500' }}
                  />
                  <LabelList
                    dataKey="name"
                    position="outside"
                    style={{
                      stroke: 'none',
                      fill: 'black',
                      fontSize: '9px',
                      fontWeight: '500',
                      letterSpacing: '-0.57px',
                    }}
                    offset={12}
                  />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ChartComments)
