import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'

import BreadCrumbDividerIcon from '../../../../../assets/svg/bredcrum-icon.svg'

const classNames = require('classnames')

export class ChartPopup extends React.Component {
  componentWillUnmount() {
    this.setState = () => {}
  }

  render() {
    const {
      texts,
      handleClosePopupClicked,
      title,
      product,
      theChart,
      width,
      height,
      benchmark,
      breadcrumb,
    } = this.props

    return (
      <div
        className={classNames({ chart_popup_outer_container: true })}
        role="button"
        tabIndex={0}
        onClick={e => {
          if (e.target.classList.contains('chart_popup_outer_container')) {
            handleClosePopupClicked()
          }
        }}
        onKeyPress={e => {
          if (e.target.classList.contains('chart_popup_outer_container')) {
            handleClosePopupClicked()
          }
        }}
      >
        <div className={classNames({ chart_popup_inner_container: true })}>
          <div className={classNames({ chart_popup_header: true })}>
            <div className="popup-header-inner">
              <div className="popup-breadcrumb">
                <span className="popup-product">{product}</span>

                {breadcrumb &&
                  breadcrumb.map((value, index) => {
                    return (
                      <span key={index} className="popup-breadcrumb-part">
                        <BreadCrumbDividerIcon />
                        <span>{value}</span>
                      </span>
                    )
                  })}
                <span>: {title}</span>
              </div>

              {benchmark && (
                <div className="popup-benchmark">
                  <span className="popup-benchmark-label">{texts.benchmark}:</span>
                  <span className="popup-benchmark-product">{benchmark}</span>
                </div>
              )}
            </div>
            <div
              className={classNames({ chart_popup_close_btn: true })}
              role="button"
              tabIndex={0}
              onClick={() => {
                handleClosePopupClicked()
              }}
              onKeyPress={() => {
                handleClosePopupClicked()
              }}
            >
              {texts.close}
            </div>
          </div>
          <div className={classNames({ chart_popup_body: true })}>{theChart(width, height)}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ChartPopup)
