import React from 'react'
import { connect } from 'react-redux'

import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts'

import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'
import {
  getProductName,
  calculatePopupChartHeight,
  getCriterionTypeShortLabels,
  getCriterionTypeList,
  doCalculationForAnalytics,
} from './common/utils'
import { LoadingBar } from '../../../../loading_bar'

import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const classNames = require('classnames')

export class CTypePerformanceOverviewChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      productName: '',
      data: [],
      radarColors: {
        initial: '',
        detailed: '',
      },
      settings: {
        chartContainerHeight: 244,
        colors: {
          exterior: {
            initial: '#695384',
            detailed: '#BEAFD3',
          },
          interior: {
            initial: '#225966',
            detailed: '#4698AE',
          },
          cargo: {
            initial: '#B94A29',
            detailed: '#D8B0A3',
          },
        },
      },
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  filterCriterionTypeForFamily = async ctype => {
    const { scoringTree, environment, family } = this.props
    const { nodeDefsObj } = scoringTree
    const { defaultLang } = environment
    const { nodes } = scoringTree.scoring
    const filteredNodes = { ...nodes }

    const idList = Object.keys(nodes)
    const nodeToExclude = []

    const perimeters = []

    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def

      if (def.parent_id === null) {
        const { name } = def
        perimeters.push({
          name: name[defaultLang],
          id: nodeId,
        })
      }

      if (type === nodeDefinitionTypeMap.criterion) {
        const itemDef = nodeDefsObj[def.parent_id]
        const subFamDef = nodeDefsObj[itemDef.parent_id]
        const famDef = nodeDefsObj[subFamDef.parent_id]
        const familyDefName = famDef.name[environment.defaultLang]
        const nodeType = def.criterion_template.slug

        if (family === scorePanelPropsMap.interior && familyDefName.indexOf('Interior') !== 0) {
          nodeToExclude.push(nodeId)
        }
        if (family === scorePanelPropsMap.exterior && familyDefName.indexOf('Exterior') !== 0) {
          nodeToExclude.push(nodeId)
        }
        if (family === scorePanelPropsMap.cargo && familyDefName.indexOf('Cargo') !== 0) {
          nodeToExclude.push(nodeId)
        }

        if (nodeType !== ctype) {
          nodeToExclude.push(nodeId)
        }
      }
    }

    for (let i = 0; i !== nodeToExclude.length; i += 1) {
      const nodeId = nodeToExclude[i]
      delete filteredNodes[nodeId]
    }

    await doCalculationForAnalytics(nodeDefsObj, filteredNodes)
    let initialPerformance = 0
    let detailedPerformance = 0
    for (let i = 0; i !== perimeters.length; i += 1) {
      const p = perimeters[i]
      const { name } = p
      const performance = filteredNodes[p.id].percentage

      if (name.indexOf('Initial') === 0) {
        initialPerformance = performance
      }
      if (name.indexOf('Detailed') === 0) {
        detailedPerformance = performance
      }
    }

    return {
      criteriaType: ctype,
      initialPerformance,
      detailedPerformance,
    }
  }

  recursivelyFilterCriterionType = async (cTypeList, trees) => {
    if (cTypeList.length === 0) return

    const ctype = cTypeList.pop()
    const filteredNodesTree = await this.filterCriterionTypeForFamily(ctype)
    trees[ctype] = filteredNodesTree

    await this.recursivelyFilterCriterionType(cTypeList, trees)
  }

  getChartColor = () => {
    const { family } = this.props

    const { settings } = this.state
    const { colors } = settings

    let radarColors = {}
    if (family === scorePanelPropsMap.exterior) {
      radarColors = colors.exterior
    } else if (family === scorePanelPropsMap.interior) {
      radarColors = colors.interior
    } else if (family === scorePanelPropsMap.cargo) {
      radarColors = colors.cargo
    }

    this.setState({ radarColors })
  }

  componentDidMount = async () => {
    this.setState({ loading: true })

    const { scoringTree, environment } = this.props
    const { scoring } = scoringTree
    const { props } = scoring

    const productName = await getProductName(props)
    this.setState({ productName })

    const shortLabels = await getCriterionTypeShortLabels(scoringTree, environment.defaultLang)

    const criterionTypeList = getCriterionTypeList()
    const cTypeListDuplicate = [...criterionTypeList]
    const treesByCType = {}
    await this.recursivelyFilterCriterionType(cTypeListDuplicate, treesByCType)

    const data = []
    for (let i = 0; i !== criterionTypeList.length; i += 1) {
      const cType = criterionTypeList[i]
      const { criteriaType, initialPerformance, detailedPerformance } = treesByCType[cType]
      const shortLabel = shortLabels[criteriaType]

      // VAV3-903 FIX
      // For how it was set up, shortLabels is the onlye variable actually aware of the criterion types present in the current template.
      // treesByCType is not reliable because the criterion types referenced are hardcoded.
      if (shortLabel !== undefined) {
        data.push({
          type: shortLabel,
          initialPerformance,
          detailedPerformance,
        })
      }
    }

    this.getChartColor()

    this.setState({ loading: false, data })
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const { loading, showPopup, productName, data, radarColors, settings } = this.state
    const { chartContainerHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, selectedReferenceType, breadcrumb } = scoringTree.analytics

    const renderLegend = props => {
      const { payload } = props
      return (
        <div className={classNames({ custom_legends_container: true })}>
          {payload.map((entry, index) => (
            <div key={`legend_${index}`} className={classNames({ custom_legend_item: true })}>
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames({
                  custom_legend_marker: true,
                  small_dot_marker: true,
                })}
              />
              <div className={classNames({ custom_legend_value: true })}>
                {entry.value.indexOf('initial') === 0 ? 'Initial' : 'Detailed'}
              </div>
            </div>
          ))}
        </div>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer width={width} height={height}>
          <RadarChart data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="type" />
            <PolarRadiusAxis angle={73} domain={[0, 100]} />
            <Radar
              dataKey="initialPerformance"
              stroke={radarColors.initial}
              strokeWidth="2.5"
              fill="transparent"
              fillOpacity={1}
            />
            <Radar
              dataKey="detailedPerformance"
              stroke={radarColors.detailed}
              strokeWidth="2.5"
              fill="transparent"
              fillOpacity={1}
            />
            <Legend content={renderLegend} />
          </RadarChart>
        </ResponsiveContainer>
      )
    }

    return (
      <>
        {(loading === true || Object.keys(data).length === 0) && (
          <div
            className={classNames({
              chart_loading_container: true,
              ctype_performance_overview_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              ctype_performance_overview_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', chartContainerHeight)}</div>
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.percentage_performance_per_criteria_type}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={selectedReferenceType === analyticsRefsTypesMap.benchmark ? benchmarkName : false}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CTypePerformanceOverviewChart)
