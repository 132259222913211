import React from 'react'
import { connect } from 'react-redux'
import TextareaAutosize from 'react-autosize-textarea'
import _ from 'lodash'
import commentFeedbackMap from '../../helper/commentFeedbackMap'
import commentSubFieldsMap from '../../helper/commentSubFieldsMap'
import CommentSendBtn from '../../../../assets/svg/comment-send.svg'

import * as actionCreators from '../../../../store/actions'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class EditComment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editCommentProps: {
        text: '',
        locale: {},
        feedback: '',
      },
    }
  }

  componentDidMount = async () => {
    const { comment } = this.props

    this.setState({
      editCommentProps: {
        text: comment.text,
        locale: comment.locale,
        feedback: comment.feedback,
      },
    })
  }

  render() {
    const {
      texts,
      device,
      authentication,
      environment,
      scoringTree,
      comment,
      updateCommentIntoStorageAndUpdateRedux,
      processSyncQueue,
    } = this.props
    const { editCommentProps } = this.state

    function validateComment() {
      return !(!editCommentProps.text || editCommentProps.text === '')
    }

    async function handleSaveCommentClicked(e) {
      e.stopPropagation()

      if (!validateComment()) {
        return
      }

      const newComment = { ...comment }
      newComment.text = editCommentProps.text
      newComment.locale = editCommentProps.locale
      newComment.feedback = editCommentProps.feedback
      newComment.author = authentication.user
      newComment.editing = false

      await updateCommentIntoStorageAndUpdateRedux(scoringTree, newComment)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    return (
      <div>
        <div
          className={classNames({
            sctree_rp_add_edit_comment_box: true,
          })}
        >
          <TextareaAutosize
            className={classNames({
              sctree_rp_add_edit_comment_textarea: true,
            })}
            maxRows={5}
            value={editCommentProps[commentSubFieldsMap.text]}
            placeholder={texts.add_comment}
            onChange={e => {
              this.setState({
                editCommentProps: {
                  ...editCommentProps,
                  text: e.target.value,
                },
              })
            }}
          />
          <select
            className={classNames({
              va_select: true,
              sctree_rp_add_edit_comment_lang_select: true,
            })}
            value={editCommentProps[commentSubFieldsMap.locale].code}
            onChange={e => {
              this.setState({
                editCommentProps: {
                  ...editCommentProps,
                  locale: _.find(environment.locales, item => item.code === e.target.value),
                },
              })
            }}
          >
            {environment.locales.map(locale => {
              return (
                <option key={uuidv4()} value={locale.code}>
                  {locale.code}
                </option>
              )
            })}
          </select>
          <div
            className={classNames({
              sctree_rp_add_edit_comment_feedback: true,
            })}
          >
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_positive: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  editCommentProps[commentSubFieldsMap.feedback] === commentFeedbackMap.positive,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                this.setState({
                  editCommentProps: {
                    ...editCommentProps,
                    feedback: commentFeedbackMap.positive,
                  },
                })
              }}
              onKeyPress={e => e.target.click()}
            />
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_neutral: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  editCommentProps[commentSubFieldsMap.feedback] === commentFeedbackMap.neutral,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                this.setState({
                  editCommentProps: {
                    ...editCommentProps,
                    feedback: commentFeedbackMap.neutral,
                  },
                })
              }}
              onKeyPress={e => e.target.click()}
            />
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_add_edit_comment_feedback_btn: true,
                sctree_rp_add_edit_comment_feedback_negative: true,
                sctree_rp_add_edit_comment_feedback_selected:
                  editCommentProps[commentSubFieldsMap.feedback] === commentFeedbackMap.negative,
                theme_color: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => {
                e.stopPropagation()
                this.setState({
                  editCommentProps: {
                    ...editCommentProps,
                    feedback: commentFeedbackMap.negative,
                  },
                })
              }}
              onKeyPress={e => e.target.click()}
            />
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_item_node_exp_send_comment_btn_container: true,
            disabled: !validateComment(),
            theme_svg: true,
          })}
          role="button"
          tabIndex={0}
          onClick={handleSaveCommentClicked}
          onKeyPress={handleSaveCommentClicked}
        >
          <CommentSendBtn />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    newComments: state.comments.newComments,
    comments: state.comments.comments,
  }
}

export default connect(mapStateToProps, actionCreators)(EditComment)
