import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { showPercentage, toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

export class ProjectModeScore extends React.Component {
  scoreDisplay = (nodeObj, viewMode) => {
    let scoreValue = ''

    if (nodeObj) {
      const { config } = this.props
      const { score, normalizedScore, maxScore } = nodeObj

      scoreValue = viewMode === 'percentage' ? showPercentage(score, maxScore) : scoreValue
      scoreValue =
        viewMode === 'normalized' ? `${toFixed1IfDecimal(normalizedScore)} / ${config.max_product_score}` : scoreValue
      scoreValue = viewMode === 'score' ? score : scoreValue
    }

    return scoreValue
  }

  render() {
    const { node, scoreDefault, scoreForecast, scoringTree } = this.props
    const { scored, percentageScored, forecast } = node
    const { calculationMode } = scoringTree

    const isEnabled = node.is_enabled
    const forecastIsEnabled = true

    const leftPanelScoresToShow = process.env.scoresToShow.split(',')
    const whatToShow = leftPanelScoresToShow[leftPanelScoresToShow.length - 2]

    const scoreToShow = scoreDefault || this.scoreDisplay(node, whatToShow)
    const forecastScoreToShow = scoreForecast || this.scoreDisplay(forecast, whatToShow)

    return (
      <>
        {!calculationMode && (
          <div
            className={classNames({
              sctree_rp_score_value_container: true,
              sctree_rp_score_value_container_unscored: !scored && percentageScored === 0,
              sctree_rp_score_value_container_disabled: !isEnabled,
              theme_color: true,
              theme_background_color_light: true,
              scoreset_default: true,
            })}
          >
            {scoreToShow}
          </div>
        )}

        {calculationMode === 'forecast' && (
          <div
            className={classNames({
              sctree_rp_score_value_container: true,
              sctree_rp_score_value_container_unscored: forecast && !forecast.scored && forecast.percentage === 0,
              sctree_rp_score_value_container_disabled: !forecastIsEnabled,
              theme_color: true,
              theme_background_color_light: true,
              scoreset_forecast: true,
            })}
          >
            {forecastScoreToShow}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    config: state.environment.config,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ProjectModeScore)
