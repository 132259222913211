import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'

const classNames = require('classnames')

const mediaTypes = { image: 'camera', video: 'camcoder', media: 'file' }

class EditMedia extends Component {
  render() {
    const {
      scoringTree,
      media,
      nodeDefId,
      device,
      updateMediaFile,
      updatePlaceholderMediaCount,
      processSyncQueue,
      authentication,
    } = this.props

    const extensions = {
      image: 'image/jpeg,image/gif,image/jpg,image/png',
      video: 'video/mp4,video/mov',
      media: device.isOnline
        ? 'image/jpeg,image/gif,image/jpg,image/png,video/mp4,video/mov'
        : 'image/jpeg,image/gif,image/jpg,image/png',
    }

    const inputAccept = `${extensions[media.type]}`
    const inputCapture = `${mediaTypes[media.type]}`

    const productIsLocked = scoringTree.scoring.is_locked

    function handleEditMedia(e) {
      e.stopPropagation()
      document.getElementById(`${media.type}-input-edit-${media.id}`).click()
    }

    async function handleInputChange() {
      const newMedia = document.getElementById(`${media.type}-input-edit-${media.id}`).files[0]

      updatePlaceholderMediaCount(newMedia.length)
      await updateMediaFile(
        scoringTree,
        newMedia,
        media.type,
        nodeDefId,
        mediaCollectionsMap.node_pictures,
        scoringTree.itemsFather.id,
        media.id
      )
      updatePlaceholderMediaCount(0)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    const removePen = true

    if (!removePen && authentication.user.parsedPermissions.canEditProduct && !productIsLocked) {
      return (
        <div
          className={classNames({
            sctree_rp_gallery_item_btn: true,
            sctree_rp_gallery_item_edit_btn: true,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            handleEditMedia(e)
          }}
          onKeyPress={e => {
            handleEditMedia(e)
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <path d="M15.376 3.866l1.187-1.187c.566-.566.584-1.23.044-1.78l-.505-.513c-.54-.549-1.221-.496-1.788.053l-1.186 1.178 2.248 2.249zM.735 16.969l2.479-.939L14.27 4.954l-2.24-2.24L.974 13.782l-.938 2.47c-.169.452.292.868.699.718z" />
          </svg>
          <input
            type="file"
            accept={inputAccept}
            capture={inputCapture}
            id={`${media.type}-input-edit-${media.id}`}
            style={{ display: 'none' }}
            onChange={handleInputChange}
          />
        </div>
      )
    }

    return <></>
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(EditMedia)
