import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'

import ExpandeIcon from '../../../../../assets/svg/expande_block.svg'
import CollapseIcon from '../../../../../assets/svg/collapse_block.svg'

import MappingOverviewChart from '../charts/mappingOverviewChart'
import ScoreDistributionChart from '../charts/scoreDistributionChart'
import FamilyPerformanceChart from '../charts/familyPerformanceChart'
import CTypeGlobalPerformanceChart from '../charts/cTypeGlobalPerformanceChart'

import MappingFamilyChart from '../charts/mappingFamilyChart'
import FamilyPerformanceOverviewChart from '../charts/familyPerformanceOverviewChart'
import CTypePerformanceOverviewChart from '../charts/cTypePerformanceOverviewChart'

import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'
import analyticsBlocksMap from '../../../helper/analyticsBlocksMap'
import screenTypesMap from '../../../../../layout/_parts/screenTypesMap'

const classNames = require('classnames')

export class OverviewView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeBlock: analyticsBlocksMap.exterior,
    }
  }

  componentDidMount = async () => {
    const { scoringTree, updateAnalyticsBreadcrumb } = this.props

    const breadcrumb = []

    await updateAnalyticsBreadcrumb(scoringTree.scoring.id, breadcrumb)
  }

  handleBlockSelected = selectedBlock => {
    const { activeBlock } = this.state
    if (activeBlock === selectedBlock) {
      selectedBlock = analyticsBlocksMap.none
    }
    this.setState({ activeBlock: selectedBlock })

    if (selectedBlock !== analyticsBlocksMap.none) {
      setTimeout(() => {
        if (typeof window !== 'undefined') {
          let gap = window.document.querySelector(`.block_${selectedBlock}`).offsetTop
          gap -= 100
          window.scrollTo(0, gap)
        }
      }, 200)
    }
  }

  render() {
    const { activeBlock } = this.state
    const { scoringTree, texts, device } = this.props

    return (
      <div className={classNames({ overview_main_container: true })}>
        {/* temporarily disabled */}
        {0 > 1 && (
          <div className={`block_container block_${analyticsBlocksMap.overview}`}>
            <div
              className={classNames({ block_title: true })}
              role="button"
              tabIndex={0}
              onClick={() => {
                this.handleBlockSelected(analyticsBlocksMap.overview)
              }}
              onKeyPress={() => {
                this.handleBlockSelected(analyticsBlocksMap.overview)
              }}
            >
              <div className={classNames({ block_label: true })}> Overview </div>
              <div className={classNames({ block_icon_container: true })}>
                {activeBlock === analyticsBlocksMap.overview ? <CollapseIcon /> : <ExpandeIcon />}
              </div>
            </div>
            {activeBlock === analyticsBlocksMap.overview && (
              <div className={classNames({ block_content: true })}>
                <div
                  className={classNames({
                    half_width_chart_container: device.screenType === screenTypesMap.desk,
                    full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                  })}
                >
                  <div className={classNames({ chart_label: true })}>{texts.mapping_overview}</div>
                  <MappingOverviewChart />
                </div>
                <div
                  className={classNames({
                    half_width_chart_container: device.screenType === screenTypesMap.desk,
                    full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                  })}
                >
                  <div className={classNames({ chart_label: true })}>{texts.score_distribution}</div>
                  <ScoreDistributionChart root={scoringTree.scoring.id} family={null} />
                </div>
                <div
                  className={classNames({
                    half_width_chart_container: true,
                  })}
                >
                  <div className={classNames({ chart_label: true })}>
                    {texts.percentage_performance_per_criteria_family}
                  </div>
                  <FamilyPerformanceChart root={scoringTree.scoring.id} />
                </div>
                <div
                  className={classNames({
                    half_width_chart_container: true,
                  })}
                >
                  <div className={classNames({ chart_label: true })}>
                    {texts.percentage_performance_per_criteria_type}
                  </div>
                  <CTypeGlobalPerformanceChart root={scoringTree.scoring.id} />
                </div>
              </div>
            )}
          </div>
        )}

        <div className={`block_container block_${analyticsBlocksMap.exterior}`}>
          <div
            className={classNames({ block_title: true })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleBlockSelected(analyticsBlocksMap.exterior)
            }}
            onKeyPress={() => {
              this.handleBlockSelected(analyticsBlocksMap.exterior)
            }}
          >
            <div className={classNames({ block_label: true })}>Exterior Overview</div>
            <div className={classNames({ block_icon_container: true })}>
              {activeBlock === analyticsBlocksMap.exterior ? <CollapseIcon /> : <ExpandeIcon />}
            </div>
          </div>
          {activeBlock === analyticsBlocksMap.exterior && (
            <div className={classNames({ block_content: true })}>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.mapping_overview}</div>
                <MappingFamilyChart family={scorePanelPropsMap.exterior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.score_distribution}</div>
                <ScoreDistributionChart root={scoringTree.scoring.id} family={scorePanelPropsMap.exterior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_left_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_family}
                </div>
                <FamilyPerformanceOverviewChart family={scorePanelPropsMap.exterior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_right_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_type}
                </div>
                <CTypePerformanceOverviewChart family={scorePanelPropsMap.exterior} />
              </div>
            </div>
          )}
        </div>

        <div className={`block_container block_${analyticsBlocksMap.interior}`}>
          <div
            className={classNames({ block_title: true })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleBlockSelected(analyticsBlocksMap.interior)
            }}
            onKeyPress={() => {
              this.handleBlockSelected(analyticsBlocksMap.interior)
            }}
          >
            <div className={classNames({ block_label: true })}>Interior Overview</div>
            <div className={classNames({ block_icon_container: true })}>
              {activeBlock === analyticsBlocksMap.interior ? <CollapseIcon /> : <ExpandeIcon />}
            </div>
          </div>
          {activeBlock === analyticsBlocksMap.interior && (
            <div className={classNames({ block_content: true })}>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.mapping_overview}</div>
                <MappingFamilyChart family={scorePanelPropsMap.interior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.score_distribution}</div>
                <ScoreDistributionChart root={scoringTree.scoring.id} family={scorePanelPropsMap.interior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_left_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_family}
                </div>
                <FamilyPerformanceOverviewChart family={scorePanelPropsMap.interior} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_right_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_type}
                </div>
                <CTypePerformanceOverviewChart family={scorePanelPropsMap.interior} />
              </div>
            </div>
          )}
        </div>

        <div className={`block_container block_${analyticsBlocksMap.cargo}`}>
          <div
            className={classNames({ block_title: true })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleBlockSelected(analyticsBlocksMap.cargo)
            }}
            onKeyPress={() => {
              this.handleBlockSelected(analyticsBlocksMap.cargo)
            }}
          >
            <div className={classNames({ block_label: true })}>Cargo Overview</div>
            <div className={classNames({ block_icon_container: true })}>
              {activeBlock === analyticsBlocksMap.cargo ? <CollapseIcon /> : <ExpandeIcon />}
            </div>
          </div>
          {activeBlock === analyticsBlocksMap.cargo && (
            <div className={classNames({ block_content: true })}>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.mapping_overview}</div>
                <MappingFamilyChart family={scorePanelPropsMap.cargo} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: device.screenType === screenTypesMap.desk,
                  full_width_chart_container: device.screenType === screenTypesMap.iPadH,
                })}
              >
                <div className={classNames({ chart_label: true })}>{texts.score_distribution}</div>
                <ScoreDistributionChart root={scoringTree.scoring.id} family={scorePanelPropsMap.cargo} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_left_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_family}
                </div>
                <FamilyPerformanceOverviewChart family={scorePanelPropsMap.cargo} />
              </div>
              <div
                className={classNames({
                  half_width_chart_container: true,
                  half_width_right_chart_container: true,
                })}
              >
                <div className={classNames({ chart_label: true })}>
                  {texts.percentage_performance_per_criteria_type}
                </div>
                <CTypePerformanceOverviewChart family={scorePanelPropsMap.cargo} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(OverviewView)
