import React, { Component } from 'react'
import { connect } from 'react-redux'
import PhotoDBItem from './photoDBItem'

import * as actionCreators from '../../../../store/actions'
import nodeDefinitionTypeMap from '../../helper/nodeDefinitionTypeMap'

class PhotoDBNodes extends Component {
  state = {}

  render() {
    const { scoringTree, mediaTypeToshow } = this.props
    function drawItems() {
      const html = []
      for (let i = 0; i < scoringTree.itemsFather.children_ids.length; i += 1) {
        const node = scoringTree.nodeDefsObj[scoringTree.itemsFather.children_ids[i]]
        if (node.type === nodeDefinitionTypeMap.item && node.parent_id === scoringTree.itemsFather.id) {
          html.push(<PhotoDBItem key={i} node={node} mediaTypeToshow={mediaTypeToshow} />)
        }
      }
      return html
    }

    return <div>{drawItems()}</div>
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(PhotoDBNodes)
