import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'
import * as actionCreators from '../../../../store/actions'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import PhotoDBNodes from './photoDBNodes'

import { httpExport } from '../../../../../va-corejs-v3/api'

import MediaIcon from '../../../../assets/svg/media-icon.svg'
import apiEndPoints from '../../../../../va-corejs-v3/api/apiEndPoints'

import { getProductBrandAndModelLabel } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')
// const uuidv4 = require('uuid/v4')

export class PhotoDB extends React.Component {
  state = {
    mediaTypeToshow: 'image',
  }

  componentDidMount = async () => {}

  handleMenuSelect = mediaTypeToshow => {
    this.setState({ mediaTypeToshow })
  }

  handleExport = () => {
    const { scoringTree } = this.props

    httpExport(apiEndPoints.export_media.replace('{scoringId}', scoringTree.scoring.id))
  }

  handleDownloadAllMedia = () => {
    const { scoringTree, updateDownloadAllMedia } = this.props
    const productName = getProductBrandAndModelLabel(scoringTree.scoring.props)
    updateDownloadAllMedia(apiEndPoints.export_media.replace('{scoringId}', scoringTree.scoring.id), productName)
  }

  render() {
    const { mediaTypeToshow } = this.state
    const { device, media, texts } = this.props

    function countMediaListElements(target) {
      let count = 0

      media.list.map(item => {
        count += item.file_type.split('/')[0] === target ? 1 : 0
        return item
      })
      return count
    }

    return (
      <div
        className={classNames({
          photo_db_inner_area: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div
          className={classNames({
            photo_db_shell: true,
          })}
        >
          <div
            className={classNames({
              photo_db_menu_container: true,
            })}
          >
            <div className="photo_db_menu">
              <div
                slug="price-currency-dollar"
                className={classNames({
                  photo_db_menu_btn: true,
                  photo_db_menu_btn_selected: mediaTypeToshow === 'image',
                })}
                role="button"
                tabIndex="0"
                onClick={() => this.handleMenuSelect('image')}
                onKeyDown={() => this.handleMenuSelect('image')}
              >
                <div className="photo_db_media_count_container">
                  <MediaIcon />{' '}
                  <span className="photo_db_media_count_label">
                    {texts.photos} ({countMediaListElements('image')})
                  </span>
                </div>
              </div>
              <div
                slug="price-currency-pound"
                className={classNames({
                  photo_db_menu_btn: true,
                  photo_db_menu_btn_selected: mediaTypeToshow === 'video',
                })}
                role="button"
                tabIndex="0"
                onClick={() => this.handleMenuSelect('video')}
                onKeyDown={() => this.handleMenuSelect('video')}
              >
                <div className="photo_db_media_count_container">
                  <MediaIcon />{' '}
                  <span className="photo_db_media_count_label">
                    {texts.videos} ({countMediaListElements('video')})
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames({
              photo_db_download_menu: true,
              theme_background_color: true,
            })}
            role="button"
            tabIndex="0"
            onClick={() => this.handleDownloadAllMedia()}
            onKeyDown={() => this.handleDownloadAllMedia()}
          >
            <span>{texts.download_all_media}</span>
          </div>
          <PhotoDBNodes mediaTypeToshow={mediaTypeToshow} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoringTree: state.scoringTree,
    media: state.media,
  }
}

export default connect(mapStateToProps, actionCreators)(PhotoDB)
