import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import PhotoDBCriterion from './photoDBCriterion'
import PhotoDBMediaGallery from './photoDBMediaGallery'
import nodeDefinitionTypeMap from '../../helper/nodeDefinitionTypeMap'
import itemViewsMap from '../../helper/itemViewsMap'

class PhotoDBItem extends Component {
  state = {}

  render() {
    const { node, scoringTree, mediaTypeToshow, list } = this.props

    function drawCriteria() {
      const html = []
      const parsedItemView = parseInt(scoringTree.itemView, 10)

      for (let i = 0; i < node.children_ids.length; i += 1) {
        const itemDefinition = scoringTree.nodeDefsObj[node.children_ids[i]]

        if (itemDefinition && itemDefinition.type === nodeDefinitionTypeMap.criterion) {
          const itemInstance = scoringTree.scoring.nodes[node.children_ids[i]]

          const hideCriteria = !!(parsedItemView === itemViewsMap.unscored && !itemInstance.is_default)
          if (!hideCriteria) {
            html.push(
              <PhotoDBCriterion
                key={itemDefinition.id}
                nodeDefId={itemDefinition.id}
                criterionIdx={itemDefinition.index}
                mediaTypeToshow={mediaTypeToshow}
              />
            )
          }
        }
      }
      return html
    }

    function criteriaSonsHasNodMedia() {
      let b = true
      for (let i = 0; i < node.children_ids.length; i += 1) {
        const itemDefinition = scoringTree.nodeDefsObj[node.children_ids[i]]
        if (itemDefinition && itemDefinition.type === nodeDefinitionTypeMap.criterion) {
          const filteredMedia = list.filter(x => x.node_definition_id === itemDefinition.id)
          b = filteredMedia.length > 0 ? false : b
        }
      }
      return b
    }

    const filteredMedia = list.filter(x => x.node_definition_id === node.id)

    if (filteredMedia.length === 0 && criteriaSonsHasNodMedia()) {
      return <></>
    }

    return (
      <div className="photo_db_item_container">
        <>
          {node.name.en}
          <PhotoDBMediaGallery
            nodeDefId={node.id}
            scoringId={scoringTree.scoring.id}
            placehodersMediaCount={0}
            mediaTypeToshow={mediaTypeToshow}
          />
          {drawCriteria()}
        </>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoringTree: state.scoringTree,
    list: state.media.list,
  }
}

export default connect(mapStateToProps, actionCreators)(PhotoDBItem)
