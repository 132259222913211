import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import OverviewView from './views/overviewView'
import FamilyView from './views/familyView'
import SubfamilyView from './views/subfamilyView'

import ReferenceSelector from './_parts/referenceSelector'
import BenchmarkSelector from './_parts/benchmarkSelector'

import './styles.scss'
import analyticsViewMap from '../../helper/analyticsViewMap'
import scoringModeMap from '../../helper/scoringModeMap'

const classNames = require('classnames')
// const uuidv4 = require('uuid/v4')

export class Analytics extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    const { scoringTree, getAnalyticsFromLocalStorage } = this.props
    await getAnalyticsFromLocalStorage(scoringTree.scoring.id, scoringTree.analytics)
    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    const { scoringTree, device } = this.props
    const { analytics, sidebarVisible, scoringMode } = scoringTree
    const { lastClickedNode } = analytics
    const { type } = lastClickedNode
    return (
      <div className={classNames({ analytics_panel_container: true })}>
        <div
          className={classNames({
            analytics_header: true,
            analytics_sticky_header: device.isStickyHeader,
            analytics_header_full_width: !sidebarVisible || scoringMode === scoringModeMap.product,
          })}
        >
          <div className={classNames({ analytics_header_left: true })}>
            <ReferenceSelector />
          </div>
          <div className={classNames({ analytics_header_right: true })}>
            <BenchmarkSelector />
          </div>
        </div>
        {loading === false && (
          <>
            {type === analyticsViewMap.overview && <OverviewView />}
            {type === analyticsViewMap.family && <FamilyView />}
            {type === analyticsViewMap.subfamily && <SubfamilyView />}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(Analytics)
