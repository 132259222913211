import React from 'react'
import { connect } from 'react-redux'

import { ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Cell, LabelList } from 'recharts'
import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'
import { getProductName, calculatePopupChartHeight } from './common/utils'
import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const classNames = require('classnames')

export class ScoreDistributionChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      productName: '',
      data: {},
      isDataEmpty: false,
      colors: ['#0C1998', '#097115', '#22B100', '#F9BB13', '#EE2119'],
      settings: {
        chartContainerHeight: 220,
      },
      benchmarkExists: false,
      colorBenchmark: '#00BBFF',
      colorTextBenchmark: '#4472c4',
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  getScoreDistributionList = async (listScore, nodes) => {
    const { scoringTree, root, family } = this.props
    const { nodeDefsObj } = scoringTree
    const { id } = scoringTree.scoring

    function getCriterionFamily(nDef) {
      const itmId = nDef.parent_id
      const subFamilyId = nodeDefsObj[itmId].parent_id
      const familyId = nodeDefsObj[subFamilyId].parent_id
      const familyDef = nodeDefsObj[familyId]
      const familyName = familyDef.name.en
      return familyName
    }

    function groupChildrenScore(idsForMappingScore, nodesToGroup, outputListScore) {
      for (let i = 0; i !== idsForMappingScore.length; i += 1) {
        const nId = idsForMappingScore[i]
        const nDef = nodeDefsObj[nId]
        if (!nDef.bonus_demerit) {
          const { type } = nDef
          if (type === nodeDefinitionTypeMap.criterion) {
            const cNode = nodesToGroup[nId]
            if (cNode?.is_enabled === true && cNode?.is_default === false && outputListScore[cNode?.score]) {
              const fName = getCriterionFamily(nDef)

              if (
                (family === scorePanelPropsMap.exterior && fName.indexOf('Exterior') === 0) ||
                (family === scorePanelPropsMap.interior && fName.indexOf('Interior') === 0) ||
                (family === scorePanelPropsMap.cargo && fName.indexOf('Cargo') === 0) ||
                (family !== scorePanelPropsMap.exterior &&
                  family !== scorePanelPropsMap.interior &&
                  family !== scorePanelPropsMap.cargo)
              ) {
                outputListScore[cNode.score].push(cNode)
              }
            }
          } else {
            const childrenList = nDef.children_ids
            groupChildrenScore(childrenList, nodesToGroup, outputListScore)
          }
        }
      }
    }

    let children = []
    if (root === id) {
      const nodeDefsArray = Object.values(nodeDefsObj)
      for (let i = 0; i !== nodeDefsArray.length; i += 1) {
        const nodeDef = nodeDefsArray[i]
        if (nodeDef.parent_id === null) {
          children.push(nodeDef.id)
        }
      }
    } else {
      children = nodeDefsObj[root].children_ids
    }

    groupChildrenScore(children, nodes, listScore)

    return listScore
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props
    const { nodes, props } = scoringTree.scoring
    // to move in a common object layout with benchmark
    let criterionListScore = { 4: [], 3: [], 2: [], 1: [], 0: [] }

    const productName = await getProductName(props)
    this.setState({ productName })

    function extractChartDataFromScoreLists(list) {
      const data = []
      const criterionListKeys = Object.keys(list).reverse()

      for (let i = 0; i !== criterionListKeys.length; i += 1) {
        const name = criterionListKeys[i]
        data.push({
          name,
          count: list[name].length,
        })
      }

      return data
    }

    criterionListScore = await this.getScoreDistributionList(criterionListScore, nodes)

    const data = extractChartDataFromScoreLists(criterionListScore)
    const isDataEmpty = this.verifyIfDataEmpty(data)

    this.setState({ data, isDataEmpty })

    await this.updateBenchmarkData()
  }

  updateBenchmarkData = async () => {
    const { scoringTree } = this.props
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { data } = this.state
    // to move in a common object layout with current product
    let criterionListBenchmarkScore = { 4: [], 3: [], 2: [], 1: [], 0: [] }

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })

      criterionListBenchmarkScore = await this.getScoreDistributionList(criterionListBenchmarkScore, benchmarkNodes)

      const criterionListKeys = Object.keys(criterionListBenchmarkScore).reverse()

      for (let i = 0; i !== criterionListKeys.length; i += 1) {
        const name = criterionListKeys[i]
        data[i].countBenchmark = criterionListBenchmarkScore[name].length
      }

      this.setState({
        data,
        benchmarkExists: true,
        loading: false,
      })
    } else this.setState({ benchmarkExists: false, loading: false })
  }

  componentDidUpdate = async prevProps => {
    const { root, scoringTree } = this.props
    const prevRoot = prevProps.root
    const reinitComponent = root !== prevRoot

    if (reinitComponent) await this.initComponent()

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    if (currRefType && currRefType !== prevRefType) await this.updateBenchmarkData()

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes

    if (benchmarkNodes !== prevBenchmarkNodes) await this.updateBenchmarkData()
  }

  handleShowPopupClicked = () => {
    const { isDataEmpty } = this.state
    if (isDataEmpty) return
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  verifyIfDataEmpty = data => {
    let total = 0
    for (let i = 0; i !== data.length; i += 1) {
      const record = data[i]
      total += record.count
    }
    return total === 0
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      productName,
      data,
      isDataEmpty,
      colors,
      benchmarkExists,
      colorBenchmark,
      colorTextBenchmark,
      settings,
    } = this.state

    const { chartContainerHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, selectedReferenceType, breadcrumb } = scoringTree.analytics

    /**
     * Chart render parts
     *
     */
    const renderCustomizedLabel = props => {
      const { x, y, width, height, value } = props

      return (
        <text x={x + width + 5} y={y + height / 2} fill="#000" textAnchor="right" dominantBaseline="middle">
          {value}
        </text>
      )
    }

    const renderCustomizedLabelBenchmark = props => {
      const { x, y, width, height, value } = props

      return (
        <text
          x={x + width + 5}
          y={y + height / 2}
          fill={colorTextBenchmark}
          textAnchor="right"
          dominantBaseline="middle"
        >
          {value}
        </text>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer width={width} height={height}>
          <ComposedChart layout="vertical" data={data}>
            <XAxis type="number" domain={[0, dataMax => (dataMax < 200 ? 250 : 600)]} />
            <YAxis dataKey="name" type="category" />
            <CartesianGrid stroke="#f5f5f5" />
            <Bar dataKey="count" radius={[0, 0, 0, 0]}>
              {data.map((entry, index) => (
                <Cell cursor="pointer" fill={colors[index]} key={`cell-${index}`} />
              ))}
              <LabelList dataKey="count" content={renderCustomizedLabel} />
            </Bar>
            {benchmarkExists && (
              <Bar dataKey="countBenchmark" radius={[0, 0, 0, 0]} fill={colorBenchmark}>
                <LabelList dataKey="countBenchmark" content={renderCustomizedLabelBenchmark} />
              </Bar>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    const renderNoData = () => {
      return <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
    }

    /**
     * Return Chart
     *
     */
    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              score_distribution_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              score_distribution_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div style={{ height: chartContainerHeight }} className={classNames({ chart_inner_container: true })}>
              {isDataEmpty ? renderNoData() : theChart('100%', chartContainerHeight)}
            </div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.score_distribution}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={selectedReferenceType === analyticsRefsTypesMap.benchmark ? benchmarkName : false}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoreDistributionChart)
