import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import HideSideBarIcon from '../../../../assets/svg/hide-sidebar.svg'
import ShowSideBarIcon from '../../../../assets/svg/show-sidebar.svg'

import { getTextWidth, showPercentage, toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils/index'
import ViewSwitcher from './viewSwitcher'

import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import { isProjectModeEnabled } from '../projectMode/utils'
import ProjectModeScore from '../projectMode/projectModeScore'

const classNames = require('classnames')

export class RightPanelHead extends React.Component {
  render() {
    const { environment, device, texts, scoringTree, updateSidebarVisibile, config } = this.props

    const { itemsFather } = scoringTree
    const nodeDefObj = scoringTree.nodeDefsObj[itemsFather.id]
    const currentNode = scoringTree.scoring.nodes[itemsFather.id]

    const { score, maxScore, normalizedScore } = currentNode

    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    const leftPanelScoresToShow = process.env.scoresToShow.split(',')
    const whatToShow = leftPanelScoresToShow[leftPanelScoresToShow.length - 2]
    let scoreToShow = ''
    scoreToShow = whatToShow === 'percentage' ? showPercentage(score, maxScore) : scoreToShow
    scoreToShow =
      whatToShow === 'normalized' ? `${toFixed1IfDecimal(normalizedScore)} / ${config.max_product_score}` : scoreToShow
    scoreToShow = whatToShow === 'score' ? score : scoreToShow

    function handleShowHideSideBar() {
      updateSidebarVisibile(!scoringTree.sidebarVisible)
    }

    const itemsFatherName = itemsFather.name[environment.lang]
      ? itemsFather.name[environment.lang]
      : itemsFather.name[environment.defaultLang]
    const itemsFatherNameWidht = getTextWidth(itemsFatherName, '18px')
    const gradientMaskNeeded =
      document.querySelector('.sctree_rp_itm_name') &&
      itemsFatherNameWidht >= document.querySelector('.sctree_rp_itm_name').offsetWidth

    return (
      <div
        className={classNames({
          sctree_rp_header: true,
          sctree_rp_head_full_width: !scoringTree.sidebarVisible,
          sctree_rp_sticky_header: device.isStickyHeader,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div
          className={classNames({
            sctree_rp_show_hide_sidebar_bnt: true,
          })}
          role="button"
          tabIndex={0}
          onClick={handleShowHideSideBar}
          onKeyPress={handleShowHideSideBar}
        >
          {scoringTree.sidebarVisible ? (
            <div
              className={classNames({
                sctree_rp_hide_sidebar_bnt: true,
                theme_background_color: true,
              })}
            >
              <HideSideBarIcon />
            </div>
          ) : (
            <div
              className={classNames({
                sctree_rp_show_sidebar_bnt: true,
                theme_svg: true,
                theme_color: true,
              })}
            >
              <ShowSideBarIcon />
              <span
                className={classNames({
                  sctree_rp_show_sidebar_label: true,
                  theme_color: true,
                })}
              >
                {texts.show_sidebar}
              </span>
            </div>
          )}
        </div>
        <div
          className={classNames({
            sctree_rp_itm_name: true,
            sctree_rp_itm_name_with_gradient: gradientMaskNeeded,
          })}
        >
          {itemsFatherName}
        </div>

        <div
          className={classNames({
            sctree_rp_itm_right_aligned: true,
          })}
        >
          {!nodeDefObj.bonus_demerit && !isProjectMode && (
            <div
              className={classNames({
                sctree_rp_itm_score_container: true,
                sctree_rp_itm_score_container_unscored: !currentNode.scored && currentNode.percentageScored === 0,
                theme_color: true,
                theme_background_color_light: true,
              })}
            >
              {scoreToShow}
            </div>
          )}

          {isProjectMode && !nodeDefObj.bonus_demerit && <ProjectModeScore node={currentNode} />}

          <ViewSwitcher />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    config: state.environment.config,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(RightPanelHead)
