import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import nodeDefinitionTypeMap from '../../helper/nodeDefinitionTypeMap'
import MediaIcon from '../../../../assets/svg/media-icon.svg'
import CommentIcon from '../../../../assets/svg/comment-icon.svg'
import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'
import ReviewBtnFlag from '../../../../assets/svg/review-btn-flag.svg'
import SelectedFlagIcon from '../../../../assets/svg/selected-flag-icon.svg'
import Criterion from './criterion'
import Forecast from '../projectMode/forecast'
import MediaButton from './mediaButton'
import MediaGallery from './mediaGallery'
import NodeComments from '../comments/nodeComments'
import itemViewsMap from '../../helper/itemViewsMap'
import { showPercentage, toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import ReportAdd from '../../../report/_parts/commons/reportAdd'
import { isProjectModeEnabled, normalizeProjectModeCount } from '../projectMode/utils'
import ProjectModeScore from '../projectMode/projectModeScore'

const classNames = require('classnames')

export class Item extends React.Component {
  constructor(props) {
    super(props)
    this.itemRef = React.createRef()
    this.handleNodeClick = this.handleNodeClick.bind(this)

    this.state = {
      placehodersMediaCount: 0,
    }
  }

  updatePlaceholderMediaCount = placehodersMediaCount => {
    this.setState({ placehodersMediaCount })
  }

  handleNodeClick = async function(e) {
    const {
      node,
      scoringTree,
      updateExpandedNodeIntoScoring,
      commentFocused,
      updateLockNodeCliksOnCommentFocus,
    } = this.props

    if (commentFocused.status === 1) {
      commentFocused.status = 2
      updateLockNodeCliksOnCommentFocus(commentFocused)
      return
    }
    if (commentFocused.status === 2) {
      commentFocused.status = 0
      updateLockNodeCliksOnCommentFocus(commentFocused)
    }

    const isChildElement =
      e.target.classList.contains('slider') ||
      e.target.classList.contains('sctree_rp_gallery_show_more_less_btn') ||
      e.target.type === 'checkbox' ||
      e.target.type === 'textarea' ||
      e.target.type === 'text' ||
      e.target.type === 'select-one' ||
      e.target.type === 'file'
    if (!isChildElement) {
      await updateExpandedNodeIntoScoring(scoringTree.scoring.id, node.id, scoringTree.itemView)
    }

    /* VAV3-979 scroll removed
    if (typeof window !== 'undefined') {
      // 30 pixels is an adequate padding above the element where to stop the scroll
      const scrollTo = this.itemRef.current.offsetTop - 30
      window.scrollTo(0, scrollTo)
    }
    */
  }

  render() {
    const {
      environment,
      config,
      texts,
      device,
      node,
      scoringTree,
      updateEnableDisableNodes,
      updateToReviewValue,
      updateDefaultValue,
      authentication,
      processSyncQueue,
    } = this.props

    const { placehodersMediaCount } = this.state

    const nodeDefObj = scoringTree.nodeDefsObj[node.id]
    const nodeObj = scoringTree.scoring.nodes[node.id]
    const { score, normalizedScore, maxScore, isExpanded, to_review: toReview } = nodeObj

    const productIsLocked = scoringTree.scoring.is_locked

    const leftPanelScoresToShow = process.env.scoresToShow.split(',')
    const whatToShow = leftPanelScoresToShow[leftPanelScoresToShow.length - 2]
    let scoreToShow = ''
    scoreToShow = whatToShow === 'percentage' ? showPercentage(score, maxScore) : scoreToShow
    scoreToShow =
      whatToShow === 'normalized' ? `${toFixed1IfDecimal(normalizedScore)} / ${config.max_product_score}` : scoreToShow
    scoreToShow = whatToShow === 'score' ? score : scoreToShow

    const isEnabled = nodeObj.is_enabled
    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    const formattedCommentCount = normalizeProjectModeCount(scoringTree.scoring, node.id, 'comments') || 0
    const formattedMediaCount = normalizeProjectModeCount(scoringTree.scoring, node.id, 'media') || 0

    async function handleDefaultScoreClicked(e) {
      e.stopPropagation()

      await updateDefaultValue(scoringTree, nodeObj.node_definition_id)

      if (device.isOnline) {
        processSyncQueue()
      }
    }
    async function handleToReviewClicked(e) {
      e.stopPropagation()
      await updateToReviewValue(scoringTree.scoring.id, node.id, !toReview)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    async function handleEnableDisableClicked(clickedNode, e) {
      e.stopPropagation()
      const checkedValue = e.target.checked
      await updateEnableDisableNodes(scoringTree, clickedNode, checkedValue)

      if (device.isOnline) {
        processSyncQueue()
      }
    }

    function hasCriteriaToReview() {
      let check = false

      for (let i = 0; i < node.children_ids.length; i += 1) {
        if (scoringTree.scoring.nodes[node.children_ids[i]].to_review) {
          check = true
        }
      }

      return check
    }

    function hasForecastToReview() {
      return node.children_ids.some(
        id => scoringTree.scoring.nodes[id].forecast && scoringTree.scoring.nodes[id].forecast.to_review
      )
    }

    function drawCriteria() {
      const html = []
      const parsedItemView = parseInt(scoringTree.itemView, 10)

      for (let i = 0; i < node.children_ids.length; i += 1) {
        const itemDefinition = scoringTree.nodeDefsObj[node.children_ids[i]]

        if (itemDefinition && itemDefinition.type === nodeDefinitionTypeMap.criterion) {
          const itemInstance = scoringTree.scoring.nodes[node.children_ids[i]]

          let hideCriteria = false
          if (parsedItemView === itemViewsMap.unscored) {
            if (!itemInstance.is_default) hideCriteria = true
          } else if (parsedItemView === itemViewsMap.mandatoryCriterion) {
            if (!itemDefinition.mandatory) hideCriteria = true
          }

          if (!hideCriteria) {
            html.push(
              <div
                className={classNames({
                  sctree_rp_criterion_container: true,
                  sctree_rp_criterion_forecast_container: isProjectMode,
                })}
                key={`item_${itemDefinition.id}`}
              >
                <Criterion key={itemDefinition.id} nodeDefId={itemDefinition.id} criterionIdx={itemDefinition.index} />
                {isProjectMode && (
                  <Forecast
                    key={`f_${itemDefinition.id}`}
                    nodeDefId={itemDefinition.id}
                    criterionIdx={itemDefinition.index}
                  />
                )}
              </div>
            )
          }
        }
      }
      return html
    }

    return (
      <div
        className={classNames({
          sctree_rp_item_node: true,
          sctree_rp_item_disabled: !isEnabled,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
        itemID={node.id}
        id={`item-${node.id}`}
        ref={this.itemRef}
      >
        <div
          className={classNames({
            sctree_rp_item_enabled_node: isEnabled,
            sctree_rp_item_disabled_node: !isEnabled,
          })}
          key={node.id}
        >
          <div
            className={classNames({
              sctree_rp_item_node_header: true,
            })}
            role="button"
            tabIndex={0}
            onClick={this.handleNodeClick}
            onKeyPress={this.handleNodeClick}
          >
            <div
              className={classNames({
                sctree_rp_item_node_name: true,
                sctree_rp_item_node_name_expanded: isExpanded,
              })}
            >
              {node.name[environment.lang] ? node.name[environment.lang] : node.name[environment.defaultLang]}
            </div>
            <div
              className={classNames({
                sctree_rp_item_node_actions_container: true,
              })}
            >
              <div
                className={classNames({
                  sctree_rp_item_node_review_and_counter_container: true,
                  sctree_rp_item_node_review_and_counter_container_disabled: !isEnabled,
                })}
              >
                {(toReview || (!isExpanded && (hasCriteriaToReview() || hasForecastToReview()))) && (
                  <div
                    className={classNames({
                      sctree_rp_item_node_toreview_container: true,
                    })}
                  >
                    <SelectedFlagIcon className="theme_svg" />
                  </div>
                )}

                <div
                  className={classNames({
                    sctree_rp_item_node_comment_count_container: true,
                  })}
                >
                  <CommentIcon />
                  <span
                    className={classNames({
                      sctree_rp_item_node_comment_count_value: true,
                    })}
                  >
                    {String(formattedCommentCount)}
                  </span>
                </div>
                <div
                  className={classNames({
                    sctree_rp_item_node_media_count_container: true,
                  })}
                >
                  <MediaIcon />
                  <span
                    className={classNames({
                      sctree_rp_item_node_media_count_value: true,
                    })}
                  >
                    {String(formattedMediaCount)}
                  </span>
                </div>
              </div>

              {!nodeDefObj.bonus_demerit && !isProjectMode && (
                <div
                  className={classNames({
                    sctree_rp_item_node_score_value_container: true,
                    sctree_rp_item_node_score_value_container_unscored:
                      !nodeObj.scored && nodeObj.percentageScored === 0,
                    sctree_rp_item_node_score_value_container_disabled: !isEnabled,
                    theme_color: true,
                    theme_background_color_light: true,
                  })}
                >
                  {scoreToShow}
                </div>
              )}

              {isProjectMode && !nodeDefObj.bonus_demerit && <ProjectModeScore node={nodeObj} />}

              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    sctree_rp_item_node_enable_disable_btn_container: true,
                  })}
                >
                  <label htmlFor={node.id} className={classNames({ ios_toggle: true })}>
                    <input
                      id={node.id}
                      type="checkbox"
                      checked={isEnabled}
                      onChange={e => {
                        handleEnableDisableClicked(node, e)
                      }}
                    />
                    <span className={classNames({ slider: true })} />
                  </label>
                </div>
              )}
              <div
                className={classNames({
                  sctree_rp_item_node_expand_collapse_btn: true,
                  sctree_rp_item_node_expand_collapse_btn_disabled: !isEnabled,
                })}
              >
                {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
              </div>
            </div>
          </div>

          {isExpanded && (
            <div
              className={classNames({
                sctree_rp_exp_area_outer_container: true,
              })}
            >
              <div
                className={classNames({
                  sctree_rp_exp_area_container: true,
                })}
              >
                <div
                  className={classNames({
                    sctree_rp_item_exp_area_buttons_container: true,
                  })}
                >
                  {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                    <div
                      className={classNames({
                        sctree_rp_item_node_exp_right_action_area: true,
                      })}
                    >
                      <div
                        className={classNames({
                          sctree_rp_item_node_exp_right_action: true,
                          sctree_rp_item_node_exp_right_action_def_score: true,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={handleDefaultScoreClicked}
                        onKeyPress={handleDefaultScoreClicked}
                      >
                        <span>{texts.default_score}</span>
                      </div>
                      <div
                        className={classNames({
                          sctree_rp_item_node_exp_right_action: true,
                          sctree_rp_item_node_exp_right_action_review: true,
                          sctree_rp_item_node_exp_right_action_selected: toReview,
                        })}
                        role="button"
                        tabIndex={0}
                        onClick={handleToReviewClicked}
                        onKeyPress={handleToReviewClicked}
                      >
                        <ReviewBtnFlag /> <span>{texts.to_review}</span>
                      </div>
                      <ReportAdd itemId={node.id} />
                    </div>
                  )}
                  {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                    <div
                      className={classNames({
                        sctree_rp_item_node_upload_media_container: true,
                      })}
                    >
                      <MediaButton
                        label="Media"
                        mediaType="media"
                        nodeDefId={node.id}
                        updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                      />
                      <MediaButton
                        label="Video"
                        mediaType="video"
                        nodeDefId={node.id}
                        updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                      />
                      <MediaButton
                        label="Photo"
                        mediaType="image"
                        nodeDefId={node.id}
                        updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                      />
                    </div>
                  )}
                </div>
                <MediaGallery
                  nodeDefId={node.id}
                  scoringId={scoringTree.scoring.id}
                  placehodersMediaCount={placehodersMediaCount}
                />
                <NodeComments nodeDefId={node.id} productIsLocked={productIsLocked} />
              </div>
              <div
                className={classNames({
                  sctree_rp_item_criterion_container: true,
                })}
              >
                {drawCriteria()}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    config: state.environment.config,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    comments: state.comments.comments,
    authentication: state.authentication,
    commentFocused: state.scoringTree.commentFocused,
  }
}

export default connect(mapStateToProps, actionCreators)(Item)
