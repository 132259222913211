import React from 'react'
import { connect } from 'react-redux'

import { Player } from 'video-react'
import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'

import CloseIcon from '../../../../assets/svg/lightbox-close.svg'
import BreadcrumbIcon from '../../../../assets/svg/lightbox-breadcrumb.svg'
import PrevIcon from '../../../../assets/svg/lightbox-arrow-big-left.svg'
import NextIcon from '../../../../assets/svg/lightbox-arrow-big-right.svg'

import PrevThumbsIcon from '../../../../assets/svg/lightbox-arrow-small-left.svg'
import NextThumbsIcon from '../../../../assets/svg/lightbox-arrow-small-right.svg'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'

import { getMediaBigFromLocalStorage } from '../../../../../va-corejs-v3/actions/media'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class MediaLightbox extends React.Component {
  state = {
    parsedMedia: [],
    sliderWidth: '',
    sliderShift: 0,
    thumbsWidth: '',
    thumbsShift: 0,
    thumbsPage: 0,
    thumbsPageSize: 5,
    thumbsPagesCount: 0,
    mediaIndex: 0,
    thumbs: [],
    slides: [],
    blobUrls: {},
    loading: false,
  }

  calculateShift = mediaIndex => {
    const { parsedMedia } = this.state
    let thumbsShift = 'translateX(0px)'

    if (parsedMedia.length > 5) {
      if (mediaIndex > 2 && mediaIndex <= parsedMedia.length - 3) {
        thumbsShift = `translateX(-${(mediaIndex - 2) * 95}px)`
      } else if (mediaIndex > parsedMedia.length - 3) {
        thumbsShift = `translateX(-${(parsedMedia.length - 5) * 95}px)`
      }
    }

    const sliderShift = `translateX(-${mediaIndex * 100}vw)`

    return { sliderShift, thumbsShift }
  }

  handleMediaIndexChanged = async (e, mediaIndex) => {
    e.stopPropagation()

    const { thumbsPageSize, blobUrls, parsedMedia } = this.state
    const { sliderShift, thumbsShift } = this.calculateShift(mediaIndex)

    const thumbsPage = parseInt(mediaIndex / thumbsPageSize, 10)

    await this.setState({ mediaIndex, sliderShift, thumbsShift, thumbsPage })

    const media = parsedMedia[mediaIndex]
    if (blobUrls[media.id] === null) {
      this.setState({ loading: true })
      blobUrls[media.id] = await getMediaBlobUrl(media.file)
      this.setState({ loading: false })
    }

    this.setState({ blobUrls })
  }

  handleThumbsPageChanged = (e, thumbsPage) => {
    e.stopPropagation()

    const { thumbsPageSize } = this.state

    const thumbsShift = `translateX(-${thumbsPage * (thumbsPageSize * (85 + 10))}px)`
    this.setState({ thumbsPage, thumbsShift })
  }

  parseMediaList = async () => {
    const { list } = this.props
    const parsedMedia = []

    await Promise.all(
      await list.map(async media => {
        const duplicateMedia = { ...media }

        let url = media.file ? media.file : media.file_thumb
        if (url.startsWith('data:')) {
          const bigMediaBase64Data = await getMediaBigFromLocalStorage(media.id)
          url = bigMediaBase64Data ? bigMediaBase64Data.file : url
          duplicateMedia.file = url
          duplicateMedia.file_thumb = url
        } else {
          duplicateMedia.file = media.file
          duplicateMedia.file_thumb = media.file_thumb
        }

        parsedMedia.push(duplicateMedia)
      })
    )

    const sortedMedia = parsedMedia.sort((a, b) => {
      if (a.order < b.order) {
        return -1
      }
      if (a.order > b.order) {
        return 1
      }
      return 0
    })

    this.setState({ parsedMedia: sortedMedia })
    return sortedMedia
  }

  setThumbs = async () => {
    const { parsedMedia } = this.state
    const thumbs = []

    // Get media blob urls
    const promises = []
    _.each(parsedMedia, item => {
      if (!item.file_thumb.startsWith('data:')) {
        promises.push(
          new Promise((resolve, reject) => {
            getMediaBlobUrl(item.file_thumb)
              .then(response => {
                resolve({
                  url: response,
                  mediaId: item.id,
                })
              })
              .catch(error => {
                reject(error)
              })
          })
        )
      }
    })

    const urlResults = await Promise.all(promises)

    for (let i = 0; i !== parsedMedia.length; i += 1) {
      const media = parsedMedia[i]
      const urlResult = _.find(urlResults, _result => _result.mediaId === media.id)

      thumbs.push({
        mediaType: media.file_type.split('/')[0],
        url: urlResult ? urlResult.url : media.file_thumb,
        index: i,
        videoPlaceholderThumb: '/play-button.jpg',
      })
    }

    this.setState({
      thumbs,
    })
  }

  setSlides = async () => {
    const { parsedMedia, mediaIndex } = this.state
    const slides = []
    const blobUrls = {}

    // Get the large media only for the selected media
    const currentMediaUrl = parsedMedia[mediaIndex].file.startsWith('data:')
      ? parsedMedia[mediaIndex].file
      : await getMediaBlobUrl(parsedMedia[mediaIndex].file)

    _.each(parsedMedia, (media, index) => {
      slides.push({
        mediaId: media.id,
        mediaType: media.file_type.split('/')[0],
      })

      if (mediaIndex === index) {
        blobUrls[media.id] = currentMediaUrl
      } else if (media.file.startsWith('data:')) {
        blobUrls[media.id] = media.file
      } else {
        blobUrls[media.id] = null
      }
    })

    this.setState({
      slides,
      blobUrls,
    })
  }

  setMediaAttributes = () => {
    const { mediaIndex } = this.props
    const { parsedMedia, thumbsPageSize } = this.state

    const { sliderShift, thumbsShift } = this.calculateShift(mediaIndex)

    const sliderWidth = `${parsedMedia.length * 100}vw`
    const thumbsWidth = `${parsedMedia.length * (85 + 10)}px`

    const thumbsPagesCount = Math.ceil(parsedMedia.length / thumbsPageSize)
    const thumbsPage = parseInt(mediaIndex / thumbsPageSize, 10)

    this.setState({
      sliderWidth,
      sliderShift,
      thumbsWidth,
      thumbsShift,
      mediaIndex,
      thumbsPage,
      thumbsPagesCount,
    })
  }

  componentDidMount = async () => {
    this.setState({ loading: true })
    await this.parseMediaList()
    this.setMediaAttributes()
    await this.setThumbs()
    await this.setSlides()
    this.setState({ loading: false })
  }

  render() {
    const { device, breadcrumb, openCloseMediaGallery, updateScrollDisabledStatus } = this.props
    const {
      parsedMedia,
      sliderWidth,
      sliderShift,
      mediaIndex,
      thumbs,
      slides,
      thumbsWidth,
      thumbsShift,
      thumbsPage,
      thumbsPagesCount,
      blobUrls,
      loading,
    } = this.state

    const BreadcrumbComponent = () => {
      const html = []
      for (let i = 0; i !== breadcrumb.length; i += 1) {
        html.push(
          <div
            key={uuidv4()}
            className={classNames({
              media_lightbox_breadcrumb_element: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <span>{breadcrumb[i]}</span>
            {device.screenType === screenTypesMap.desk && i !== breadcrumb.length - 1 && <BreadcrumbIcon />}
          </div>
        )
        if (device.screenType === screenTypesMap.desk && i === 1) {
          html.push(<br key={uuidv4()} />)
        }
      }
      return html
    }

    return (
      <>
        {parsedMedia.length > 0 && (
          <div
            className={classNames({
              media_lightbox_outer_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
            role="button"
            tabIndex={0}
            onClick={e => {
              e.stopPropagation()
            }}
            onKeyPress={e => {
              e.stopPropagation()
            }}
          >
            {slides.length > 0 && (
              <div
                className={classNames({
                  media_lightbox_inner_container: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <div className={classNames({ media_lightbox_bredcrumb: true })}>
                  <BreadcrumbComponent />
                </div>
                <div
                  className={classNames({ media_lightbox_close_btn: true })}
                  role="button"
                  tabIndex={0}
                  onClick={e => {
                    updateScrollDisabledStatus(false)
                    openCloseMediaGallery(e, false, 0)
                  }}
                  onKeyPress={e => {
                    updateScrollDisabledStatus(false)
                    openCloseMediaGallery(e, false, 0)
                  }}
                >
                  <CloseIcon />
                </div>
                <div
                  className={classNames({
                    media_lightbox_slider: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                  style={{ width: sliderWidth, transform: sliderShift }}
                >
                  {slides.map(slide => {
                    const currentUrl = blobUrls[slide.mediaId]
                    if (currentUrl === null && loading) {
                      return (
                        <div key={uuidv4()} className={classNames({ media_lightbox_slide: true })}>
                          <img src="/ajax-loader.gif" alt="loading" />
                        </div>
                      )
                    }
                    return (
                      <div key={uuidv4()} className={classNames({ media_lightbox_slide: true })}>
                        {slide.mediaType !== 'video' && <img src={currentUrl} alt="" />}
                        {slide.mediaType === 'video' && (
                          <Player playsInline src={currentUrl} fluid={false} height="100%" width="100%" />
                        )}
                      </div>
                    )
                  })}
                </div>
                <div
                  className={classNames({
                    media_lightbox_disabled_btn: mediaIndex === 0,
                    media_lightbox_btn: true,
                    media_lightbox_btn_prev: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={async e => {
                    if (mediaIndex > 0) {
                      await this.handleMediaIndexChanged(e, mediaIndex - 1)
                    }
                  }}
                  onKeyPress={async e => {
                    if (mediaIndex > 0) {
                      await this.handleMediaIndexChanged(e, mediaIndex - 1)
                    }
                  }}
                >
                  <PrevIcon />
                </div>
                <div
                  className={classNames({
                    media_lightbox_disabled_btn: mediaIndex === parsedMedia.length - 1,
                    media_lightbox_btn: true,
                    media_lightbox_btn_next: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={async e => {
                    if (mediaIndex < parsedMedia.length - 1) {
                      await this.handleMediaIndexChanged(e, mediaIndex + 1)
                    }
                  }}
                  onKeyPress={async e => {
                    if (mediaIndex < parsedMedia.length - 1) {
                      await this.handleMediaIndexChanged(e, mediaIndex + 1)
                    }
                  }}
                >
                  <NextIcon />
                </div>

                <div
                  className={classNames({
                    media_lightbox_thumbnails_container: true,
                  })}
                >
                  <div
                    className={classNames({
                      media_lightbox_disabled_btn: thumbsPage === 0,
                      media_lightbox_thumb_btn: true,
                      media_lightbox_thumb_btn_prev: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                      if (thumbsPage > 0) {
                        this.handleThumbsPageChanged(e, thumbsPage - 1)
                      }
                    }}
                    onKeyPress={e => {
                      if (thumbsPage > 0) {
                        this.handleThumbsPageChanged(e, thumbsPage - 1)
                      }
                    }}
                  >
                    <PrevThumbsIcon />
                  </div>

                  <div
                    className={classNames({
                      media_lightbox_disabled_btn: thumbsPage === thumbsPagesCount - 1,
                      media_lightbox_thumb_btn: true,
                      media_lightbox_thumb_btn_next: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                      if (thumbsPage < thumbsPagesCount - 1) {
                        this.handleThumbsPageChanged(e, thumbsPage + 1)
                      }
                    }}
                    onKeyPress={e => {
                      if (thumbsPage < thumbsPagesCount - 1) {
                        this.handleThumbsPageChanged(e, thumbsPage + 1)
                      }
                    }}
                  >
                    <NextThumbsIcon />
                  </div>

                  <div className={classNames({ media_lightbox_thumbnails: true })}>
                    <div
                      className={classNames({
                        media_lightbox_thumbnails_inner: true,
                      })}
                      style={{ width: thumbsWidth, transform: thumbsShift }}
                    >
                      {thumbs.map(thumb => (
                        <div
                          key={uuidv4()}
                          className={classNames({ media_lightbox_thumb: true })}
                          style={{
                            backgroundImage:
                              thumb.mediaType === 'video' ? `url(${thumb.videoPlaceholderThumb})` : `url(${thumb.url})`,
                          }}
                          role="button"
                          tabIndex={0}
                          onClick={async e => {
                            await this.handleMediaIndexChanged(e, thumb.index)
                          }}
                          onKeyPress={async e => {
                            await this.handleMediaIndexChanged(e, thumb.index)
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {slides.length <= 0 && loading && (
              <div
                className={classNames({
                  media_lightbox_inner_container: true,
                  media_lightbox_spinner_container: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <img src="/ajax-loader.gif" alt="loading" />
              </div>
            )}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(MediaLightbox)
