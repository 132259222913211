import React from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import * as actionCreators from '../../../../../store/actions'

import DownloadIcon from '../../../../../assets/svg/download-icon.svg'
import RemoveIcon from '../../../../../assets/svg/trash.svg'
import UploadIcon from '../../../../../assets/svg/upload-icon.svg'
import msgBoxMessageMap from '../../../../../../va-corejs-v3/utils/msgBoxMessagesMap'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

export class ReportItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: null,
    }
  }

  componentDidMount = async () => {
    await this.setItem()
  }

  componentDidUpdate = async prevProps => {
    const { item } = this.props

    if (prevProps.item !== item) {
      await this.setItem()
    }
  }

  setItem = async () => {
    const { item } = this.props
    const date = moment(item.created_at)
    item.created_at = date.format('DD-MM-YYYY')

    item.file = await getMediaBlobUrl(item.file)

    item.file_thumb = item.file_thumb
      ? await getMediaBlobUrl(item.file_thumb)
      : 'https://via.placeholder.com/300x208/e3e3e3/747474?text=X'

    this.setState({ item })
  }

  handleRemove = () => {
    const { updateMsgboxMessage, scoringTree, item } = this.props
    updateMsgboxMessage(msgBoxMessageMap.confirmDeleteProductAttachment, {
      scoringId: scoringTree.scoring.id,
      par1: item.file_name,
      item,
      scoringTree,
    })
  }

  render() {
    const { texts, authentication, onUpload } = this.props
    const { item } = this.state

    const autoReportType = () => {
      return item.auto_report ? item.auto_report.type : null
    }

    if (!item) {
      return null
    }

    return (
      <div className="attachment_item attachment_item_report">
        <div className="attachment_item_thumbnail">
          <img src={item.file_thumb} alt="" width="190" height="104" />
        </div>
        <div className="attachment_item_body">
          <div className="attachment_item_title_wrapper">
            <h2 className="attachment_item_title" title={item.file_name}>
              {item.file_name}
            </h2>
          </div>
          <p className="attachment_item_date">
            {texts.attached_at} {item.created_at}
          </p>
          <div className="attachment_item_bottom">
            <div className={`attachment_item_extension ${autoReportType() ?? item.extension}`}>
              {autoReportType() ?? item.extension}
            </div>
            <div className="attachment_item_action_bar">
              {authentication.user.parsedPermissions.canUpdateReport && (
                <div className="attachment_item_button_container">
                  <button
                    type="button"
                    className="attachment_item_button upload"
                    onClick={() => onUpload(item.id)}
                    onKeyPress={() => onUpload(item.id)}
                  >
                    <UploadIcon />
                  </button>
                </div>
              )}
              <div className="attachment_item_button_container">
                <a
                  href={item.file}
                  rel="noreferrer"
                  download
                  target="_blank"
                  className="attachment_item_button download"
                >
                  <DownloadIcon />
                </a>
              </div>
              <div className="attachment_item_button_container">
                <button
                  type="button"
                  className="attachment_item_button remove"
                  onClick={() => this.handleRemove()}
                  onKeyPress={() => this.handleRemove()}
                >
                  <RemoveIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportItem)
