export function scrollToItem(id, isStickyHeader) {
  const win = typeof window === 'object' ? window : null
  if (win) {
    const element = document.querySelector(`div[itemid='${id}']`)

    if (element) {
      const bounding = document.querySelector(`div[itemid='${id}']`).getBoundingClientRect()
      const additionalGap = isStickyHeader ? 160 : 194
      const gap = win.pageYOffset + bounding.top - additionalGap

      const rPanelObj = document.querySelector('.sctree_rp_container')
      rPanelObj.style.height = `${win.innerHeight + gap}px`

      win.scrollTo({
        top: gap,
        behavior: 'smooth',
      })
    }
  }
}

export function scrollLeftPanel(nodeId) {
  const win = typeof window === 'object' ? window : null
  if (win) {
    const lPanel = document.querySelector('.scoring_tree_left_panel')
    const { offsetHeight } = lPanel
    const middle = offsetHeight / 2

    const nodeItm = document.querySelector(`div[itemid='${nodeId}']`)

    if (nodeItm) {
      const nodeBounding = nodeItm.getBoundingClientRect()
      const { y } = nodeBounding

      let distance = 0
      if (y < 0) {
        distance = -middle + y
      } else {
        distance = y - middle
      }
      lPanel.scrollBy({
        top: distance,
        behavior: 'smooth',
      })
    }
  }
}
