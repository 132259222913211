import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import * as actionCreators from '../../../../../store/actions'
import AverageIcon from '../../../../../assets/svg/average.svg'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'

const classNames = require('classnames')

export class ChartAverageSubfamiliesScore extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount = async () => {
    this.setState({
      loaded: true,
    })
  }

  data = () => {
    const { scoringTree, environment } = this.props
    const { nodeDefsObj: nodeDefinitions, scoring } = scoringTree
    const maxScore = environment.config.max_product_score

    const subfamilies = _.keyBy(
      _.filter(nodeDefinitions, element => element.type === nodeDefinitionTypeMap.subfamily),
      'id'
    )

    let sum = 0
    let numberOfElements = 0
    _.each(scoring.nodes, node => {
      if (_.has(subfamilies, node.node_definition_id) && node.is_enabled) {
        sum += _.isNaN(node.normalized_score) ? 0 : node.normalized_score
        numberOfElements += 1
      }
    })

    const average = Math.round(sum / numberOfElements)

    const data = [
      {
        name: 'Average',
        value: average,
        fill: '#3ad600',
      },
      {
        name: 'Max',
        value: maxScore - average,
        fill: '#d6d6da',
      },
    ]

    return data
  }

  renderCustomizedLabel = props => {
    // Some parameters
    const RADIAN = Math.PI / 180

    const { cx, cy, value, name, midAngle, endAngle, innerRadius, outerRadius } = props

    if (name === 'Max') {
      return ''
    }

    // Parameters to draw the label
    const labelRadiusOffset = -5
    const labelRadius = innerRadius + (outerRadius - innerRadius) * 0.5 + labelRadiusOffset
    const labelX = cx + labelRadius * Math.cos(-midAngle * RADIAN)
    const labelY = cy + labelRadius * Math.sin(-midAngle * RADIAN)

    // Parameters to draw the gauge
    const lineOffset = -6
    const lineRadius = innerRadius + lineOffset
    const lineX = cx + lineRadius * Math.cos(-endAngle * RADIAN)
    const lineY = cy + lineRadius * Math.sin(-endAngle * RADIAN)

    // Parameters to draw a small arrow at the top of the gauge
    const arrowRadiusOffset = -5
    const arrowAngleOffset = 5
    const arrowRadius = lineRadius + arrowRadiusOffset
    const arrowLeftAngle = endAngle + arrowAngleOffset
    const arrowLeftX = cx + arrowRadius * Math.cos(-arrowLeftAngle * RADIAN)
    const arrowLeftY = cy + arrowRadius * Math.sin(-arrowLeftAngle * RADIAN)
    const arrowRightAngle = endAngle - arrowAngleOffset
    const arrowRightX = cx + arrowRadius * Math.cos(-arrowRightAngle * RADIAN)
    const arrowRightY = cy + arrowRadius * Math.sin(-arrowRightAngle * RADIAN)

    return (
      <>
        <text
          x={labelX}
          y={labelY}
          fill="white"
          textAnchor={labelX > cx ? 'start' : 'end'}
          dominantBaseline="central"
          fontWeight="500"
          fontSize="12px"
        >
          {value}
        </text>
        <path d={`M ${cx} ${cy} L ${lineX} ${lineY}`} stroke="black" fill="black" />
        <path d={`M ${lineX} ${lineY} L ${arrowLeftX} ${arrowLeftY}`} stroke="black" fill="black" />
        <path d={`M ${lineX} ${lineY} L ${arrowRightX} ${arrowRightY}`} stroke="black" fill="black" />
        <circle cx={cx} cy={cy} r="2" />
      </>
    )
  }

  render() {
    const { texts } = this.props
    const { loaded } = this.state
    const data = this.data()

    if (!loaded) {
      return <></>
    }

    return (
      <div
        className={classNames({
          product_mode_chart_container: true,
        })}
      >
        <h3
          className={classNames({
            product_mode_chart_title: true,
          })}
        >
          <span
            className={classNames({
              product_mode_chart_title_icon: true,
            })}
          >
            <AverageIcon />
          </span>
          <span>{texts.average_score}</span>
        </h3>

        <div style={{ width: '100%', height: 157 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                cy={130}
                data={data}
                dataKey="value"
                innerRadius={42}
                outerRadius={88}
                paddingAngle={2}
                labelLine={false}
                startAngle={180}
                endAngle={0}
                label={this.renderCustomizedLabel}
                isAnimationActive={false}
              >
                {data.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fill={entry.fill} />
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ChartAverageSubfamiliesScore)
