import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import { calculatePopupChartHeight, getProductName } from './common/utils'
import ChartPopup from '../_parts/chartPopup'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ScoreBestWorstChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPopup: false,
      productName: '',
      data: {},
      colors: ['#FF1E21', '#0B6AC6'],
      isDataEmpty: false,
    }
  }

  componentDidMount = async () => {
    this.initComponent()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  initComponent = async () => {
    const { scoringTree, environment, root } = this.props
    const { nodeDefsObj } = scoringTree
    const { nodes, props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setState({ productName })

    const data = {
      0: [],
      4: [],
    }
    function parseChildren(list) {
      for (let i = 0; i !== list.length; i += 1) {
        const nId = list[i]
        const nDef = nodeDefsObj[nId]
        const nInst = nodes[nId]
        if (!nDef.bonus_demerit && nInst.is_enabled) {
          const { type } = nDef
          if (type === nodeDefinitionTypeMap.criterion) {
            const cNode = nodes[nId]
            const { score } = cNode
            if (score === 0 || score === 4) {
              const parent = nodeDefsObj[nDef.parent_id]
              const pName = parent.name[environment.defaultLang]
              const cName = nDef.name[environment.defaultLang]

              data[score].push({
                parent: pName,
                criterion: cName,
              })
            }
          } else {
            const childrenList = nDef.children_ids
            parseChildren(childrenList)
          }
        }
      }
    }

    const children = nodeDefsObj[root].children_ids
    parseChildren(children)

    const isDataEmpty = data[0].length === 0 && data[4].length === 0

    this.setState({ data, isDataEmpty })
  }

  drawChart = key => {
    const { data, showPopup, colors } = this.state
    const items = data[key]

    const html = []
    for (let i = 0; i !== items.length; i += 1) {
      const item = items[i]
      html.push(
        <tr key={uuidv4()} className={classNames({ score_row: true, popup_version: showPopup })}>
          <td
            className={classNames({
              score_label: true,
              popup_version: showPopup,
            })}
            style={{ backgroundColor: key === 0 ? colors[0] : colors[1] }}
          >
            {key}
          </td>
          <td
            className={classNames({
              score_cell: true,
              popup_version: showPopup,
            })}
          >
            {item.parent}
          </td>
          <td
            className={classNames({
              score_cell: true,
              popup_version: showPopup,
            })}
          >
            {item.criterion}
          </td>
        </tr>
      )
    }

    return html
  }

  componentDidUpdate = async prevProps => {
    const { root } = this.props

    const prevRoot = prevProps.root
    const reinitComponent = root !== prevRoot

    if (reinitComponent) {
      await this.initComponent()
    }
  }

  handleShowPopupClicked = () => {
    const { isDataEmpty } = this.state
    if (isDataEmpty) return
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const { data, isDataEmpty, showPopup, productName } = this.state

    // eslint-disable-next-line no-unused-vars
    const theChart = (width, height) => {
      return (
        <div
          className={classNames({
            best_worst_container: true,
            popup_version: showPopup,
          })}
        >
          <div className={classNames({ best_worst_header: true })}>
            <div
              className={classNames({
                best_worst_head_lable: true,
                best_worst_item: true,
                popup_version: showPopup,
              })}
            >
              Item
            </div>
            <div
              className={classNames({
                best_worst_head_lable: true,
                best_worst_criterion: true,
                popup_version: showPopup,
              })}
            >
              Criterion
            </div>
          </div>
          <table className={classNames({ best_container: true })}>
            <tbody>{this.drawChart(4)}</tbody>
          </table>
          <table className={classNames({ worst_container: true })}>
            <tbody>{this.drawChart(0)}</tbody>
          </table>
        </div>
      )
    }

    const renderNoData = () => {
      return <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
    }

    return (
      <>
        {Object.keys(data).length > 0 && showPopup === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              score_best_worst_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div style={{ height: '100%' }} className={classNames({ chart_inner_container: true })}>
              {isDataEmpty ? renderNoData() : theChart('100%', '100%')}
            </div>
          </div>
        )}
        {Object.keys(data).length > 0 && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={`${productName} : ${texts.score_best_worst}`}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoreBestWorstChart)
