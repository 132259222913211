import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import ScoringTreeComponent from '../components/scoring_tree'
import * as actionCreators from '../store/actions'

export class ScoringPage extends React.Component {
  render() {
    return (
      <Layout>
        <ScoringTreeComponent />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    templates: state.templates,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPage)
