import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import CriteriaCommentsIcon from '../../../../assets/svg/criteria-comments-icon.svg'
import CriteriaMediaIcon from '../../../../assets/svg/criteria-media-icon.svg'
import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'

import ReviewBtnFlag from '../../../../assets/svg/review-btn-flag.svg'
import SelectedFlagIcon from '../../../../assets/svg/selected-flag-icon.svg'

import ForecastScoreButtons from './forecastScoreButtons'
import MediaButton from '../rightPanel/mediaButton'
import MediaGallery from '../rightPanel/mediaGallery'
import NodeComments from '../comments/nodeComments'
import Guidelines from '../rightPanel/guidelines'

import { setScoreButtonColorStyle } from '../../../../../va-corejs-v3/utils'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'

const classNames = require('classnames')

export class Forecast extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forceScoringButtonsVisible: false,
      showGuidelines: false,
      placehodersMediaCount: 0,
    }
  }

  updatePlaceholderMediaCount = placehodersMediaCount => {
    this.setState({ placehodersMediaCount })
  }

  updateForceButtonsVisible = (e, forceScoringButtonsVisible) => {
    e.stopPropagation()
    this.setState({ forceScoringButtonsVisible })
  }

  updateShowGuidelines = (e, showGuidelines) => {
    e.stopPropagation()
    this.setState({ showGuidelines })
  }

  handleUpdateShowInterRanking = (e, criterionInstance) => {
    const { updateShowInterRanking, scoringTree } = this.props
    e.stopPropagation()
    updateShowInterRanking(
      criterionInstance.product_id,
      criterionInstance.node_definition_id,
      criterionInstance.score,
      scoringTree.scoring.template_id,
      true
    )
  }

  handleScoreButtonsClick = () => {
    this.setState({ forceScoringButtonsVisible: false })
  }

  render() {
    const {
      texts,
      device,
      environment,
      nodeDefId,
      scoringTree,
      updateExpandedNodeIntoScoring,
      updateEnableDisableNodes,
      updateToReviewValue,
      authentication,
      processSyncQueue,
      commentFocused,
      updateLockNodeCliksOnCommentFocus,
    } = this.props
    const { forceScoringButtonsVisible, showGuidelines, placehodersMediaCount } = this.state

    const productIsLocked = scoringTree.scoring.is_locked

    const criterionDef = scoringTree.nodeDefsObj[nodeDefId]
    const criterionInstance = scoringTree.scoring.nodes[nodeDefId]

    const formattedCommentsCount = criterionInstance.forecast.commentsCount || 0
    const formattedMediaCount = criterionInstance.forecast.mediaCount || 0

    async function handleCriteriaClicked(e) {
      e.stopPropagation()
      const isChildElement =
        e.target.classList.contains('slider') ||
        e.target.classList.contains('sctree_rp_gallery_show_more_less_btn') ||
        e.target.type === 'checkbox' ||
        e.target.type === 'textarea' ||
        e.target.type === 'text' ||
        e.target.type === 'select-one' ||
        e.target.type === 'file'

      if (!isChildElement) {
        if (commentFocused && commentFocused.status === 1) {
          commentFocused.status = 2
          updateLockNodeCliksOnCommentFocus(commentFocused)
          return
        }
        if (commentFocused && commentFocused.status === 2) {
          commentFocused.status = 0
          updateLockNodeCliksOnCommentFocus(commentFocused)
        }
        await updateExpandedNodeIntoScoring(scoringTree.scoring.id, nodeDefId, scoringTree.itemView, 'forecast')
      }
    }

    async function handleEnableDisableClicked(e) {
      e.stopPropagation()
      const checkValue = e.target.checked
      await updateEnableDisableNodes(scoringTree, criterionDef, checkValue, 'forecast')
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    const renderButtonsCondition =
      !criterionInstance.forecast ||
      typeof criterionInstance.forecast.is_default === 'undefined' ||
      (criterionInstance.forecast && criterionInstance.forecast.is_default) ||
      forceScoringButtonsVisible

    const criterionDesc = () => {
      if (criterionDef.criterion_template.description) {
        const description = criterionDef.criterion_template.description[environment.lang]
          ? criterionDef.criterion_template.description[environment.lang]
          : criterionDef.criterion_template.description[environment.defaultLang]

        return description
      }
      return ''
    }

    async function handleReviewButtonClicked(e) {
      e.stopPropagation()
      const toReview =
        criterionInstance.forecast && criterionInstance.forecast.to_review
          ? !criterionInstance.forecast.to_review
          : true

      await updateToReviewValue(scoringTree.scoring.id, nodeDefId, toReview, 'forecast')
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    /* var countNode  = scoringTree.scoring.nodes.filter(x=>x.).length */

    return (
      <div
        className={classNames({
          sctree_rp_criterion: true,
          sctree_rp_criterion_disabled:
            criterionInstance.forecast &&
            typeof criterionInstance.forecast.is_enabled !== 'undefined' &&
            !criterionInstance.forecast.is_enabled,
          sctree_rp_criterion_forecast: true,
        })}
        role="button"
        tabIndex={0}
        onClick={handleCriteriaClicked}
        onKeyPress={handleCriteriaClicked}
      >
        <div
          className={classNames({
            sctree_rp_criterion_top_left_cell: true,
          })}
        >
          <div
            className={classNames({
              sctree_rp_criterion_type: true,
            })}
          >
            {texts.forecast}
          </div>

          <div
            className={classNames({
              sctree_rp_criterion_description: true,
            })}
          >
            {criterionDesc()}
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_criterion_top_right_cell: true,
          })}
        >
          {criterionInstance.forecast &&
            !criterionInstance.forecast.isExpanded &&
            criterionInstance.forecast.to_review && (
              <div
                className={classNames({
                  sctree_rp_criterion_to_review_flag_container: true,
                })}
              >
                <SelectedFlagIcon />
              </div>
            )}
          <div
            className={classNames({
              sctree_rp_criterion_comments_counter: true,
            })}
          >
            <CriteriaCommentsIcon />
            <div
              className={classNames({
                sctree_rp_criterion_comments_count: true,
              })}
            >
              {String(formattedCommentsCount)}
            </div>
          </div>
          <div
            className={classNames({
              sctree_rp_criterion_media_counter: true,
            })}
          >
            <CriteriaMediaIcon />
            <div
              className={classNames({
                sctree_rp_criterion_media_count: true,
              })}
            >
              {String(formattedMediaCount)}
            </div>
          </div>
          <div
            className={classNames({
              sctree_rp_criterion_expand_collapse: true,
            })}
          >
            {criterionInstance.forecast && criterionInstance.forecast.isExpanded ? <CollapseIcon /> : <ExpandIcon />}
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_criterion_score_row: true,
          })}
        >
          {renderButtonsCondition && authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
            <div
              className={classNames({
                sctree_rp_criterion_score_buttons_container: true,
              })}
            >
              <ForecastScoreButtons
                demerit={criterionDef.bonus_demerit}
                criterionInstance={criterionInstance}
                onScoreButtonsClick={this.handleScoreButtonsClick}
              />
            </div>
          )}
          <div
            className={classNames({
              sctree_rp_criterion_right_actions_container: true,
            })}
          >
            {criterionInstance.forecast &&
              typeof criterionInstance.forecast.is_default !== 'undefined' &&
              !criterionInstance.forecast.is_default && (
                <div
                  className={classNames({
                    sctree_rp_criterion_score_value_btn: true,
                    ...setScoreButtonColorStyle(false, criterionInstance.forecast.score, true),
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={
                    authentication.user.parsedPermissions.canEditProduct && !productIsLocked
                      ? e => {
                          this.updateForceButtonsVisible(e, !forceScoringButtonsVisible)
                        }
                      : null
                  }
                  onKeyPress={
                    authentication.user.parsedPermissions.canEditProduct && !productIsLocked
                      ? e => {
                          this.updateForceButtonsVisible(e, !forceScoringButtonsVisible)
                        }
                      : null
                  }
                >
                  {criterionInstance.forecast.score}
                </div>
              )}

            {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
              <div
                className={classNames({
                  sctree_rp_criterion_enable_disable_btn_container: true,
                })}
              >
                <label htmlFor={`forecast_${criterionInstance.id}`} className={classNames({ ios_toggle: true })}>
                  <input
                    id={`forecast_${criterionInstance.id}`}
                    type="checkbox"
                    checked={
                      criterionInstance.forecast &&
                      (typeof criterionInstance.forecast.is_enabled === 'undefined' ||
                        criterionInstance.forecast.is_enabled)
                    }
                    onChange={handleEnableDisableClicked}
                  />
                  <span className={classNames({ slider: true })} />
                </label>
              </div>
            )}
          </div>
        </div>
        {criterionInstance.forecast && criterionInstance.forecast.isExpanded && (
          <div
            className={classNames({
              sctree_rp_exp_area_container: true,
              sctree_rp_criterion_exp_area_container: true,
            })}
          >
            <div
              className={classNames({
                sctree_rp_criterion_exp_area_buttons_container: true,
              })}
            >
              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    sctree_rp_criterion_to_review_btn: true,
                    sctree_rp_criterion_to_review_btn_selected:
                      criterionInstance.forecast && criterionInstance.forecast.to_review,
                    disabled: !authentication.user.parsedPermissions.canEditProduct,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleReviewButtonClicked}
                  onKeyPress={handleReviewButtonClicked}
                >
                  <ReviewBtnFlag /> <span>{texts.to_review}</span>
                </div>
              )}
              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    sctree_rp_item_node_upload_media_container: true,
                  })}
                >
                  <MediaButton
                    label="Media"
                    mediaType="media"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.forecast_node_pictures}
                  />
                  <MediaButton
                    label="Video"
                    mediaType="video"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.forecast_node_pictures}
                  />
                  <MediaButton
                    label="Photo"
                    mediaType="image"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.forecast_node_pictures}
                  />
                </div>
              )}
            </div>
            <MediaGallery
              nodeDefId={nodeDefId}
              scoringId={scoringTree.scoring.id}
              placehodersMediaCount={placehodersMediaCount}
              collection={mediaCollectionsMap.forecast_node_pictures}
            />
            <NodeComments nodeDefId={nodeDefId} productIsLocked={productIsLocked} scoreSet="forecast" />
          </div>
        )}
        {showGuidelines && <Guidelines nodeDefId={nodeDefId} updateShowGuidelines={this.updateShowGuidelines} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    authentication: state.authentication,
    commentFocused: state.scoringTree.commentFocused,
  }
}

export default connect(mapStateToProps, actionCreators)(Forecast)
