import React from 'react'
import { connect } from 'react-redux'

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  LabelList,
  Line,
  Tooltip,
  Legend,
} from 'recharts'

import {
  getGlobalAverageOfSegment,
  getAverageOfSegmentPerNode,
  getBestOfSegment,
  getBestOfSegmentPerNode,
  getBestOfAll,
  getBestOfAllPerNode,
} from '../../../../../../va-corejs-v3/actions/charts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import {
  calculateChartWidthPerPopup,
  calculatePopupChartHeight,
  calulateTickCharactersPerLine,
  doCalculationForAnalytics,
  getProductName,
} from './common/utils'
import { splitTextToLines } from '../../../../../../va-corejs-v3/utils'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class FamilyPerformanceChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.chartRef = React.createRef()

    this.state = {
      loading: false,
      showPopup: false,
      benchmarkExists: false,
      benchmarkSelected: false,
      families: ['appearance', 'usability', 'robustness', 'workmanship'],
      productName: '',
      data: [],
      dataLabels: [],
      target: 0,
      barColor: '',
      benchmarkColor: '#00B0F0',
      settings: {
        chartContainerHeight: 230,
        xAxisDataKey: 'family',
        xAxisHeight: 30,
        colors: {
          exterior: '#BEAFD3',
          interior: '#4698AE',
          cargo: '#D8B0A3',
        },
      },
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  filterNodesByFamily = async (family, fNodes) => {
    const { scoringTree } = this.props
    const { nodeDefsObj } = scoringTree
    const { nodes } = scoringTree.scoring
    const filteredNodes = fNodes ? { ...fNodes } : { ...nodes }

    const idList = Object.keys(filteredNodes)
    const nodeToExclude = []
    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def
      if (type === nodeDefinitionTypeMap.criterion) {
        const nodeFamily = def.criterion_template.type.slug
        if (nodeFamily !== family) {
          nodeToExclude.push(nodeId)
        }
      }
    }

    for (let i = 0; i !== nodeToExclude.length; i += 1) {
      const nodeId = nodeToExclude[i]
      delete filteredNodes[nodeId]
    }

    const filteredNodesWithCalc = await doCalculationForAnalytics(nodeDefsObj, filteredNodes)

    return filteredNodesWithCalc
  }

  recursivelyFilterAndDoCalculationOnFamilies = async (families, trees, fNodes) => {
    if (families.length === 0) return

    const { root, scoringTree } = this.props
    const { nodeDefsObj } = scoringTree
    const { id } = scoringTree.scoring

    const family = families.pop()

    const filteredNodes = await this.filterNodesByFamily(family, fNodes)

    if (root === id) {
      // this is the overview case so we use perimeters score
      const children = []
      const nodeDefsArray = Object.values(nodeDefsObj)
      for (let i = 0; i !== nodeDefsArray.length; i += 1) {
        const nodeDef = nodeDefsArray[i]
        if (nodeDef.parent_id === null) {
          children.push(nodeDef.id)
        }
      }
      let percentageSum = 0
      for (let i = 0; i !== children.length; i += 1) {
        const child = children[i]
        const node = filteredNodes[child]
        const percentage = node?.percentage || 0
        percentageSum += percentage
      }

      trees[family] = {
        percentage: (percentageSum / children.length).toFixed(2),
      }
    } else {
      const node = filteredNodes[root]
      const percentage = node?.percentage || 0
      trees[family] = {
        percentage: percentage.toFixed(2),
      }
    }

    await this.recursivelyFilterAndDoCalculationOnFamilies(families, trees, fNodes)
  }

  getChartColor = () => {
    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree

    const { settings } = this.state
    const { colors } = settings

    const currentNode = nodeDefsObj[root]
    const { type } = currentNode

    let familyName = ''

    function getParent(n) {
      const parentId = n.parent_id
      const p = nodeDefsObj[parentId]
      return p
    }

    if (type === nodeDefinitionTypeMap.family) {
      familyName = currentNode.name.en
    } else if (type === nodeDefinitionTypeMap.subfamily) {
      familyName = getParent(currentNode).name.en
    }

    let fam = ''
    if (familyName.indexOf('Exterior') === 0) fam = 'exterior'
    else if (familyName.indexOf('Interior') === 0) fam = 'interior'
    else if (familyName.indexOf('Cargo') === 0) fam = 'cargo'

    const barColor = colors[fam]
    this.setStateIfMounted({ barColor })
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props
    const { selectedReferenceType } = scoringTree.analytics
    const { props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const { families } = this.state
    const treesByFamily = {}
    const familiesDuplicate = [...families]

    await this.recursivelyFilterAndDoCalculationOnFamilies(familiesDuplicate, treesByFamily)

    const data = []
    const dataLabels = []

    for (let i = 0; i !== families.length; i += 1) {
      // it's better to use map array method
      const family = families[i]
      const familyStructure = treesByFamily[family]
      if (familyStructure) {
        const { percentage } = familyStructure
        if (percentage > 0) {
          dataLabels.push(family)
          data.push({
            family: '',
            performance: percentage,
          })
        }
      }
    }

    this.setStateIfMounted({ data, dataLabels })
    this.getChartColor()
    this.performActionOnReferenceUpdated(selectedReferenceType)
    await this.updateBenchmarkData()
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.scored) === 0) {
      const scoreValue = parseInt(referenceType.replace(`${analyticsRefsTypesMap.scored}_`, ''), 10)
      this.updateTargetDataByScoreValue(scoreValue)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setStateIfMounted({ loading: true })

    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score
    const percentageValue = (calculatedScoreValue * 100) / config.max_product_score
    this.setStateIfMounted({ loading: false, target: percentageValue })
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props
    const { environment } = this.props
    const { config } = environment

    if (root === scoringTree.scoring.id) {
      const averageHit = await getGlobalAverageOfSegment(scoringTree.scoring.template_id, segment)
      const percentageValue = (averageHit.globalAverage * 100) / config.max_product_score
      this.setStateIfMounted({ loading: false, target: percentageValue })
    } else {
      const averageValue = await getAverageOfSegmentPerNode(scoringTree.scoring.template_id, segment, root)
      const percentageValue = (averageValue * 100) / config.max_product_score
      this.setStateIfMounted({ loading: false, target: percentageValue })
    }
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props
    const { environment } = this.props
    const { config } = environment

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    if (root === scoringTree.scoring.id) {
      const bestHit = await getBestOfSegment(scoringTree.scoring.template_id, mainSegment.value.slug)
      const percentageValue = (bestHit.globalAverage * 100) / config.max_product_score
      this.setStateIfMounted({ loading: false, target: percentageValue })
    } else {
      const bestValue = await getBestOfSegmentPerNode(scoringTree.scoring.template_id, mainSegment.value.slug, root)
      const percentageValue = (bestValue * 100) / config.max_product_score
      this.setStateIfMounted({ loading: false, target: percentageValue })
    }
  }

  updateTargetValueByBestOfAll = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props
    const { environment } = this.props
    const { config } = environment

    if (root === scoringTree.scoring.id) {
      const bestHit = await getBestOfAll(scoringTree.scoring.template_id)
      const percentageValue = (bestHit.globalAverage * 100) / config.max_product_score
      this.setStateIfMounted({ loading: false, target: percentageValue })
    } else {
      const bestValue = await getBestOfAllPerNode(scoringTree.scoring.template_id, root)
      let percentageValue = (bestValue * 100) / config.max_product_score
      percentageValue = percentageValue > 100 ? 100 : percentageValue
      this.setStateIfMounted({ loading: false, target: percentageValue })
    }
  }

  updateBenchmarkData = async () => {
    const { scoringTree } = this.props
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { data } = this.state

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })
      const { families, dataLabels } = this.state
      const treesByFamily = {}
      const familiesDuplicate = [...families]

      await this.recursivelyFilterAndDoCalculationOnFamilies(familiesDuplicate, treesByFamily, benchmarkNodes)

      data.map((currentData, index) => {
        // use of datalabels[] instead of families[]
        // to match benchmark value with performance showed value
        const family = dataLabels[index]
        const familyStructure = treesByFamily[family]

        currentData.benchmark = familyStructure.percentage

        return currentData
      })

      this.setState({
        loading: false,
        data,
        benchmarkExists: true,
        benchmarkSelected: true,
      })
    } else if (selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: false,
      })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, root } = this.props

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    const prevRoot = prevProps.root

    const reinitComponent = root !== prevRoot
    const updateUi = currRefType && currRefType !== prevRefType

    if (reinitComponent) {
      await this.initComponent()
    } else if (updateUi) {
      await this.performActionOnReferenceUpdated(currRefType)
      await this.updateBenchmarkData()
    }

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes
    if (benchmarkNodes !== prevBenchmarkNodes) {
      await this.updateBenchmarkData()
    }
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setStateIfMounted({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setStateIfMounted({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      productName,
      data,
      dataLabels,
      target,
      barColor,
      benchmarkColor,
      benchmarkSelected,
      benchmarkExists,
      settings,
    } = this.state
    const { chartContainerHeight, xAxisDataKey, xAxisHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const renderCustomizedLabelOnFront = props => {
      const { x, y, width, value } = props
      const yPosition = (y || 0) - 15

      return (
        <g>
          <foreignObject className="front-label-container" x={x} y={yPosition} width={width} height={15}>
            <span className="front-label">{Math.round(value) || ''}</span>
          </foreignObject>
        </g>
      )
    }

    const renderLegend = props => {
      const { payload } = props
      return (
        <div className={classNames({ custom_legends_container: true })}>
          {payload.map((entry, index) => (
            <div key={`legend_${index}`} className={classNames({ custom_legend_item: true })}>
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames({
                  custom_legend_marker: true,
                  small_dot_marker: true,
                })}
              />
              <div className={classNames({ custom_legend_value: true })}>{entry.value}</div>
            </div>
          ))}
        </div>
      )
    }

    const renderCustomizedAxisTick = props => {
      const { x, y, payload } = props
      const { index } = payload

      const lineHeigh = showPopup === true ? '12px' : '10px'

      const ticksNumber = dataLabels.length
      const { offsetWidth } = this.chartRef.current.container
      const chartPadding = 70

      const maxLineNumber = showPopup === true ? parseInt(xAxisHeight / 12, 10) - 1 : parseInt(xAxisHeight / 10, 10) - 1

      const charactersPerLine = calulateTickCharactersPerLine(offsetWidth, chartPadding, showPopup, ticksNumber)
      const lines = splitTextToLines(dataLabels[index], charactersPerLine)

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {lines.map((line, idx) => (
              <tspan
                key={uuidv4()}
                className={classNames({
                  hidden: idx >= maxLineNumber,
                })}
                x={0}
                dy={lineHeigh}
              >
                {line}
              </tspan>
            ))}
          </text>
        </g>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer ref={this.chartRef} width={width} height={height}>
          <ComposedChart data={data}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey={xAxisDataKey} height={xAxisHeight} tick={renderCustomizedAxisTick} />
            <YAxis type="number" domain={[0, 100]} />
            <Bar dataKey="performance" fill={barColor} radius={[0, 0, 0, 0]}>
              <LabelList dataKey="performance" content={renderCustomizedLabelOnFront} />
            </Bar>

            {benchmarkExists && data.length === 1 && (
              <ReferenceLine y={data[0].benchmark} stroke={benchmarkColor} strokeWidth={2.5} />
            )}
            {benchmarkExists && <Line connectNulls dataKey="benchmark" stroke={benchmarkColor} strokeWidth="2.5" />}

            {benchmarkExists && <Tooltip isAnimationActive={false} formatter={value => Math.round(value)} />}
            {!benchmarkSelected && <ReferenceLine y={target} stroke="#000000" strokeWidth={3} />}
            <Legend content={renderLegend} />
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              family_performance_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && data.length === 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              breakdown_loading_outer_container: true,
            })}
          >
            <div className={classNames({ chart_inner_container: true })} style={{ height: chartContainerHeight }}>
              <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
            </div>
          </div>
        )}
        {loading === false && showPopup === false && data.length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              family_performance_outer_countainer: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div style={{ height: chartContainerHeight }} className={classNames({ chart_inner_container: true })}>
              {theChart('100%', chartContainerHeight)}
            </div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.percentage_performance_per_criteria_family}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            width={calculateChartWidthPerPopup(data)}
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(FamilyPerformanceChart)
