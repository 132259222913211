import React from 'react'
import storage from '../../../../../../../va-corejs-v3/storage'
import storageMap from '../../../../../../../va-corejs-v3/storage/storageMap'
import scopePropsMap from '../../../../../scoring/_parts/helper/scopePropsMap'
import nodeDefinitionTypeMap from '../../../../helper/nodeDefinitionTypeMap'

export const getProductName = async props => {
  const brand = props.filter(x => x.slug === scopePropsMap.brand)[0].value.body.en
  const model = props.filter(x => x.slug === scopePropsMap.model)[0].value.body.en

  const name = `${brand} ${model}`
  return name
}

export const findMaxs = (
  levelIndex,
  treeMap,
  perimeterId,
  parentId,
  maxScores,
  defKeys,
  nodeDefinitions,
  productNodes
) => {
  if (levelIndex > treeMap.length - 1) {
    return
  }

  for (let i = 0; i < defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type === treeMap[levelIndex] && def.parent_id === parentId) {
      if (!maxScores[perimeterId][treeMap[levelIndex]]) {
        maxScores[perimeterId][treeMap[levelIndex]] = 0
      }
      if (maxScores[perimeterId][treeMap[levelIndex]] < productNodes[def.id].score) {
        maxScores[perimeterId][treeMap[levelIndex]] = productNodes[def.id].score
      }
      findMaxs(levelIndex + 1, treeMap, perimeterId, def.id, maxScores, defKeys, nodeDefinitions, productNodes)
    }
  }
}

export const setNormalizedScores = (defKeys, nodeDefinitions, productNodes) => {
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const product = productNodes[id]
    if (product) {
      const { score, maxScore } = product
      product.normalizedScore = (score * configMaxScore) / maxScore
      product.normalizedScore = product.normalizedScore > configMaxScore ? configMaxScore : product.normalizedScore
    }
  }
}

export const doCalculationForAnalytics = async (nodeDefinitions, productNodes) => {
  const config = await storage.get(storageMap.config, 0)
  let treeMap = process.env.treeMap.split(',')

  // Perimeter score got calculated for charts, not used in scoringtree view
  // treeMap.shift() // Remove Perimeter because calc arrive only on families

  treeMap.pop() // Remove criteria
  treeMap = treeMap.reverse() // Reverse array so now i have [item, subfamily, family, perimeter]

  const criteria = []
  const demerits = []
  const defKeys = Object.keys(nodeDefinitions)

  // Get Criteria and Demerits
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const node = productNodes[id]
    if (node) {
      if (def.type === nodeDefinitionTypeMap.criterion && node.is_enabled === true) {
        if (def.bonus_demerit === false) {
          criteria.push(def)
        } else {
          demerits.push(def)
        }
      }
    }
  }

  // Empty all scores but criteria
  const pNodeKeys = Object.keys(productNodes)
  for (let i = 0; i !== pNodeKeys.length; i += 1) {
    const nodeDefinitionId = pNodeKeys[i]
    const node = productNodes[nodeDefinitionId]
    const { type } = nodeDefinitions[node.node_definition_id]
    if ([nodeDefinitionTypeMap.criterion].indexOf(type) === -1) {
      node.score = 0
      node.maxScore = 0
      node.percentage = 0
      node.normalizedScore = 0
      node.scored = 0
      node.notScored = 0
      node.percentageScored = 0
      if (type !== 'item') {
        node.itemsCount = 0
      }
    }
  }

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // For each Criterion multiply score*weigth then for parents weight
  // on treeMap from criteria to perimeters
  for (let c = 0; c < criteria.length; c += 1) {
    const criterion = criteria[c]
    // get scoring node
    const node = productNodes[criterion.id]
    productNodes[criterion.id].maxScore = global.env.config.max_score
    productNodes[criterion.id].percentage =
      (productNodes[criterion.id].score * 100) / productNodes[criterion.id].maxScore

    // Start calc on criterion
    let calc = node.score * nodeDefinitions[node.node_definition_id].weight
    let maxCalc = global.env.config.max_score * nodeDefinitions[node.node_definition_id].weight

    let fatherNodeDefinitionId = criterion.parent_id

    // For each criterion's parent
    // This has been changed to include perimeter...
    // We have now treeMap.length = 4 (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Get template father
      const father = nodeDefinitions[fatherNodeDefinitionId]

      // Do calc
      calc *= father.weight
      maxCalc *= father.weight

      if (!node.is_enabled || node.is_default) {
        productNodes[father.id].notScored += 1
      } else {
        productNodes[father.id].scored += 1
      }

      // Get this node father id
      fatherNodeDefinitionId = father.parent_id
    }

    // Restart from Item
    fatherNodeDefinitionId = criterion.parent_id

    // For each criterion's parent sum calc
    // Also here we loop 4 levels (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Sum calc on this node's score
      productNodes[fatherNodeDefinitionId].score += calc
      productNodes[fatherNodeDefinitionId].maxScore += maxCalc
      productNodes[fatherNodeDefinitionId].percentage =
        (productNodes[fatherNodeDefinitionId].score * 100) / productNodes[fatherNodeDefinitionId].maxScore

      // Keep this. For now Perimeter has score
      // if (maxScores[treeMap[t]] < productNodes[fatherNodeDefinitionId].score) {
      // maxScores[treeMap[t]] = productNodes[fatherNodeDefinitionId].score
      // }

      // Get this node father id
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      // In case of perimeter the fatherNodeDefinitionId is null
      // We do not set anything at father itemsCount for perimeter
      if (fatherNodeDefinitionId) {
        productNodes[fatherNodeDefinitionId].itemsCount += 1
      }
    }
  }

  // STEP 1.2 GUESS DEMERITS SCORED / UNSCORED
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]
    const node = productNodes[demerit.id]
    let fatherNodeDefinitionId = demerit.parent_id
    for (let t = 0; t < treeMap.length + 1; t += 1) {
      const father = nodeDefinitions[fatherNodeDefinitionId]
      if (father) {
        if (!node.is_enabled || node.is_default) {
          productNodes[father.id].notScored += 1
        } else {
          productNodes[father.id].scored += 1
        }
        fatherNodeDefinitionId = father.parent_id
      }
    }
  }

  // STEP 2
  // -----------------------------------------------------------------------------------------
  // Calculate family demerit
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]

    let fatherNodeDefinitionId = demerit.parent_id
    for (let t = 1; t < treeMap.length; t += 1) {
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      productNodes[fatherNodeDefinitionId].itemsCount += 1
    }

    const family = productNodes[fatherNodeDefinitionId]
    let famScore = productNodes[fatherNodeDefinitionId].score
    famScore += (productNodes[demerit.id].score * family.score * config.demerit_behavior.value) / 100

    productNodes[fatherNodeDefinitionId].score = famScore
  }

  // STEP 3
  // -----------------------------------------------------------------------------------------
  // Get levels maxScores per perimeter
  // This will contain max score of every level distinct for every perimeter
  treeMap = treeMap.reverse() // Reverse array so now i have [perimeter, family, subfamily, item]
  const maxScores = {}
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type === 'perimeter') {
      maxScores[def.id] = {}
      findMaxs(0, treeMap, def.id, def.id, maxScores, defKeys, nodeDefinitions, productNodes)
    }
  }

  // STEP 4
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  setNormalizedScores(defKeys, nodeDefinitions, productNodes)

  // STEP 5
  // -----------------------------------------------------------------------------------------
  // For every node but criterion calc scored percentage
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type !== nodeDefinitionTypeMap.criterion) {
      const node = productNodes[def.id]
      if (node) {
        const { scored, notScored } = node
        const tot = scored + notScored
        if (tot > 0) {
          productNodes[def.id].percentageScored = (scored / tot) * 100
        }
      }
    }
  }

  return productNodes
}

export const getCriterionTypeList = () => {
  const criterionTypeList = [
    'gap-flush',
    'coherence-harmony-integration',
    'visual-quality-materials-style-treatment-of-parts-light-sources',
    'reassuring-appearance-well-being-feeling-seems-robust-safety-feeling-roominess-and-comfort-feeling',
    'hiding-technical-elements',
    'material-touch-quality',
    'ease-of-use-practicality-intuitiveness-notion-of-ergonomics',
    'sensation-to-manipulation-pleasure-to-use-effort-and-movement-controlled-and-adepted-haptic-feedback',
    'sound-functional-sound',
    'equipment-protection',
    'actual-robustness',
    'maintain-new-feeling-of-durability-cleanability',
    'craftsmanship-appearance-defects',
  ]
  return criterionTypeList
}

export const getCriterionTypeShortLabels = async (scoringTree, lang) => {
  const { nodeDefsObj } = scoringTree
  const { nodes } = scoringTree.scoring
  const idList = Object.keys(nodes)

  const shortLabels = {}
  for (let i = 0; i !== idList.length; i += 1) {
    const nodeId = idList[i]
    const def = nodeDefsObj[nodeId]
    const { type } = def

    if (type === nodeDefinitionTypeMap.criterion) {
      const shortLabel = def.criterion_template.short_label[lang]
      shortLabels[def.criterion_template.slug] = shortLabel
    }
  }

  return shortLabels
}

export const calculatePopupChartHeight = () => {
  const height = typeof window !== 'undefined' ? window.innerHeight - 210 : 400
  return height
}

export const calculateChartWidthPerPopup = data => {
  if (!data) return '100%'
  const minWidthPopup = 300
  const maxItemsPerView = 15
  const extra = data.length - maxItemsPerView
  const plus = extra * 6.5
  const popupWidth = (window.innerWidth / 100) * parseInt(100 + plus, 10)

  const containerWidth = minWidthPopup > popupWidth ? minWidthPopup : `${parseInt(100 + plus, 10)}%`

  return containerWidth
}

export const calculateInnerContainerWidth = data => {
  if (!data) return '100%'

  const maxItemsPerView = 5
  const extra = data.length - maxItemsPerView
  const plus = extra * 20

  const innerContainerWidth = `${parseInt(100 + plus, 10)}%`
  return innerContainerWidth
}

export const calulateTickCharactersPerLine = (offsetWidth, chartPadding, showPopup, ticksNumber) => {
  const charWidth = showPopup === true ? 8 : 6
  const lineWidht = parseInt((offsetWidth - chartPadding) / ticksNumber, 10)
  const charactersPerLine = parseInt(lineWidht / charWidth, 10)
  return charactersPerLine
}

export const getFullDotShape = item => {
  return <circle transform={`translate(${item.cx} ${item.cy})`} r="5" fill={item.fill} fillRule="evenodd" />
}

export const getCurrentProductMarker = item => {
  return (
    <g
      id="currentProductMarker"
      data-name="currentProductMarker"
      transform={`translate(${item.cx} ${item.cy}) rotate(-45)`}
      fill={item.fill}
      stroke={item.fill}
      strokeWidth="1"
    >
      <rect width="7" height="7" stroke="none" />
      <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
    </g>
  )
}
export const getTargetProductMarker = item => {
  return (
    <path
      id="targetProductMarker"
      d="M8,3H5.125L4,0,2.875,3H0L2.165,4.894,1,8,4,6.5,7,8,5.835,4.894Z"
      transform={`translate(${item.cx} ${item.cy})`}
      fill={item.fill}
      stroke={item.fill}
      strokeWidth="1"
    />
  )
}
export const getBenchmarkProductMarker = item => {
  return <circle transform={`translate(${item.cx} ${item.cy})`} r="5" fill={item.fill} fillRule="evenodd" />
}

export const getCurrentProductMarkerLarge = item => {
  return (
    <g
      id="currentProductMarker"
      data-name="currentProductMarker"
      transform={`translate(${item.cx - 7} ${item.cy}) rotate(-45)`}
      fill={item.fill}
      stroke={item.fill}
      strokeWidth="1"
    >
      <rect width="11" height="11" stroke="none" />
      <rect x="0.5" y="0.5" width="10" height="10" fill="none" />
    </g>
  )
}
export const getTargetProductMarkerLarge = item => {
  return (
    <path
      id="targetProductMarker"
      d="M8,3H5.125L4,0,2.875,3H0L2.165,4.894,1,8,4,6.5,7,8,5.835,4.894Z"
      transform={`translate(${item.cx - 5} ${item.cy - 5}) scale(1.4)`}
      fill={item.fill}
      stroke={item.fill}
      strokeWidth="1"
    />
  )
}
export const getBenchmarkProductMarkerLarge = item => {
  return <circle transform={`translate(${item.cx} ${item.cy})`} r="7" fill={item.fill} fillRule="evenodd" />
}

export const getEmptyDotShape = item => {
  return (
    <circle
      transform={`translate(${item.cx} ${item.cy})`}
      r="8"
      fill={item.fill}
      fillRule="evenodd"
      stroke={item.stroke}
      strokeWidth="2"
    />
  )
}

export const getBigTargetShape = item => {
  return (
    <g fill={item.fill} transform={`translate(${item.cx - 12} ${item.cy - 12})`}>
      <path
        stroke={item.stroke}
        strokeLinejoin="round"
        strokeWidth="4"
        d="M2.61 3.795C5.516.889 9.934.412 13.336 2.363h0L11.901 3.8C9.312 2.57 6.126 3.024 3.983 5.167c-2.722 2.722-2.722 7.133 0 9.854 2.721 2.722 7.132 2.722 9.854 0 2.143-2.143 2.6-5.33 1.368-7.918h0l1.432-1.44c1.95 3.403 1.474 7.821-1.432 10.727-3.476 3.48-9.115 3.48-12.595 0-3.48-3.476-3.48-9.115 0-12.595zm2.517 2.52C6.61 4.829 8.759 4.4 10.623 5.034h0l-1.52 1.52c-.973-.055-1.962.289-2.706 1.033-1.385 1.385-1.385 3.632 0 5.017 1.385 1.385 3.632 1.385 5.017 0 .744-.744 1.088-1.737 1.033-2.71h0l1.52-1.52c.633 1.868.205 4.013-1.283 5.5-2.085 2.086-5.472 2.086-7.557 0-2.086-2.085-2.086-5.472 0-7.557zM16.829 0l.255 1.916L19 2.171l-2.533 2.533-.81-.108L10.653 9.6c.042.15.065.313.065.478 0 .991-.805 1.797-1.796 1.797s-1.797-.806-1.797-1.797c0-.99.806-1.796 1.797-1.796.165 0 .324.023.478.065h0l5.004-5.004-.108-.81L16.83 0z"
        transform="translate(2 2)"
      />
      <path
        d="M2.61 3.795C5.516.889 9.934.412 13.336 2.363L11.901 3.8C9.312 2.57 6.126 3.024 3.983 5.167c-2.722 2.722-2.722 7.133 0 9.854 2.721 2.722 7.132 2.722 9.854 0 2.143-2.143 2.6-5.33 1.368-7.918l1.432-1.44c1.95 3.403 1.474 7.821-1.432 10.727-3.476 3.48-9.115 3.48-12.595 0-3.48-3.476-3.48-9.115 0-12.595zm2.517 2.52C6.61 4.829 8.759 4.4 10.623 5.034l-1.52 1.52c-.973-.055-1.962.289-2.706 1.033-1.385 1.385-1.385 3.632 0 5.017 1.385 1.385 3.632 1.385 5.017 0 .744-.744 1.088-1.737 1.033-2.71l1.52-1.52c.633 1.868.205 4.013-1.283 5.5-2.085 2.086-5.472 2.086-7.557 0-2.086-2.085-2.086-5.472 0-7.557zM16.829 0l.255 1.916L19 2.171l-2.533 2.533-.81-.108L10.653 9.6c.042.15.065.313.065.478 0 .991-.805 1.797-1.796 1.797s-1.797-.806-1.797-1.797c0-.99.806-1.796 1.797-1.796.165 0 .324.023.478.065l5.004-5.004-.108-.81L16.83 0z"
        transform="translate(2 2)"
      />
    </g>
  )
}

export const getSmallTargetShape = item => {
  return (
    <path
      transform={`translate(${item.cx - 10} ${item.cy - 10})`}
      fill={item.fill}
      d="M2.61 3.795C5.516.889 9.934.412 13.336 2.363L11.901 3.8C9.312 2.57 6.126 3.024 3.983 5.167c-2.722 2.722-2.722 7.133 0 9.854 2.721 2.722 7.132 2.722 9.854 0 2.143-2.143 2.6-5.33 1.368-7.918l1.432-1.44c1.95 3.403 1.474 7.821-1.432 10.727-3.476 3.48-9.115 3.48-12.595 0-3.48-3.476-3.48-9.115 0-12.595zm2.517 2.52C6.61 4.829 8.759 4.4 10.623 5.034l-1.52 1.52c-.973-.055-1.962.289-2.706 1.033-1.385 1.385-1.385 3.632 0 5.017 1.385 1.385 3.632 1.385 5.017 0 .744-.744 1.088-1.737 1.033-2.71l1.52-1.52c.633 1.868.205 4.013-1.283 5.5-2.085 2.086-5.472 2.086-7.557 0-2.086-2.085-2.086-5.472 0-7.557zM16.829 0l.255 1.916L19 2.171l-2.533 2.533-.81-.108L10.653 9.6c.042.15.065.313.065.478 0 .991-.805 1.797-1.796 1.797s-1.797-.806-1.797-1.797c0-.99.806-1.796 1.797-1.796.165 0 .324.023.478.065l5.004-5.004-.108-.81L16.83 0z"
    />
  )
}

export const getRhombusFullShape = item => {
  return (
    <path
      transform={`translate(${item.cx - 10} ${item.cy - 10}) rotate(45 145.175 2.873)`}
      fill={item.fill}
      fillRule="evenodd"
      d="M46.5 97.485H58.5V109.485H46.5z"
    />
  )
}

export const getRhombusEmptyShape = item => {
  return (
    <path
      transform={`translate(${item.cx - 10} ${item.cy - 10}) rotate(45 173.76 -67.347)`}
      fill={item.fill}
      fillRule="evenodd"
      stroke={item.stroke}
      strokeWidth="2"
      d="M107 97.485H119V109.485H107z"
    />
  )
}
