import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import { array2Object } from '../../../../../va-corejs-v3/utils'
import scopePropsMap from '../../../scoring/_parts/helper/scopePropsMap'
import scoringPropTypesMap from '../../../scoring/_parts/helper/scoringPropsTypeMap'

const classNames = require('classnames')

export class PropsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      objectizedProps: {},
    }
  }

  componentDidMount = async () => {
    const { scoringTree } = this.props

    const objectizedProps = array2Object(scoringTree.scoring.props, 'slug')
    this.setState({ objectizedProps })
  }

  render() {
    const { texts, environment, scoringTree, propsList, title } = this.props
    const { objectizedProps } = this.state
    const { scopeProps } = scoringTree

    function propLabel(slug) {
      const prop = _.find(scopeProps, item => item.slug === slug)

      if (!prop) {
        return null
      }

      return prop.label[environment.lang]
    }

    function propValue(slug) {
      const prop = objectizedProps[slug]

      if (!prop) {
        return '--'
      }

      if (prop.type === scoringPropTypesMap.price) {
        const minProp = _.find(prop.props, item => item.slug === scopePropsMap.price_minimum)
        const maxProp = _.find(prop.props, item => item.slug === scopePropsMap.price_maximum)
        const currencyProp = _.find(prop.props, item => item.slug === scopePropsMap.price_currency)
        const priceOfModelProp = _.find(prop.props, item => item.slug === scopePropsMap.price_of_model)

        if (priceOfModelProp.value.body[environment.lang] !== null) {
          return `${priceOfModelProp.value.body[environment.lang]} ${currencyProp.value.body[environment.lang]}`
        }

        return `${minProp.value.body[environment.lang]} - ${maxProp.value.body[environment.lang]} ${
          currencyProp.value.body[environment.lang]
        }`
      }
      if (prop.type === scoringPropTypesMap.distance) {
        return `${prop.props[0].value.body[environment.lang]} ${prop.props[1].value.body[environment.lang]}`
      }
      if (prop.type === scopePropsMap.tire_size) {
        return (
          <span>
            <div>
              <span>{texts.width}: </span>
              <span>{prop.props[0].value.body[environment.lang]}</span>
            </div>
            <div>
              <span>{texts.top}: </span>
              <span>{prop.props[1].value.body[environment.lang]}</span>
            </div>
            <div>
              <span>{texts.diam}: </span>
              <span>
                {prop.props[2] &&
                  prop.props[2].value &&
                  prop.props[2].value.body &&
                  prop.props[2].value.body[environment.defaultLang]}
              </span>
            </div>
          </span>
        )
      }
      if (prop.type === scoringPropTypesMap.text_or_image) {
        return prop.props[0].value.body[environment.defaultLang]
      }
      return prop.value.body[environment.defaultLang]
    }

    return (
      <>
        {title && (
          <h3
            className={classNames({
              product_mode_props_title: true,
            })}
          >
            {title}
          </h3>
        )}

        <table
          className={classNames({
            product_mode_props_table: true,
          })}
        >
          <tbody>
            {propsList.map(propSlug => (
              <tr key={propSlug}>
                <td
                  className={classNames({
                    product_mode_props_table_definition: true,
                  })}
                >
                  {propLabel(propSlug)}
                </td>
                <td
                  className={classNames({
                    product_mode_props_table_value: true,
                  })}
                >
                  {propValue(propSlug)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(PropsTable)
