import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'
import DownloadIcon from '../../../../assets/svg/download-icon.svg'

const classNames = require('classnames')

export class DownloadMediaButton extends React.Component {
  handleDownloadClick = async e => {
    e.stopPropagation()
    const { media } = this.props

    const downloadUrl = media.file ? await getMediaBlobUrl(`${media.file}?disposition=attachment`) : null
    const a = document.createElement('a')
    if (downloadUrl) {
      a.href = downloadUrl
      a.download = media.file_name
    } else {
      a.href = media.file_thumb
      a.download = `Image.${media.file_ext}`
    }

    a.click()
  }

  render() {
    return (
      <div
        className={classNames({
          sctree_rp_gallery_item_btn: true,
          sctree_rp_gallery_item_download_btn: true,
        })}
        role="button"
        tabIndex={0}
        onClick={async e => {
          await this.handleDownloadClick(e)
        }}
        onKeyPress={async e => {
          await this.handleDownloadClick(e)
        }}
      >
        <DownloadIcon />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(DownloadMediaButton)
