import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'

import LoadingBar from '../../../../loading_bar/index.js'

import { searchProductsByTemplate } from '../../../../../../va-corejs-v3/actions/elastic_search'

const classNames = require('classnames')

export class BenchmarkSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      benchmarkName: '',
      pIdList: [],
      products: {},
    }
  }

  componentDidMount = async () => {
    const { scoringTree } = this.props

    const results = await searchProductsByTemplate(scoringTree.scoring.template_id)

    if (results.status === 200) {
      const { data } = results
      const { hits } = data.hits
      const parsedProducts = await this.parseProductsFromElasticSearch(hits)
      const { pIdList, products } = parsedProducts
      const benchmarkName = await this.getBenchmarkName(products)

      this.setState({ initialized: true, benchmarkName, pIdList, products })
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  getBenchmarkName = async products => {
    const { scoringTree } = this.props
    const { analytics } = scoringTree
    const { benchmarkId } = analytics

    const benchmarkName = benchmarkId !== 0 ? products[benchmarkId] : ''
    return benchmarkName
  }

  parseProductsFromElasticSearch = async hits => {
    const { scoringTree } = this.props
    const { scoring } = scoringTree

    const products = {}
    const pIdList = []
    for (let i = 0; i !== hits.length; i += 1) {
      const hit = hits[i]._source
      const { id, props } = hit
      if (id !== scoring.id) {
        pIdList.push(id)

        const brand = props.brand.value
        const model = props.model.value
        let name = `${brand} ${model}`

        if (props['commercial-name-grade']) {
          const commercialGrade = props['commercial-name-grade'].value
          name = `${brand} ${model} ${commercialGrade}`
        }

        products[id] = name
      }
    }
    return {
      pIdList,
      products,
    }
  }

  handleInputchanged = async benchmarkName => {
    const { pIdList, products } = this.state
    const {
      scoringTree,
      updateAnalyticsBenchmarkSelectedId,
      updateAnalyticsBenchmarkSelectedNodes,
      updateAnalyticsBenchmarkSelectedName,
    } = this.props

    this.setState({ benchmarkName })

    let selectedProductId = 0

    for (let i = 0; i !== pIdList.length; i += 1) {
      const pID = pIdList[i]
      const pName = products[pID]

      // Trim names for a better comparison
      if (pName.replace(/\s/g, '') === benchmarkName.replace(/\s/g, '')) {
        selectedProductId = pID
        break
      }
    }

    if (selectedProductId !== 0 || (selectedProductId === 0 && benchmarkName === '')) {
      await updateAnalyticsBenchmarkSelectedId(scoringTree.scoring.id, selectedProductId)

      await updateAnalyticsBenchmarkSelectedNodes(
        scoringTree.scoring.id,
        selectedProductId,
        scoringTree.scoring.template_id
      )

      await updateAnalyticsBenchmarkSelectedName(scoringTree.scoring.id, benchmarkName)
    }
  }

  render() {
    const { initialized, benchmarkName, products } = this.state
    const productIds = Object.keys(products)

    return (
      <>
        {initialized === false && (
          <div
            className={classNames({
              benchmark_selector_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {initialized === true && (
          <div className={classNames({ benchmark_selector: true })}>
            <div className={classNames({ benchmark_list_container: true })}>
              <input
                type="text"
                value={benchmarkName}
                placeholder="Benchmark product"
                className={classNames({ benchmark_input: true })}
                list="benchmark_list"
                onChange={e => {
                  this.handleInputchanged(e.target.value)
                }}
              />
              <datalist id="benchmark_list">
                {productIds.map(pID => {
                  return (
                    <option key={pID} id={pID}>
                      {products[pID]}
                    </option>
                  )
                })}
              </datalist>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(BenchmarkSelector)
