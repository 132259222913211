/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import * as actionCreators from '../../store/actions'
import Warning from '../../assets/svg/warning.svg'

const classNames = require('classnames')

class ConfirmationModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClose = e => {
    e.stopPropagation()
    const { onClose } = this.props

    onClose()
  }

  handleConfirm = async e => {
    e.stopPropagation()
    const { onConfirm } = this.props

    this.setState({
      loading: true,
    })

    await onConfirm()

    this.setState({
      loading: false,
    })
  }

  render() {
    const { message, open } = this.props
    const { loading } = this.state

    if (!open) {
      return <></>
    }

    return (
      <div
        className={classNames({
          msgbox_container: true,
        })}
      >
        <div
          className={classNames({
            msgbox_shell: true,
          })}
        >
          <div className="msgbox_warning_container">
            <Warning className="msgbox_warning_svg" />
          </div>
          <div className="msgbox_message_container">
            <div
              className={classNames({
                msgbox_message: true,
                left_align: true,
              })}
            >
              <p>{message}</p>
            </div>
          </div>
          <div className="msgbox_button_container">
            <button
              type="button"
              className="msgbox_btn"
              onClick={e => {
                this.handleConfirm(e)
              }}
              disabled={loading}
            >
              OK
            </button>
            <button
              type="button"
              className="msgbox_btn"
              onClick={e => {
                this.handleClose(e)
              }}
              disabled={loading}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    downloaded: state.downloaded,
    msgboxMessage: state.msgbox.msgboxMessage,
    data: state.msgbox.data,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(ConfirmationModal)
