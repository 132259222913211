import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'

import ScoringPie from './scoringPie'

import scopePropsMap from '../../scoring/_parts/helper/scopePropsMap'
import ScoringModeMap from '../helper/scoringModeMap'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'

import ScorePanel from '../../commons/score_panel'

import ContextualMenu from './contextualMenu'
import EditScoringBtn from '../../../assets/svg/edit-scoring.svg'
import MediaIcon from '../../../assets/svg/media-icon.svg'
import CommentIcon from '../../../assets/svg/comment-icon.svg'

import EditScoring from './editScoring'

import { httpPost, getMediaBlobUrl } from '../../../../va-corejs-v3/api'
import { updateQuerystringWithoutReloadPage } from '../../../../va-corejs-v3/utils'
import apiEndPoints from '../../../../va-corejs-v3/api/apiEndPoints'
import msgBoxMessageMap from '../../../../va-corejs-v3/utils/msgBoxMessagesMap'
import CalculationModeButtons from './projectMode/calculationModeButtons'
import { isProjectModeEnabled, normalizeProjectModeCount } from './projectMode/utils'

const classNames = require('classnames')

export class ScoringSubHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showEditPanel: false,
      brandProps: {},
      howToImport: '',
      featuredImageUrl: '',
    }
  }

  openEditPanel = async () => {
    const { updateScopes } = this.props
    await updateScopes()
    this.setState({ showEditPanel: true })
  }

  closeEditPanel = () => {
    this.setState({ showEditPanel: false })
  }

  componentDidMount = async () => {
    const { scoringTree, getScoringTreeFeaturedImage } = this.props
    getScoringTreeFeaturedImage(scoringTree.scoring.id, mediaCollectionsMap.cover_pictures)
    await this.setBrandProps()
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree } = this.props
    const { scoringTree: prevScoringTree } = prevProps

    if (
      scoringTree.header &&
      prevScoringTree.header &&
      scoringTree.header.featuredImg.file !== prevScoringTree.header.featuredImg.file
    ) {
      await this.setFeaturedImageUrl()
    }

    if (scoringTree.scoring.props !== prevScoringTree.scoring.props) {
      await this.setBrandProps()
    }
  }

  setFeaturedImageUrl = async () => {
    const { scoringTree } = this.props
    const featuredImgFile = scoringTree.header.featuredImg.file
    if (featuredImgFile) {
      const featuredImageUrl = featuredImgFile.startsWith('data:')
        ? featuredImgFile
        : await getMediaBlobUrl(featuredImgFile)
      this.setState({ featuredImageUrl })
    }
  }

  setBrandProps = async () => {
    const { environment, scoringTree } = this.props

    const brand = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.brand)[0]

    const brandName = brand.value.body[environment.defaultLang]

    const brandDef = scoringTree.scopeProps.filter(x => x.slug === scopePropsMap.brand)[0]

    let brandLogo = ''
    if (brand.value.slug !== null) {
      const brandLogoObject = brandDef.values.filter(x => x.slug === brand.value.slug)[0]
      brandLogo =
        brandLogoObject && brandLogoObject.media && brandLogoObject.media.length > 0
          ? await getMediaBlobUrl(brandLogoObject.media[0].url)
          : ''
    }

    const brandProps = {
      brandName,
      brandLogo,
    }

    this.setState({ brandProps })
  }

  handleImport = howToImport => {
    this.setState({ howToImport })
    document.getElementById(`inputImport`).click()
  }

  handleInputChange = async () => {
    const { scoringTree, updateMsgboxMessage } = this.props
    const { howToImport } = this.state

    updateMsgboxMessage(msgBoxMessageMap.preparingUpload)

    const file = document.getElementById(`inputImport`).files[0]
    const endPoint = apiEndPoints[howToImport].replace('{scoringId}', scoringTree.scoring.id)
    const formData = new FormData()
    formData.append('file', file)
    await httpPost(endPoint, formData, false)
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
    updateMsgboxMessage()
  }

  render() {
    const {
      authentication,
      device,
      environment,
      texts,
      scoringTree,
      updateScoringSelectedMode,
      updateScrollDisabledStatus,
      scorePanelObj,
    } = this.props

    const { showEditPanel, brandProps, featuredImageUrl } = this.state

    const userRoles = authentication.user.roles
    const userRole = userRoles.length > 0 ? userRoles[0].slug : 'reader'

    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    const model = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.model)[0].value.body[environment.lang]

    const commercialNameGradeProp = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.comm_name_grade)
    const commercialNameGrade =
      commercialNameGradeProp.length > 0 ? commercialNameGradeProp[0].value.body[environment.defaultLang] : ''

    function handleScoringModeSelected() {
      updateScoringSelectedMode(ScoringModeMap.scoring)
      updateQuerystringWithoutReloadPage('view', ScoringModeMap.scoring)
    }
    function handleProductModeSelected() {
      updateScoringSelectedMode(ScoringModeMap.product)
      updateQuerystringWithoutReloadPage('view', ScoringModeMap.product)
    }

    return (
      <div
        className={classNames({
          scoring_sub_header: true,
          scoring_sub_header_with_edit_scoring_enabled: showEditPanel,
          scoring_sticky_sub_header: device.isStickyHeader,
          scoring_project_mode_header: isProjectMode,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <div
          className={classNames({
            scoring_sub_header_image_placeholder: true,
            placeholder_image: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div
            className={classNames({
              scoring_sub_header_image: true,
            })}
          >
            {featuredImageUrl && featuredImageUrl !== '' && <img src={featuredImageUrl} alt="" />}
            {userRole.indexOf('reader') === -1 && <ContextualMenu />}
          </div>
        </div>
        <div
          className={classNames({
            scoring_sub_header_brand_logo: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          {brandProps.brandLogo && <img src={`${brandProps.brandLogo}`} alt="" />}
          {!brandProps.brandLogo && (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <circle
                cx="609"
                cy="222"
                r="14.5"
                fill="#D8D8D8"
                fillRule="evenodd"
                stroke="#000"
                strokeOpacity=".1"
                transform="translate(-594 -207)"
              />
            </svg>
          )}
        </div>
        <div
          className={classNames({
            scoring_sub_header_brand_data: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          <div
            className={classNames({
              scoring_sub_header_brand_data_inner_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <div
              className={classNames({
                scoring_sub_header_brand_name: true,
                scoring_sub_header_brand_name_sticky: device.isStickyHeader,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              {brandProps.brandName} {model}
            </div>
            <div
              className={classNames({
                scoring_sub_header_level: true,
                scoring_sub_header_commercial_name_grade: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              {commercialNameGrade}
            </div>
          </div>
        </div>
        {!device.isStickyHeader && (
          <div
            className={classNames({
              scoring_sub_header_calculation_summary: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {scoringTree.scoring.score_panel && (
              <ScorePanel
                scorePanel={scorePanelObj}
                size="normal"
                maxProductScore={environment.config.max_product_score}
                type={isProjectMode ? 'double' : process.env.scorePanelType}
                projectMode={isProjectMode}
              />
            )}
          </div>
        )}
        <div
          className={classNames({
            scoring_sub_header_buttons_area: true,
            isSafari: device.isSafari,
            isFirefox: device.isFirefox,
            desk: device.screenType === screenTypesMap.desk,
            ipadh: device.screenType === screenTypesMap.iPadH,
            ipadv: device.screenType === screenTypesMap.iPadV,
            iphonexr: device.screenType === screenTypesMap.iPhoneXR,
            iphonese: device.screenType === screenTypesMap.iPhoneSE,
          })}
        >
          {!device.isStickyHeader && (
            <div
              className={classNames({
                scoring_sub_header_buttons_container: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              {authentication.user.parsedPermissions.canUpdateProduct && (
                <div
                  className={classNames({
                    scoring_sub_header_edit_scoring_button_container: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    updateScrollDisabledStatus(true)
                    this.openEditPanel()
                  }}
                  onKeyPress={() => {
                    updateScrollDisabledStatus(true)
                    this.openEditPanel()
                  }}
                >
                  <EditScoringBtn />
                </div>
              )}
            </div>
          )}

          <div
            className={classNames({
              scoring_sub_header_counter_container: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <div
              className={classNames({
                scoring_sub_header_comments_count_countainer: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <CommentIcon />
              <span
                className={classNames({
                  scoring_sub_header_comments_count_value: true,
                })}
              >
                {normalizeProjectModeCount(scoringTree.scoring, null, 'comments')}
              </span>
            </div>
            <div
              className={classNames({
                scoring_sub_header_mediaCount_container: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <MediaIcon />
              <span
                className={classNames({
                  scoring_sub_header_mediaCount_value: true,
                })}
              >
                {normalizeProjectModeCount(scoringTree.scoring, null, 'media')}
              </span>
            </div>
          </div>

          <div
            className={classNames({
              scoring_sub_header_switch_mode_countainer: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {authentication.user.parsedPermissions.canViewProductScoring && (
              <div
                className={classNames({
                  scoring_sub_header_switcher: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <div
                  className={classNames({
                    scoring_sub_header_switcher_mode_scoring: true,
                    scoring_sub_header_switcher_selected_mode: scoringTree.scoringMode === ScoringModeMap.scoring,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleScoringModeSelected}
                  onKeyPress={handleScoringModeSelected}
                >
                  <div
                    className={classNames({
                      scoring_sub_header_switcher_label: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    <span
                      className={classNames({
                        scoring_sub_header_switcher_label_value: true,
                        isSafari: device.isSafari,
                        isFirefox: device.isFirefox,
                        desk: device.screenType === screenTypesMap.desk,
                        ipadh: device.screenType === screenTypesMap.iPadH,
                        ipadv: device.screenType === screenTypesMap.iPadV,
                        iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                        iphonese: device.screenType === screenTypesMap.iPhoneSE,
                      })}
                    >
                      {device.screenType === screenTypesMap.iPadV && device.isStickyHeader
                        ? `${scoringTree.completedPercentage}%`
                        : texts.scoring_mode_label}
                    </span>
                    {!isProjectMode && (
                      <span
                        className={classNames({
                          scoring_sub_header_scoring_completed_percentage: true,
                        })}
                      >
                        <ScoringPie percentage={scoringTree.completedPercentage} />
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={classNames({
                    scoring_sub_header_switcher_mode_product: true,
                    scoring_sub_header_switcher_selected_mode: scoringTree.scoringMode !== ScoringModeMap.scoring,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleProductModeSelected}
                  onKeyPress={handleProductModeSelected}
                >
                  <div
                    className={classNames({
                      scoring_sub_header_switcher_label: true,
                      isSafari: device.isSafari,
                      isFirefox: device.isFirefox,
                      desk: device.screenType === screenTypesMap.desk,
                      ipadh: device.screenType === screenTypesMap.iPadH,
                      ipadv: device.screenType === screenTypesMap.iPadV,
                      iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                      iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    })}
                  >
                    <span
                      className={classNames({
                        scoring_sub_header_switcher_label_value: true,
                        isSafari: device.isSafari,
                        isFirefox: device.isFirefox,
                        desk: device.screenType === screenTypesMap.desk,
                        ipadh: device.screenType === screenTypesMap.iPadH,
                        ipadv: device.screenType === screenTypesMap.iPadV,
                        iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                        iphonese: device.screenType === screenTypesMap.iPhoneSE,
                      })}
                    >
                      {device.screenType === screenTypesMap.iPadV && device.isStickyHeader
                        ? texts.product_mode_label_sticky
                        : texts.product_mode_label}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {authentication.user.parsedPermissions.canViewProductScoring && isProjectMode && <CalculationModeButtons />}
        </div>
        {showEditPanel && <EditScoring onClose={this.closeEditPanel} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    scorePanelObj: state.scoringTree.scoring.score_panel,
    sync: state.sync,
    publishingServices: state.publishingServices.list,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSubHeader)
