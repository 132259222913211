import React from 'react'
import { connect } from 'react-redux'

import { ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import {
  getProductDataByTemplateAndSegmentGroupedByCriteriaType,
  getProductDataByTemplateGroupedByCriteriaType,
} from '../../../../../../va-corejs-v3/actions/charts'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import {
  getProductName,
  calculatePopupChartHeight,
  getCriterionTypeShortLabels,
  getCriterionTypeList,
  doCalculationForAnalytics,
} from './common/utils.js'

const classNames = require('classnames')

const targetType = {
  simple: 0,
  list: 1,
}

export class CTypePerformanceChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      benchmarkExists: false,
      benchmarkSelected: false,
      productName: '',
      data: [],
      radarColors: {
        performance: '',
        reference: '',
      },
      settings: {
        chartContainerHeight: 230,
        colors: {
          exterior: {
            performance: '#695186',
            reference: '#000000',
            benchmark: '#00BBFF',
          },
          interior: {
            performance: '#245967',
            reference: '#000000',
            benchmark: '#00BBFF',
          },
          cargo: {
            performance: '#B94A29',
            reference: '#000000',
            benchmark: '#00BBFF',
          },
        },
      },
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  filterCriterionType = async (ctype, fNodes) => {
    const { scoringTree, root } = this.props
    const { nodeDefsObj, scoring } = scoringTree
    const { nodes } = scoring
    const filteredNodes = fNodes ? { ...fNodes } : { ...nodes }

    const idList = Object.keys(nodes)
    const nodeToExclude = []

    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def

      if (type === nodeDefinitionTypeMap.criterion) {
        const nodeType = def.criterion_template.slug
        if (nodeType !== ctype) {
          nodeToExclude.push(nodeId)
        }
      }
    }

    for (let i = 0; i !== nodeToExclude.length; i += 1) {
      const nodeId = nodeToExclude[i]
      delete filteredNodes[nodeId]
    }

    await doCalculationForAnalytics(nodeDefsObj, filteredNodes)

    const performance = filteredNodes[root]?.percentage
    return {
      criteriaType: ctype,
      performance,
    }
  }

  recursivelyFilterCriterionType = async (cTypeList, trees, fNodes) => {
    if (cTypeList.length === 0) return

    const ctype = cTypeList.pop()
    const filteredNodesTree = await this.filterCriterionType(ctype, fNodes)
    trees[ctype] = filteredNodesTree

    await this.recursivelyFilterCriterionType(cTypeList, trees, fNodes)
  }

  getChartColor = () => {
    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree

    const { settings } = this.state
    const { colors } = settings

    const currentNode = nodeDefsObj[root]
    const { type, name } = currentNode

    let familyName = name.en

    function getParent(n) {
      const parentId = n.parent_id
      const p = nodeDefsObj[parentId]
      return p
    }

    if (type === nodeDefinitionTypeMap.subfamily) {
      const family = getParent(currentNode)
      familyName = family.name.en
    }

    let radarColors = {}
    if (familyName.indexOf('Exterior') === 0) radarColors = colors.exterior
    else if (familyName.indexOf('Interior') === 0) radarColors = colors.interior
    else if (familyName.indexOf('Cargo') === 0) radarColors = colors.cargo

    this.setStateIfMounted({ radarColors })
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, environment } = this.props
    const { analytics, scoring } = scoringTree
    const { selectedReferenceType } = analytics
    const { props } = scoring

    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const shortLabels = await getCriterionTypeShortLabels(scoringTree, environment.defaultLang)

    const criterionTypeList = getCriterionTypeList()
    const cTypeListDuplicate = [...criterionTypeList]
    const treesByCType = {}
    await this.recursivelyFilterCriterionType(cTypeListDuplicate, treesByCType)

    const data = []
    for (let i = 0; i !== criterionTypeList.length; i += 1) {
      const cType = criterionTypeList[i]
      const { criteriaType, performance } = treesByCType[cType]
      const shortLabel = shortLabels[criteriaType]

      // VAV3-903 FIX
      // For how it was set up, shortLabels is the onlye variable actually aware of the criterion types present in the current template.
      // treesByCType is not reliable because the criterion types referenced are hardcoded.
      if (shortLabel !== undefined) {
        data.push({
          type: shortLabel,
          slug: cType,
          performance,
          benchmark: 20,
        })
      }
    }

    this.setStateIfMounted({ data })
    this.getChartColor()

    setTimeout(() => {
      this.performActionOnReferenceUpdated(selectedReferenceType)
    }, 200)

    await this.updateBenchmarkData()
  }

  redesignHitsBasedOnTarget = (reference, data, tType) => {
    for (let i = 0; i !== data.length; i += 1) {
      const hit = data[i]
      if (tType === targetType.simple) {
        hit.reference = reference
      } else if (tType === targetType.list) {
        const { slug } = hit
        hit.reference = reference[slug]
      }
    }
    this.setStateIfMounted({ loading: false, data })
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.scored) === 0) {
      const scoreValue = parseInt(referenceType.replace(`${analyticsRefsTypesMap.scored}_`, ''), 10)
      this.updateTargetDataByScoreValue(scoreValue)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setStateIfMounted({ loading: true })
    const { data } = this.state
    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score
    const percentageValue = (calculatedScoreValue * 100) / config.max_product_score
    this.redesignHitsBasedOnTarget(percentageValue, data, targetType.simple)
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setStateIfMounted({ loading: true })
    const { data } = this.state
    const { scoringTree, root, environment } = this.props
    const { config } = environment
    const response = await getProductDataByTemplateAndSegmentGroupedByCriteriaType(
      scoringTree.scoring.template_id,
      segment,
      root
    )
    const { globalAverageByCriterion } = response
    const tKeys = Object.keys(globalAverageByCriterion)
    const percentageValues = []
    for (let i = 0; i !== tKeys.length; i += 1) {
      const criSlug = tKeys[i]
      const criterion = globalAverageByCriterion[criSlug]
      const { average } = criterion
      const percentageValue = (average * 100) / config.max_score
      percentageValues[criSlug] = percentageValue
    }
    this.redesignHitsBasedOnTarget(percentageValues, data, targetType.list)
  }

  getPercentageValueArrayFromObject = bestValues => {
    this.setStateIfMounted({ loading: true })

    const { environment } = this.props
    const { config } = environment
    const tKeys = Object.keys(bestValues)
    const percentageValues = []
    for (let i = 0; i !== tKeys.length; i += 1) {
      const criSlug = tKeys[i]
      const value = bestValues[criSlug]
      const percentageValue = (value * 100) / config.max_score
      percentageValues[criSlug] = percentageValue
    }
    return percentageValues
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })
    const { data } = this.state
    const { scoringTree, root } = this.props

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const response = await getProductDataByTemplateAndSegmentGroupedByCriteriaType(
      scoringTree.scoring.template_id,
      mainSegment.value.slug,
      root
    )
    const { bestCriterionValues } = response
    const percentageValues = this.getPercentageValueArrayFromObject(bestCriterionValues)
    this.redesignHitsBasedOnTarget(percentageValues, data, targetType.list)
  }

  updateTargetValueByBestOfAll = async () => {
    this.setStateIfMounted({ loading: true })
    const { data } = this.state
    const { scoringTree, root } = this.props

    const response = await getProductDataByTemplateGroupedByCriteriaType(scoringTree.scoring.template_id, root)
    const { bestCriterionValues } = response
    const percentageValues = this.getPercentageValueArrayFromObject(bestCriterionValues)
    this.redesignHitsBasedOnTarget(percentageValues, data, targetType.list)
  }

  updateBenchmarkData = async () => {
    const { scoringTree } = this.props
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { data } = this.state

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })
      const criterionTypeList = getCriterionTypeList()
      const cTypeListDuplicate = [...criterionTypeList]
      const treesByCType = {}

      await this.recursivelyFilterCriterionType(cTypeListDuplicate, treesByCType, benchmarkNodes)

      const updateData = data.map((currentData, index) => {
        const cType = criterionTypeList[index]
        const { performance } = treesByCType[cType]
        currentData.benchmark = performance
        return currentData
      })

      this.setState({
        loading: false,
        data: updateData,
        benchmarkExists: true,
        benchmarkSelected: true,
      })
    } else if (selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: false,
      })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, root } = this.props

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    const prevRoot = prevProps.root

    const reinitComponent = root !== prevRoot
    const updateUi = currRefType && currRefType !== prevRefType

    if (reinitComponent) {
      await this.initComponent()
    } else if (updateUi) {
      await this.performActionOnReferenceUpdated(currRefType)
      await this.updateBenchmarkData()
    }

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes

    if (benchmarkNodes !== prevBenchmarkNodes) {
      await this.updateBenchmarkData()
    }
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      benchmarkExists,
      benchmarkSelected,
      productName,
      data,
      radarColors,
      settings,
    } = this.state
    const { chartContainerHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const renderLegend = props => {
      const { payload } = props
      return (
        <div className={classNames({ custom_legends_container: true })}>
          {payload.map((entry, index) => (
            <div key={`legend_${index}`} className={classNames({ custom_legend_item: true })}>
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames({
                  custom_legend_marker: true,
                  small_dot_marker: true,
                })}
              />
              <div className={classNames({ custom_legend_value: true })}>{entry.value}</div>
            </div>
          ))}
        </div>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer width={width} height={height}>
          <RadarChart data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey="type" />
            <PolarRadiusAxis angle={73} domain={[0, 100]} />
            <Radar
              dataKey="performance"
              stroke={radarColors.performance}
              strokeWidth="2.5"
              fill="transparent"
              fillOpacity={1}
            />

            {!benchmarkSelected && (
              <Radar
                dataKey="reference"
                stroke={radarColors.reference}
                strokeWidth="1.5"
                fill="transparent"
                fillOpacity={1}
              />
            )}

            {benchmarkExists && (
              <Radar
                dataKey="benchmark"
                stroke={radarColors.benchmark}
                strokeWidth="1.5"
                fill="transparent"
                fillOpacity={1}
              />
            )}

            <Legend content={renderLegend} />
          </RadarChart>
        </ResponsiveContainer>
      )
    }
    return (
      <>
        {(loading === true || Object.keys(data).length === 0) && (
          <div
            className={classNames({
              chart_loading_container: true,
              ctype_performance_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              ctype_performance_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', chartContainerHeight)}</div>
          </div>
        )}
        {loading === false && Object.keys(data).length === 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              ctype_performance_outer_container: true,
            })}
          >
            <div className={classNames({ chart_inner_container: true })} style={{ height: chartContainerHeight }}>
              <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
            </div>
          </div>
        )}
        {loading === false && Object.keys(data).length > 0 && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={productName}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CTypePerformanceChart)
