import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../store/actions'

import PrevIcon from '../../../assets/svg/prev-icon-desk.svg'
import NextIcon from '../../../assets/svg/next-icon-desk.svg'
import nodeDefinitionTypeMap from '../helper/nodeDefinitionTypeMap'
import { scrollLeftPanel, scrollToItem } from './commons'
import { isProjectModeEnabled } from './projectMode/utils'
import itemViewsMap from '../helper/itemViewsMap'

const classNames = require('classnames')

export class ItemsNavigator extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rootNodes: [],
    }
  }

  componentDidMount = async () => {
    const { scoringTree, updateActiveItemIdInReduxOnly } = this.props

    if (!scoringTree.scoring.activeItemId) {
      const currItemId = scoringTree.itemsFather.children_ids[0]
      await updateActiveItemIdInReduxOnly(scoringTree.scoring.id, currItemId)
    }

    const { nodeDefsObj } = scoringTree
    const keys = Object.keys(nodeDefsObj)

    const rootNodes = []

    for (let i = 0; i !== keys.length; i += 1) {
      const prop = nodeDefsObj[keys[i]]
      if (prop.parent_id === null) {
        rootNodes.push(prop.id)
      }
    }

    this.setState({ rootNodes })
  }

  render() {
    const {
      texts,
      device,
      scoringTree,
      environment,
      authentication,
      updateScoringTreeItemsFather,
      loadCommentsFromStorageByItemIdAndUpdateRedux,
      initializeNewCommentsListForRedux,
      initNewCommentsForecast,
      updateActiveItem,
      updateMedia,
      applyExpandedStatusOnItemsAndCriteria,
    } = this.props

    const { rootNodes } = this.state

    function getFilteredItemIdsList(itemFather) {
      const list = []
      itemFather.children_ids.forEach(id => {
        list.push(scoringTree.scoring.nodes[id])
      })

      const listIds = []
      list.forEach(node => {
        const nodeDefinition = scoringTree.nodeDefsObj[node.node_definition_id]
        const children = _.map(nodeDefinition.children_ids, id => {
          return scoringTree.scoring.nodes[id]
        })

        const itemView = scoringTree.itemView.toString()
        const unscored = node.percentageScored < 100
        const toReview = node.to_review || _.some(children, child => child.to_review === true)

        if (itemView === itemViewsMap.unscored.toString() && !unscored) {
          return
        }

        if (itemView === itemViewsMap.toReview.toString() && toReview === false) {
          return
        }

        listIds.push(node.node_definition_id)
      })

      return listIds
    }

    function firstItemInList(element) {
      const ids = getFilteredItemIdsList(element)

      if (ids.length > 0) {
        return ids[ids.length - 1]
      }

      return _.last(element.children_ids)
    }

    function checkIfIsLast(list, elementId) {
      const pos = list.indexOf(elementId)
      const isLast = pos === list.length - 1
      return {
        isLast,
        pos,
      }
    }

    function checkIfIsFirst(list, elementId) {
      const pos = list.indexOf(elementId)
      const isFirst = pos === 0
      return {
        isFirst,
        pos,
      }
    }

    async function performActionsAfterPrevNextItemClicked(props, itemView) {
      const { itemsFather } = scoringTree
      const { scoring } = scoringTree
      const itemsFatherHasBeenChanged = itemsFather.id !== props.itemsFather.id

      await updateScoringTreeItemsFather(scoringTree.scoring, props.itemsFather)
      await updateMedia(scoringTree.scoring.id, props.itemsFather.id)

      const currentLocale = _.find(environment.locales, locale => locale.code === environment.lang)

      await loadCommentsFromStorageByItemIdAndUpdateRedux(scoringTree.scoring.id, props.itemsFather.id)
      await initializeNewCommentsListForRedux(
        scoringTree.scoring.id,
        scoringTree.nodeDefsObj,
        props.itemsFather.id,
        currentLocale,
        authentication.user
      )

      if (isProjectModeEnabled(scoringTree.scoring)) {
        await initNewCommentsForecast(
          scoringTree.scoring.id,
          scoringTree.nodeDefsObj,
          props.itemsFather.id,
          currentLocale,
          authentication.user
        )
      }

      await applyExpandedStatusOnItemsAndCriteria(scoring, itemView, props.itemsFather)

      await updateActiveItem(
        props.scoringId,
        props.subFamily,
        props.family,
        props.perimeter,
        props.itemsFather,
        props.active,
        itemView
      )

      scrollToItem(props.active, device.isStickyHeader)
      if (itemsFatherHasBeenChanged) {
        scrollLeftPanel(props.subFamily)
      }
    }

    function getNextPropsForLevel(level) {
      const { id, activeItemId } = scoringTree.scoring
      const { itemsFather, nodeDefsObj } = scoringTree

      const family = nodeDefsObj[itemsFather.parent_id]
      const perimeter = nodeDefsObj[family.parent_id]

      const itemsIds = getFilteredItemIdsList(itemsFather)

      if (level === 0) {
        const itemOrCriteriaNode = nodeDefsObj[activeItemId]
        const itemId =
          itemOrCriteriaNode.type === nodeDefinitionTypeMap.criterion ? itemOrCriteriaNode.parent_id : activeItemId

        const currEl = checkIfIsLast(itemsIds, itemId)

        if (!currEl.isLast) {
          return {
            scoringId: id,
            subFamily: itemsFather.id,
            family: family.id,
            perimeter: family.parent_id,
            itemsFather,
            active: itemsIds[currEl.pos + 1],
          }
        }
        return false
      }

      if (level === 1) {
        const currEl = checkIfIsLast(family.children_ids, itemsFather.id)

        if (!currEl.isLast) {
          const nextSubFamilyId = family.children_ids[currEl.pos + 1]
          const nextSubFamily = nodeDefsObj[nextSubFamilyId]

          return {
            scoringId: id,
            subFamily: nextSubFamilyId,
            family: family.id,
            perimeter: family.parent_id,
            itemsFather: nextSubFamily,
            active: nextSubFamily.children_ids[0],
          }
        }
        return false
      }

      if (level === 2) {
        const currEl = checkIfIsLast(perimeter.children_ids, family.id)

        if (!currEl.isLast) {
          const nextFamilyId = perimeter.children_ids[currEl.pos + 1]
          const nextFamily = nodeDefsObj[nextFamilyId]

          const nextSubFamilyId = nextFamily.children_ids[0]
          const nextSubFamily = nodeDefsObj[nextSubFamilyId]

          return {
            scoringId: id,
            subFamily: nextSubFamilyId,
            family: nextFamilyId,
            perimeter: nextFamily.parent_id,
            itemsFather: nextSubFamily,
            active: nextSubFamily.children_ids[0],
          }
        }
        return false
      }

      if (level === 3) {
        const currEl = checkIfIsLast(rootNodes, perimeter.id)

        if (!currEl.isLast) {
          const nextPerimId = rootNodes[currEl.pos + 1]
          const nextPerim = nodeDefsObj[nextPerimId]

          const nextFamilyId = nextPerim.children_ids[0]
          const nextFamily = nodeDefsObj[nextFamilyId]

          const nextSubFamilyId = nextFamily.children_ids[0]
          const nextSubFamily = nodeDefsObj[nextSubFamilyId]

          return {
            scoringId: id,
            subFamily: nextSubFamilyId,
            family: nextFamilyId,
            perimeter: nextPerimId,
            itemsFather: nextSubFamily,
            active: nextSubFamily.children_ids[0],
          }
        }
        return false
      }

      return true
    }

    function getPropsForNextItem() {
      let level = 0
      let props = false
      while (!props) {
        props = getNextPropsForLevel(level)
        if (props === false) level += 1
      }
      return { props, level }
    }

    const handleNextItemClicked = _.debounce(async () => {
      const { props } = getPropsForNextItem()
      if (props === true) {
        console.log('end of the tree')
      } else {
        await performActionsAfterPrevNextItemClicked(props, scoringTree.itemView)
      }
    }, 400)

    function getPrevPropsForLevel(level) {
      const { id, activeItemId } = scoringTree.scoring
      const { itemsFather, nodeDefsObj } = scoringTree

      const family = nodeDefsObj[itemsFather.parent_id]
      const perimeter = nodeDefsObj[family.parent_id]

      const itemsIds = getFilteredItemIdsList(itemsFather)

      if (level === 0) {
        const itemOrCriteriaNode = nodeDefsObj[activeItemId]
        const itemId =
          itemOrCriteriaNode.type === nodeDefinitionTypeMap.criterion ? itemOrCriteriaNode.parent_id : activeItemId

        const currEl = checkIfIsFirst(itemsIds, itemId)

        if (!currEl.isFirst && currEl.pos > -1) {
          return {
            scoringId: id,
            subFamily: itemsFather.id,
            family: family.id,
            perimeter: family.parent_id,
            itemsFather,
            active: itemsIds[currEl.pos - 1],
          }
        }

        return false
      }

      if (level === 1) {
        const currEl = checkIfIsFirst(family.children_ids, itemsFather.id)

        if (!currEl.isFirst && currEl.pos > -1) {
          const prevSubFamilyId = family.children_ids[currEl.pos - 1]
          const prevSubFamily = nodeDefsObj[prevSubFamilyId]

          return {
            scoringId: id,
            subFamily: prevSubFamilyId,
            family: family.id,
            perimeter: family.parent_id,
            itemsFather: prevSubFamily,
            active: firstItemInList(prevSubFamily),
          }
        }
        return false
      }

      if (level === 2) {
        const currEl = checkIfIsFirst(perimeter.children_ids, family.id)

        if (!currEl.isFirst && currEl.pos > -1) {
          const prevFamilyId = perimeter.children_ids[currEl.pos - 1]
          const prevFamily = nodeDefsObj[prevFamilyId]

          const prevSubFamilyId = firstItemInList(prevFamily)
          const prevSubFamily = nodeDefsObj[prevSubFamilyId]

          return {
            scoringId: id,
            subFamily: prevSubFamilyId,
            family: prevFamilyId,
            perimeter: prevFamily.parent_id,
            itemsFather: prevSubFamily,
            active: firstItemInList(prevSubFamily),
          }
        }
        return false
      }

      if (level === 3) {
        const currEl = checkIfIsFirst(rootNodes, perimeter.id)

        if (!currEl.isFirst && currEl.pos > -1) {
          const prevPerimId = rootNodes[currEl.pos - 1]
          const prevPerim = nodeDefsObj[prevPerimId]

          const prevFamilyId = firstItemInList(prevPerim)
          const prevFamily = nodeDefsObj[prevFamilyId]

          const prevSubFamilyId = firstItemInList(prevFamily)
          const prevSubFamily = nodeDefsObj[prevSubFamilyId]

          return {
            scoringId: id,
            subFamily: prevSubFamilyId,
            family: prevFamilyId,
            perimeter: prevPerimId,
            itemsFather: prevSubFamily,
            active: firstItemInList(prevSubFamily),
          }
        }
        return false
      }

      return true
    }

    function getPropsForPrevItem() {
      let level = 0
      let props = false
      while (!props) {
        props = getPrevPropsForLevel(level)
        if (props === false) level += 1
      }
      return { props, level }
    }

    const handlePrevItemClicked = _.debounce(async () => {
      const { props } = getPropsForPrevItem()

      if (props === true) {
        console.log('this is the root of the tree')
      } else {
        await performActionsAfterPrevNextItemClicked(props, scoringTree.itemView)
      }
    }, 400)

    return (
      <div
        className={classNames({
          items_navigator_container: true,
        })}
      >
        <div
          className={classNames({
            items_navigator_btn: true,
            items_navigator_prev_btn: true,
            theme_background_color: true,
            theme_box_shadow_button: true,
          })}
          role="button"
          tabIndex={0}
          onClick={handlePrevItemClicked}
          onKeyPress={handlePrevItemClicked}
        >
          <span>{texts.items_prev}</span>
          <PrevIcon />
        </div>
        <div
          className={classNames({
            items_navigator_btn: true,
            items_navigator_next_btn: true,
            theme_background_color: true,
            theme_box_shadow_button: true,
          })}
          role="button"
          tabIndex={0}
          onClick={handleNextItemClicked}
          onKeyPress={handleNextItemClicked}
        >
          <span>{texts.items_next}</span>
          <NextIcon />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    authentication: state.authentication,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(ItemsNavigator)
