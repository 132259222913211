import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import msgBoxMessageMap from '../../../../../va-corejs-v3/utils/msgBoxMessagesMap'
import ClipboardItem from './_parts/clipboardItem'
import ReportItem from './_parts/reportItem'

const classNames = require('classnames')

export class Documents extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fileId: null,
    }
  }

  handleAttachmentButtonClicked = () => {
    document.getElementById('attachments').click()
  }

  handleRemoveAttachment = attachment => {
    const { updateMsgboxMessage, scoringTree } = this.props
    updateMsgboxMessage(msgBoxMessageMap.confirmDeleteProductAttachment, {
      scoringId: scoringTree.scoring.id,
      par1: attachment.file_name,
      item: attachment,
      scoringTree,
    })
  }

  handleAttachmentInputChange = () => {
    const { uploadClipboarFilesOnServer, scoringTree } = this.props
    const inputElementDOM = document.getElementById('attachments')
    const { files } = inputElementDOM
    uploadClipboarFilesOnServer(scoringTree, files)
  }

  handleReportAttachmentButtonClicked = mediaId => {
    if (mediaId) this.setState({ fileId: mediaId })
    else this.setState({ fileId: null })

    document.getElementById('attachments_report').click()
  }

  handleReportAttachmentInputChange = () => {
    const { fileId } = this.state

    const { uploadClipboarFilesOnServer, scoringTree } = this.props
    const { media } = scoringTree.scoring
    const inputElementDOM = document.getElementById('attachments_report')

    const { files } = inputElementDOM

    // update id only first file because input has no multiplefiles
    // if id is added media will overwrite the previews media id file

    if (fileId && files && files[0]) {
      files[0].id = fileId

      const mediaToUploadIndex = media.findIndex(x => x.id === fileId)
      if (mediaToUploadIndex > -1) {
        const { order } = media[mediaToUploadIndex]
        files[0].order = order
      }
    }

    uploadClipboarFilesOnServer(scoringTree, files, 'auto_reports')
  }

  render() {
    const { texts, scoringTree, device } = this.props

    const clipboardList =
      scoringTree.scoring.media && scoringTree.scoring.media.length > 0
        ? scoringTree.scoring.media.filter(singleMedia => singleMedia.collection === 'clipboard')
        : []

    const reportList =
      scoringTree.scoring.media && scoringTree.scoring.media.length > 0
        ? scoringTree.scoring.media
            .filter(singleMedia => singleMedia.collection === 'auto_reports')
            .sort((a, b) => {
              if (a.order < b.order) return -1
              if (a.order > b.order) return 1
              return 0
            })
        : []

    return (
      <div
        className={classNames({
          attachments_mode_container: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        {JSON.parse(process.env.reportEnabled) === true && reportList.length > 0 && (
          <div className="product_mode_full_size">
            <div className="attachments_list_title">{texts.report_vehicles}</div>

            <input
              type="file"
              id="attachments_report"
              name="attachments_report"
              style={{ display: 'none' }}
              onChange={this.handleReportAttachmentInputChange}
            />
            <div className="product_attachments">
              <div className="product_attachments_list product_attachments_list_reports">
                {reportList && reportList.length > 0 && (
                  <>
                    {reportList.map(attachment => (
                      <ReportItem
                        item={attachment}
                        onUpload={this.handleReportAttachmentButtonClicked}
                        key={attachment.id}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="product_mode_full_size">
          <div className="attachments_list_title clipboard">
            <div>{texts.clipboard_documents_title}</div>
            &nbsp;
            <span className="help_text">{texts.clipboard_attach_document_help_text}</span>
            <div
              className="sm_btn theme_primary_btn btn_200 theme_background_color attach_document_button"
              role="button"
              tabIndex={0}
              onClick={this.handleAttachmentButtonClicked}
              onKeyPress={this.handleAttachmentButtonClicked}
            >
              {texts.attach_document}
            </div>
            <input
              type="file"
              multiple
              id="attachments"
              name="attachments"
              style={{ display: 'none' }}
              onChange={this.handleAttachmentInputChange}
            />
          </div>

          <div className="product_attachments">
            <div className="product_attachments_list product_attachments_list_clipboard">
              {clipboardList && clipboardList.length > 0 && (
                <>
                  {clipboardList.map(attachment => (
                    <ClipboardItem item={attachment} key={attachment.id} />
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(Documents)
