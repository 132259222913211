import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'

import BreakdownChart from '../charts/breakdownChart'
import AreaPerformanceChart from '../charts/areaPerformanceChart'
import ScoreDistributionChart from '../charts/scoreDistributionChart'
import ScoreAreaDistributionChart from '../charts/scoreAreaDistributionChart'
import ScoreCriteriaTypeDistributionChart from '../charts/scoreCriteriaTypeDistributionChart'
import FamilyPerformanceChart from '../charts/familyPerformanceChart'
import CTypePerformanceChart from '../charts/cTypePerformanceChart'

import BreadCrumbDividerIcon from '../../../../../assets/svg/bredcrum-icon.svg'
import screenTypesMap from '../../../../../layout/_parts/screenTypesMap'

const classNames = require('classnames')

const scoreModeView = {
  areaDistribution: 0,
  criteriaTypeDistribution: 1,
  bestWorst: 2,
}

export class FamilyView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedScoreMode: scoreModeView.areaDistribution,
    }
  }

  componentDidMount = async () => {
    const { scoringTree, environment, updateAnalyticsBreadcrumb } = this.props
    const { analytics, nodeDefsObj } = scoringTree
    const { lastClickedNode } = analytics

    const breadcrumb = this.makeBreadcrumb(lastClickedNode.id, nodeDefsObj, environment.defaultLang)

    await updateAnalyticsBreadcrumb(scoringTree.scoring.id, breadcrumb)
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, environment, updateAnalyticsBreadcrumb } = this.props
    const { analytics, nodeDefsObj } = scoringTree
    const { lastClickedNode } = analytics

    const { scoringTree: prevScoringTree } = prevProps
    const { lastClickedNode: prevLastClickedNode } = prevScoringTree.analytics

    if (prevLastClickedNode.id !== lastClickedNode.id) {
      const breadcrumb = this.makeBreadcrumb(lastClickedNode.id, nodeDefsObj, environment.defaultLang)
      await updateAnalyticsBreadcrumb(scoringTree.scoring.id, breadcrumb)
    }
  }

  makeBreadcrumb = (nodeId, nodeDefsObj, defaultLang) => {
    const nDef = nodeDefsObj[nodeId]
    const perimeterDef = nodeDefsObj[nDef.parent_id]
    const familyName = nDef.name[defaultLang]
    const perimeterName = perimeterDef.name[defaultLang]

    return [perimeterName, familyName]
  }

  handleScoreModeViewChanged = e => {
    const selectedScoreMode = parseInt(e.target.options[e.target.selectedIndex].value, 10)
    this.setState({ selectedScoreMode })
  }

  render() {
    const { selectedScoreMode } = this.state
    const { scoringTree, texts, environment, device } = this.props
    const { analytics, nodeDefsObj } = scoringTree
    const { lastClickedNode } = analytics

    const nDef = nodeDefsObj[lastClickedNode.id]
    const perimeterDef = nodeDefsObj[nDef.parent_id]
    const familyName = nDef.name[environment.defaultLang]
    const perimeterName = perimeterDef.name[environment.defaultLang]

    return (
      <>
        {lastClickedNode && (
          <div className={classNames({ family_view_container: true })}>
            <div className={classNames({ chart_breadcrumb_container: true })}>
              <span>{perimeterName}</span>
              <BreadCrumbDividerIcon />
              <span>{familyName}</span>
            </div>
            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>{texts.score_breakdown}</div>
              <BreakdownChart root={lastClickedNode.id} />
            </div>

            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>{texts.percentage_performance_per_area}</div>
              <AreaPerformanceChart root={lastClickedNode.id} />
            </div>

            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>{texts.score_distribution}</div>
              <ScoreDistributionChart root={lastClickedNode.id} family={null} />
            </div>

            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>
                <select
                  className={classNames({
                    va_select: true,
                    score_chart_selector: true,
                  })}
                  onChange={e => {
                    this.handleScoreModeViewChanged(e)
                  }}
                  value={selectedScoreMode}
                >
                  <option value={scoreModeView.areaDistribution}>{texts.score_distribution_per_area}</option>
                  <option value={scoreModeView.criteriaTypeDistribution}>
                    {texts.score_distribution_per_criteria_type}
                  </option>
                </select>
              </div>
              {selectedScoreMode === scoreModeView.areaDistribution && (
                <ScoreAreaDistributionChart root={lastClickedNode.id} />
              )}
              {selectedScoreMode === scoreModeView.criteriaTypeDistribution && (
                <ScoreCriteriaTypeDistributionChart root={lastClickedNode.id} />
              )}
            </div>

            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>
                {texts.percentage_performance_per_criteria_family}
              </div>
              <FamilyPerformanceChart root={lastClickedNode.id} />
            </div>

            <div
              className={classNames({
                one_third_width_chart_container: device.screenType === screenTypesMap.desk,
                half_width_chart_container: device.screenType === screenTypesMap.iPadH,
              })}
            >
              <div className={classNames({ chart_label: true })}>{texts.percentage_performance_per_criteria_type}</div>
              <CTypePerformanceChart root={lastClickedNode.id} />
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    scoringTree: state.scoringTree,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(FamilyView)
