import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import AddPhoto from '../../../../assets/svg/add-photo.svg'
import AddVideo from '../../../../assets/svg/add-video.svg'
import AddMedia from '../../../../assets/svg/add-media.svg'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'

const classNames = require('classnames')

const mediaTypes = { image: 'camera', video: 'camcoder', media: 'file' }

export class MediaButton extends React.Component {
  render() {
    const {
      device,
      mediaType,
      nodeDefId,
      scoringTree,
      updateMediaFile,
      updatePlaceholderMediaCount,
      processSyncQueue,
      collection,
    } = this.props

    const mediaCollection = collection || mediaCollectionsMap.node_pictures
    const inputCollection = mediaCollection === mediaCollectionsMap.node_pictures ? '' : `${mediaCollection}-`
    const inputId = `${mediaType}-input-${inputCollection}${nodeDefId}`

    const extensions = {
      image: 'image/jpeg,image/png',
      video: 'video/mp4,video/quicktime',
      media: device.isOnline ? 'image/jpeg,image/png,video/mp4,video/quicktime' : 'image/jpeg,image/png',
    }

    const inputAccept = `${extensions[mediaType]}`
    const inputCapture = `${mediaTypes[mediaType]}`

    function handleClick(e) {
      e.stopPropagation()
      document.getElementById(inputId).click()
    }

    async function handleInputChange() {
      const inputElementDOM = document.getElementById(inputId)

      const mediaFiles = inputElementDOM.files

      updatePlaceholderMediaCount(mediaFiles.length)
      await updateMediaFile(
        scoringTree.scoring.id,
        mediaFiles,
        mediaType,
        nodeDefId,
        mediaCollection,
        scoringTree.itemsFather.id
      )
      updatePlaceholderMediaCount(0)

      inputElementDOM.value = ''

      if (device.isOnline) {
        processSyncQueue()
      }
    }

    return (
      <>
        {((!device.isOnline && mediaType !== 'video') || device.isOnline) && (
          <span>
            <div
              className={classNames({
                sm_btn: true,
                secondary_btn: true,
                sctree_rp_item_node_media_action_btn: true,
                sctree_rp_item_node_take_photo_btn: mediaType === 'image',
                sctree_rp_item_node_take_video_btn: mediaType === 'video',
                sctree_rp_item_node_take_media_btn: mediaType === 'media',
                theme_svg: true,
              })}
              role="button"
              tabIndex={0}
              onClick={handleClick}
              onKeyPress={handleClick}
            >
              {mediaType === 'image' && <AddPhoto />}
              {mediaType === 'video' && <AddVideo />}
              {mediaType === 'media' && <AddMedia />}
            </div>
            {inputCapture === 'file' && (
              <input
                type="file"
                accept={inputAccept}
                id={inputId}
                name={inputId}
                style={{ display: 'none' }}
                multiple
                onChange={handleInputChange}
              />
            )}
            {inputCapture !== 'file' && (
              <input
                type="file"
                accept={inputAccept}
                capture={inputCapture}
                id={inputId}
                name={inputId}
                style={{ display: 'none' }}
                onChange={handleInputChange}
              />
            )}
          </span>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    sync: state.sync,
  }
}

export default connect(mapStateToProps, actionCreators)(MediaButton)
