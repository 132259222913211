import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

export class PlayButton extends React.Component {
  render() {
    const { idx, openCloseMediaGallery, updateScrollDisabledStatus } = this.props

    return (
      <div
        className="play_button"
        role="button"
        tabIndex={0}
        onClick={e => {
          updateScrollDisabledStatus(true)
          openCloseMediaGallery(e, true, idx)
        }}
        onKeyPress={e => {
          updateScrollDisabledStatus(true)
          openCloseMediaGallery(e, true, idx)
        }}
      >
        <img src="/play-button.jpg" alt="Play video" />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    appVersion: state.appVersion,
    device: state.device,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(PlayButton)
