import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ScoringPicture extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pictureUrl: null,
    }
  }

  componentDidMount = async () => {
    await this.setPictureUrl()
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, collection } = this.props

    if (prevProps.scoringTree !== scoringTree || prevProps.collection !== collection) {
      await this.setPictureUrl()
    }
  }

  setPictureUrl = async () => {
    const { scoringTree, collection } = this.props
    const { scoring } = scoringTree
    const { media } = scoring

    const picture = _.find(media, item => item.collection === collection)

    if (picture) {
      const pictureUrl =
        picture.file_thumb && !picture.file_thumb.startsWith('data:')
          ? await getMediaBlobUrl(picture.file_thumb)
          : picture.file_thumb
      this.setState({ pictureUrl })
    }
  }

  render() {
    const { pictureUrl } = this.state

    if (!pictureUrl) {
      return null
    }

    return (
      <div
        className={classNames({
          product_mode_product_picture: true,
        })}
      >
        <img src={pictureUrl} alt="" />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPicture)
