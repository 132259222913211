import React from 'react'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import * as actionCreators from '../../../../store/actions'
import { updateParentsCheckboxSelection } from '../utils'
import { fetchAutoReportsNormalized, initReportFromServer, updateAutoReportNormalized } from '../../../../store/actions'

export class ReportActions extends React.Component {
  state = {
    active: false,
  }

  /*-----------------------------------------------------*/
  //  Init Active State
  /*-----------------------------------------------------*/

  componentDidMount = async () => {
    const { authentication, scoringTree, itemId } = this.props

    const reportNode = await this.getReportNodeFromServer(itemId, authentication.user.id, scoringTree)
    if (reportNode && reportNode.selected && reportNode.selected === 'enabled') this.setState({ active: true })
  }

  /*-----------------------------------------------------*/
  // Get Node from Report
  /*-----------------------------------------------------*/

  getReportNodeFromServer = async (nodeDefId, userId, scoringTree) => {
    const reportResult = await fetchAutoReportsNormalized(userId, scoringTree.scoring.id)

    if (reportResult) {
      if (reportResult.nodes && reportResult.nodes[nodeDefId]) return reportResult.nodes[nodeDefId]
    }

    return false
  }

  /*-----------------------------------------------------*/
  // Toggle Selection Node to Report
  /*-----------------------------------------------------*/

  toggleSelect = async event => {
    event.stopPropagation()

    const { active } = this.state

    await this.toggleReportSelectedNodeToServer(active)
  }

  /*-----------------------------------------------------*/
  // Select Node to Report
  /*-----------------------------------------------------*/

  toggleReportSelectedNodeToServer = async active => {
    const { authentication, scoringTree, itemId } = this.props

    const userId = authentication.user.id

    const selection = active ? 'disabled' : 'enabled'

    let reportObj = {}

    reportObj = await initReportFromServer(scoringTree, userId)

    // Add Selected Node in Report

    if (reportObj.nodes[itemId]) {
      reportObj.nodes[itemId].selected = selection
      // update all parent checknox select state
      reportObj.nodes = updateParentsCheckboxSelection(reportObj.nodes, scoringTree.nodeDefsObj)

      updateAutoReportNormalized(reportObj.id, reportObj)
    }

    this.setState({ active: !active })
  }

  /*-----------------------------------------------------*/
  // Rendering
  /*-----------------------------------------------------*/

  render() {
    const { texts, itemId, scoringTree } = this.props
    const { active } = this.state
    return (
      <>
        {JSON.parse(process.env.reportEnabled) === true && scoringTree.scoring.nodes[itemId].is_enabled && (
          <div className="report-button report-button-add">
            <Button
              variant="contained"
              onClick={e => this.toggleSelect(e)}
              color={active ? 'primary' : 'default'}
              className="round-btn"
            >
              {texts.report_add}
            </Button>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(ReportActions)
