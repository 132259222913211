import React from 'react'
import { connect } from 'react-redux'
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import LoadingBar from '../../../../loading_bar/index.js'

import * as actionCreators from '../../../../../store/actions'
import {
  getBestOfSegment,
  getBestOfAll,
  getGlobalAverageOfSegment,
} from '../../../../../../va-corejs-v3/actions/charts'
import { searchProductsByTemplateAndSegmentOrProductId } from '../../../../../../va-corejs-v3/actions/elastic_search'

import ChartPopup from '../_parts/chartPopup'
import {
  getProductName,
  getFullDotShape,
  getEmptyDotShape,
  getBigTargetShape,
  getSmallTargetShape,
  getRhombusFullShape,
  getRhombusEmptyShape,
  calculatePopupChartHeight,
} from './common/utils'

import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'
import scorePanelNestedPropsMap from '../../../helper/scorePanelNestedPropsMap'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const classNames = require('classnames')

export class MappingOverviewChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      productName: '',
      // hits: [],
      cpInitialData: [],
      cpDetailedData: [],
      initialData: [],
      detailedData: [],
      benchmarkInitialData: [],
      benchmarkDetailedData: [],
      targetInitialData: [],
      targetDetailedData: [],
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })
    await new Promise(r => setTimeout(r, 300)) // wait for redux to reload props

    const { scoringTree } = this.props
    const { props } = scoringTree.scoring
    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const parsedHits = await this.loadAndParseHitsFromES()
    if (parsedHits) {
      const { selectedReferenceType } = scoringTree.analytics

      this.setStateIfMounted(parsedHits)
      this.performActionOnReferenceUpdated(selectedReferenceType)
    }
  }

  loadAndParseHitsFromES = async () => {
    const { scoringTree } = this.props
    const { benchmarkId } = scoringTree.analytics

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const result = await searchProductsByTemplateAndSegmentOrProductId(
      scoringTree.scoring.template_id,
      mainSegment.value.slug,
      benchmarkId
    )
    if (result.status === 200) {
      const { hits } = result.data.hits
      const parsedHits = await this.parseHits(hits)
      return parsedHits
    }

    return false
  }

  parseHits = async hits => {
    const { scoringTree } = this.props
    const { analytics, scoring } = scoringTree
    const currentProductId = scoring.id
    const { benchmarkId } = analytics

    const cpInitialData = []
    const cpDetailedData = []

    const initialData = []
    const detailedData = []

    const benchmarkInitialData = []
    const benchmarkDetailedData = []

    for (let i = 0; i !== hits.length; i += 1) {
      const { _source } = hits[i]
      const { id, props } = _source
      const scorePanel = _source.score_panel

      const name = `${props.brand.value} ${props.model.value}`

      const exteriorInitial = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.initial].value
      const exteriorDetailed = scorePanel[scorePanelPropsMap.exterior].values[scorePanelNestedPropsMap.detailed].value
      const interiorInitial = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.initial].value
      const interiorDetailed = scorePanel[scorePanelPropsMap.interior].values[scorePanelNestedPropsMap.detailed].value

      const initialPoint = {
        name,
        perimeter: 'Initial',
        x: interiorInitial || 0,
        y: exteriorInitial || 0,
      }
      const detailedPoint = {
        name,
        perimeter: 'Detailed',
        x: interiorDetailed || 0,
        y: exteriorDetailed || 0,
      }

      switch (id) {
        case currentProductId:
          cpInitialData.push(initialPoint)
          cpDetailedData.push(detailedPoint)
          break
        case benchmarkId:
          benchmarkInitialData.push(initialPoint)
          benchmarkDetailedData.push(detailedPoint)
          break
        default:
          initialData.push(initialPoint)
          detailedData.push(detailedPoint)
      }
    }

    const parsedHits = {
      hits,
      cpInitialData,
      cpDetailedData,
      initialData,
      detailedData,
      benchmarkInitialData,
      benchmarkDetailedData,
    }

    return parsedHits
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  performBenchmarkChanged = async () => {
    this.setStateIfMounted({ loading: true })

    const parsedHits = await this.loadAndParseHitsFromES()
    if (parsedHits) {
      this.setStateIfMounted(parsedHits)
    }

    this.setStateIfMounted({ loading: false })
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setStateIfMounted({ loading: true })

    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score

    const name = `Scored ${scoreValue}`

    const initialPoint = {
      name,
      perimeter: 'Initial',
      x: calculatedScoreValue || 0,
      y: calculatedScoreValue || 0,
    }
    const detailedPoint = {
      name,
      perimeter: 'Detailed',
      x: calculatedScoreValue || 0,
      y: calculatedScoreValue || 0,
    }
    const targetInitialData = [initialPoint]
    const targetDetailedData = [detailedPoint]

    this.setStateIfMounted({
      loading: false,
      targetInitialData,
      targetDetailedData,
    })
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props

    const averageHit = await getGlobalAverageOfSegment(scoringTree.scoring.template_id, segment)

    const name = 'Average of segment'

    const initialPoint = {
      name,
      perimeter: 'Initial',
      x: averageHit.exteriorInitial || 0,
      y: averageHit.interiorInitial || 0,
    }
    const detailedPoint = {
      name,
      perimeter: 'Detailed',
      x: averageHit.exteriorDetailed || 0,
      y: averageHit.interiorDetailed || 0,
    }

    const targetInitialData = [initialPoint]
    const targetDetailedData = [detailedPoint]

    this.setStateIfMounted({
      loading: false,
      targetInitialData,
      targetDetailedData,
    })
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props
    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const bestHit = await getBestOfSegment(scoringTree.scoring.template_id, mainSegment.value.slug)

    const initialPoint = {
      name: bestHit.name,
      perimeter: 'Initial',
      x: bestHit.exteriorInitial || 0,
      y: bestHit.interiorInitial || 0,
    }
    const detailedPoint = {
      name: bestHit.name,
      perimeter: 'Detailed',
      x: bestHit.exteriorDetailed || 0,
      y: bestHit.interiorDetailed || 0,
    }

    const targetInitialData = [initialPoint]
    const targetDetailedData = [detailedPoint]

    this.setStateIfMounted({
      loading: false,
      targetInitialData,
      targetDetailedData,
    })
  }

  updateTargetValueByBestOfAll = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props

    const bestHit = await getBestOfAll(scoringTree.scoring.template_id)

    const initialPoint = {
      name: bestHit.name,
      perimeter: 'Initial',
      x: bestHit.exteriorInitial || 0,
      y: bestHit.interiorInitial || 0,
    }
    const detailedPoint = {
      name: bestHit.name,
      perimeter: 'Detailed',
      x: bestHit.exteriorDetailed || 0,
      y: bestHit.interiorDetailed || 0,
    }

    const targetInitialData = [initialPoint]
    const targetDetailedData = [detailedPoint]

    this.setStateIfMounted({
      loading: false,
      targetInitialData,
      targetDetailedData,
    })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree } = this.props
    const { analytics } = scoringTree
    const { selectedReferenceType, benchmarkId } = analytics

    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType
    const prevBenchmarkId = prevProps.scoringTree.analytics.benchmarkId

    if (selectedReferenceType && selectedReferenceType !== prevRefType) {
      await this.performActionOnReferenceUpdated(selectedReferenceType)
    }

    if (benchmarkId !== prevBenchmarkId) {
      await this.performBenchmarkChanged()
    }
  }

  /*
  renderLegend = props => {
    const { payload } = props
    return (
      <div className={classNames({ custom_legends_container: true })}>
        {payload.map((entry, index) => (
          <div
            key={`legend_${index}`}
            className={classNames({ custom_legend_item: true })}
          >
            <div
              className={`custom_legend_marker mapping_overview_legend_marker_${index}`}
            />
            <div className={classNames({ custom_legend_value: true })}>
              {entry.value}
            </div>
          </div>
        ))}
      </div>
    )
  }
  */

  renderCustomizedTooltip = props => {
    const { active } = props
    if (active) {
      const { payload } = props
      const interior = payload[0]
      const exterior = payload[1]

      const { name, perimeter } = interior.payload
      return (
        <div className={classNames({ custom_tooltip: true })}>
          <div className="tooltip_title">{name}</div>
          <div className="tooltip_text">{`${perimeter} ${interior.name} : ${Math.round(interior.value)}`}</div>
          <div className="tooltip_text">{`${perimeter} ${exterior.name} : ${Math.round(exterior.value)}`}</div>
        </div>
      )
    }
    return null
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setStateIfMounted({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setStateIfMounted({ showPopup: false })
  }

  render() {
    const {
      loading,
      showPopup,
      productName,
      cpInitialData,
      cpDetailedData,
      initialData,
      detailedData,
      benchmarkInitialData,
      benchmarkDetailedData,
      targetInitialData,
      targetDetailedData,
    } = this.state
    const { texts } = this.props

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer width={width} height={height}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis
              type="number"
              domain={[0, 500]}
              dataKey="x"
              name="Interior"
              label={{
                value: 'Interior',
                position: 'insideBottomLeft',
              }}
            />
            <YAxis
              type="number"
              domain={[0, dataMax => (dataMax < 200 ? 250 : 500)]}
              dataKey="y"
              name="Exterior"
              padding={{ top: 0 }}
              label={{
                value: 'Exterior',
                angle: -90,
                position: 'insideBottomLeft',
              }}
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={this.renderCustomizedTooltip} />
            {/* 
            <Legend content={this.renderLegend} />
            */}
            <Scatter
              name="Current Product Initial"
              shape={getEmptyDotShape}
              data={cpInitialData}
              fill="#fff"
              stroke="#1d72ff"
            />
            <Scatter name="Current Product Detailed" shape={getFullDotShape} data={cpDetailedData} fill="#1d72ff" />
            <Scatter
              name="Segment Products Initial"
              shape={getEmptyDotShape}
              data={initialData}
              stroke="#c2c2c2"
              fill="#fff"
            />
            <Scatter name="Segment Products Detailed" shape={getFullDotShape} data={detailedData} fill="#c2c2c2" />
            <Scatter
              name="Product Benchmark Initial"
              shape={getRhombusEmptyShape}
              data={benchmarkInitialData}
              fill="#fff"
              stroke="#FF1DA1"
            />
            <Scatter
              name="Product Benchmark Detailed"
              shape={getRhombusFullShape}
              data={benchmarkDetailedData}
              fill="#ff1da1"
            />
            <Scatter
              name="Target Value Initial"
              data={targetInitialData}
              fill="#fff"
              stroke="#1ce71c"
              shape={getBigTargetShape}
            />
            <Scatter
              name="Target Value Detailed"
              data={targetDetailedData}
              fill="#139913"
              stroke="none"
              shape={getSmallTargetShape}
            />
          </ScatterChart>
        </ResponsiveContainer>
      )
    }

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              mapping_overview_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              mapping_overview_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', 200)}</div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={`${productName} : ${texts.mapping_overview}`}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(MappingOverviewChart)
