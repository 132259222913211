import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

// import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
// import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'

import SelectedFlagIcon from '../../../../assets/svg/selected-flag-icon.svg'

import PhotoDBMediaGallery from './photoDBMediaGallery'

const classNames = require('classnames')

export class PhotoDBCriterion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      placehodersMediaCount: 0,
    }
  }

  updatePlaceholderMediaCount = placehodersMediaCount => {
    this.setState({ placehodersMediaCount })
  }

  render() {
    const {
      environment,
      criterionIdx,
      nodeDefId,
      scoringTree,
      updateExpandedNodeIntoScoring,
      mediaTypeToshow,
      list,
    } = this.props
    const { placehodersMediaCount } = this.state

    const criterionDef = scoringTree.nodeDefsObj[nodeDefId]
    const criterionInstance = scoringTree.scoring.nodes[nodeDefId]
    criterionInstance.isExpanded = true

    async function handleCriteriaClicked(e) {
      e.stopPropagation()
      const isChildElement =
        e.target.classList.contains('slider') ||
        e.target.classList.contains('sctree_rp_gallery_show_more_less_btn') ||
        e.target.type === 'checkbox' ||
        e.target.type === 'textarea' ||
        e.target.type === 'text' ||
        e.target.type === 'select-one' ||
        e.target.type === 'file'

      if (!isChildElement) {
        await updateExpandedNodeIntoScoring(scoringTree.scoring.id, nodeDefId, scoringTree.itemView)
      }
    }

    const criterionDesc = () => {
      if (criterionDef.criterion_template.description) {
        const description = criterionDef.criterion_template.description[environment.lang]
          ? criterionDef.criterion_template.description[environment.lang]
          : criterionDef.criterion_template.description[environment.defaultLang]

        return description
      }
      return ''
    }

    const filteredMedia = list.filter(x => x.node_definition_id === nodeDefId)

    if (filteredMedia.length === 0) {
      return <></>
    }

    return (
      <div
        className={classNames({
          sctree_rp_criterion: true,
          sctree_rp_criterion_photodb: true,
          sctree_rp_criterion_disabled: !criterionInstance.is_enabled,
        })}
        role="button"
        tabIndex={0}
        onClick={handleCriteriaClicked}
        onKeyPress={handleCriteriaClicked}
      >
        <div
          className={classNames({
            sctree_rp_criterion_idx: true,
          })}
        >
          {criterionIdx}
        </div>
        <div
          className={classNames({
            sctree_rp_criterion_top_left_cell: true,
          })}
        >
          <div
            className={classNames({
              sctree_rp_criterion_name: true,
            })}
          >
            {criterionDef.name[environment.lang]
              ? criterionDef.name[environment.lang]
              : criterionDef.name[environment.defaultLang]}
          </div>
          <div
            className={classNames({
              sctree_rp_criterion_description: true,
            })}
          >
            {criterionDesc()}
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_criterion_top_right_cell: true,
          })}
        >
          {!criterionInstance.isExpanded && criterionInstance.to_review && (
            <div
              className={classNames({
                sctree_rp_criterion_to_review_flag_container: true,
              })}
            >
              <SelectedFlagIcon />
            </div>
          )}
          <div
            className={classNames({
              sctree_rp_criterion_expand_collapse: true,
            })}
          >
            {/* criterionInstance.isExpanded ? <CollapseIcon /> : <ExpandIcon /> */}
          </div>
        </div>
        {criterionInstance.isExpanded && (
          <div
            className={classNames({
              sctree_rp_exp_area_container: true,
              sctree_rp_criterion_exp_area_container: true,
            })}
          >
            <PhotoDBMediaGallery
              nodeDefId={nodeDefId}
              scoringId={scoringTree.scoring.id}
              placehodersMediaCount={placehodersMediaCount}
              mediaTypeToshow={mediaTypeToshow}
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    list: state.media.list,
  }
}

export default connect(mapStateToProps, actionCreators)(PhotoDBCriterion)
