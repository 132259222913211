import React from 'react'
import { connect } from 'react-redux'
import { ResponsiveContainer, ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'

import CurrentProductIcon from '../../../../../assets/svg/mapping_rhombus.svg'
import ReferenceProductIcon from '../../../../../assets/svg/mapping_star.svg'

import {
  getGlobalAverageOfSegment,
  getBestOfSegment,
  getBestOfAll,
} from '../../../../../../va-corejs-v3/actions/charts'

import { searchProductsByTemplateAndSegmentOrProductId } from '../../../../../../va-corejs-v3/actions/elastic_search'

import {
  getFullDotShape,
  getBenchmarkProductMarkerLarge,
  getProductName,
  calculatePopupChartHeight,
  getCurrentProductMarkerLarge,
  getTargetProductMarkerLarge,
} from './common/utils'

import scorePanelNestedPropsMap from '../../../helper/scorePanelNestedPropsMap'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'
import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'

const classNames = require('classnames')

export class MappingFamilyChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      showPopup: false,
      productName: '',
      cpFamilyData: [],
      familyData: [],
      benchmarkFamilyData: [],
      targetFamilyData: [],
      calculatedDomain: [],
      benchmarkExists: false,
      benchmarkSelected: false,
      popupChartMinSize: 0,
      colors: {
        currentProduct: '#fd5215',
        reference: '#00b050',
        sameSegment: '#c2c2c2',
        benchmark: '#00B0F0',
      },
      settings: {
        chartContainerHeight: 210,
      },
    }
  }

  componentDidMount = async () => {
    this.initComponent()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  initComponent = async () => {
    await new Promise(r => setTimeout(r, 300)) // wait for redux to reload props

    const { scoringTree } = this.props
    const { props } = scoringTree.scoring
    const productName = await getProductName(props)

    const popupChartMinSize = this.popupChartMinSize()

    this.setState({ productName, popupChartMinSize })

    const parsedHits = await this.loadAndParseHitsFromES()
    if (parsedHits) {
      const { selectedReferenceType } = scoringTree.analytics

      this.setState(parsedHits)
      this.performActionOnReferenceUpdated(selectedReferenceType)
      this.performBenchmarkChanged()
    }
  }

  loadAndParseHitsFromES = async () => {
    const { scoringTree } = this.props
    const { benchmarkId } = scoringTree.analytics

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const result = await searchProductsByTemplateAndSegmentOrProductId(
      scoringTree.scoring.template_id,
      mainSegment.value.slug,
      benchmarkId
    )
    if (result.status === 200) {
      const { hits } = result.data.hits
      const parsedHits = await this.parseHits(hits)
      return parsedHits
    }

    return false
  }

  parseHits = async hits => {
    const { scoringTree, family } = this.props
    const { analytics, scoring } = scoringTree
    const currentProductId = scoring.id
    const { benchmarkId } = analytics

    const cpFamilyData = []
    const familyData = []
    const benchmarkFamilyData = []

    for (let i = 0; i !== hits.length; i += 1) {
      const { _source } = hits[i]
      const { id, props } = _source
      const scorePanel = _source.score_panel

      const model = props.model.value
      const name = `${props.brand.value} ${model}`
      const initial = scorePanel[family].values?.[scorePanelNestedPropsMap.initial]?.value
      const detailed = scorePanel[family].values?.[scorePanelNestedPropsMap.detailed]?.value

      // value max 500
      const detailedValue = detailed ? Math.min(500, detailed) : 0
      const initialValue = initial ? Math.min(500, initial) : 0

      const familyPoint = { name, model, x: initialValue, y: detailedValue }

      if (id === currentProductId) {
        familyPoint.z = 300
        cpFamilyData.push(familyPoint)
      } else if (id === benchmarkId) {
        familyPoint.z = 500
        benchmarkFamilyData.push(familyPoint)
      } else {
        familyPoint.z = 100
        familyData.push(familyPoint)
      }
    }
    const parsedHits = {
      hits,
      cpFamilyData,
      familyData,
      benchmarkFamilyData,
    }

    return parsedHits
  }

  performBenchmarkChanged = async () => {
    this.setState({ loading: true })

    const { scoringTree } = this.props
    const { selectedReferenceType, benchmarkId } = scoringTree.analytics

    if (benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      const parsedHits = await this.loadAndParseHitsFromES()

      if (parsedHits) {
        this.setState(parsedHits)
      }

      this.setState({
        benchmarkExists: true,
        benchmarkSelected: true,
        loading: false,
      })
    } else if (selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setState({
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        benchmarkExists: false,
        benchmarkSelected: false,
      })

    const calculatedDomain = this.getDomain()
    this.setState({ loading: false, calculatedDomain })
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setState({ loading: true })

    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score

    const name = `Scored ${scoreValue}`

    const point = {
      name,
      x: calculatedScoreValue || 0,
      y: calculatedScoreValue || 0,
    }
    const targetFamilyData = [point]

    this.setState({
      targetFamilyData,
    })

    const calculatedDomain = this.getDomain()
    this.setState({
      loading: false,
      calculatedDomain,
    })
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setState({ loading: true })

    const { scoringTree, family } = this.props

    const averageHit = await getGlobalAverageOfSegment(scoringTree.scoring.template_id, segment)

    const name = 'Average of segment'
    let point = {}
    if (family === scorePanelPropsMap.exterior) {
      point = {
        name,
        x: averageHit.exteriorInitial || 0,
        y: averageHit.exteriorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.interior) {
      point = {
        name,
        x: averageHit.interiorInitial || 0,
        y: averageHit.interiorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.cargo) {
      point = {
        name,
        x: averageHit.cargoInitial || 0,
        y: averageHit.cargoDetailed || 0,
      }
    }

    const targetFamilyData = [point]

    this.setState({
      targetFamilyData,
    })

    const calculatedDomain = this.getDomain()
    this.setState({
      loading: false,
      calculatedDomain,
    })
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setState({ loading: true })

    const { scoringTree, family } = this.props
    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const bestHit = await getBestOfSegment(scoringTree.scoring.template_id, mainSegment.value.slug)
    let point = {}
    if (family === scorePanelPropsMap.exterior) {
      point = {
        name: bestHit.name,
        x: bestHit.exteriorInitial || 0,
        y: bestHit.exteriorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.interior) {
      point = {
        name: bestHit.name,
        x: bestHit.interiorInitial || 0,
        y: bestHit.interiorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.cargo) {
      point = {
        name: bestHit.name,
        x: bestHit.cargoInitial || 0,
        y: bestHit.cargoDetailed || 0,
      }
    }

    const targetFamilyData = [point]

    this.setState({
      targetFamilyData,
    })

    const calculatedDomain = this.getDomain()
    this.setState({
      loading: false,
      calculatedDomain,
    })
  }

  updateTargetValueByBestOfAll = async () => {
    this.setState({ loading: true })

    const { scoringTree, family } = this.props
    const bestHit = await getBestOfAll(scoringTree.scoring.template_id)
    let point = {}
    if (family === scorePanelPropsMap.exterior) {
      point = {
        name: bestHit.name,
        x: bestHit.exteriorInitial || 0,
        y: bestHit.exteriorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.interior) {
      point = {
        name: bestHit.name,
        x: bestHit.interiorInitial || 0,
        y: bestHit.interiorDetailed || 0,
      }
    } else if (family === scorePanelPropsMap.cargo) {
      point = {
        name: bestHit.name,
        x: bestHit.cargoInitial || 0,
        y: bestHit.cargoDetailed || 0,
      }
    }

    const targetFamilyData = [point]

    this.setState({
      targetFamilyData,
    })

    const calculatedDomain = this.getDomain()
    this.setState({
      loading: false,
      calculatedDomain,
    })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree } = this.props
    const { analytics } = scoringTree
    const { selectedReferenceType, benchmarkId } = analytics

    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType
    const prevBenchmarkId = prevProps.scoringTree.analytics.benchmarkId

    if (selectedReferenceType && selectedReferenceType !== prevRefType) {
      await this.performActionOnReferenceUpdated(selectedReferenceType)
      await this.performBenchmarkChanged()
    }

    if (benchmarkId !== prevBenchmarkId) {
      await this.performBenchmarkChanged()
    }
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  getMinMaxFromArray = list => {
    const filtered = {
      max: undefined,
      min: undefined,
    }

    function setDefaultValue(x, y) {
      filtered.max = x > y ? x : y
      filtered.min = x < y ? x : y
    }

    for (let i = 0; i !== list.length; i += 1) {
      const element = list[i]
      const { x, y } = element

      if (i === 0) {
        setDefaultValue(x, y)
      } else {
        if (x > filtered.max) filtered.max = x
        if (x < filtered.min) filtered.min = x
        if (y > filtered.max) filtered.max = y
        if (y < filtered.min) filtered.min = y
      }
    }
    return filtered
  }

  getDomain = () => {
    const { cpFamilyData, familyData, benchmarkFamilyData, targetFamilyData } = this.state

    const cpFamilyMinMax = this.getMinMaxFromArray(cpFamilyData)
    const familyMinMax = this.getMinMaxFromArray(familyData)
    const benchmarkFamilyMinMax = this.getMinMaxFromArray(benchmarkFamilyData)
    const targetFamilyMinMax = this.getMinMaxFromArray(targetFamilyData)

    let absoluteMin = cpFamilyMinMax.min
    absoluteMin = familyMinMax.min !== undefined && familyMinMax.min < absoluteMin ? familyMinMax.min : absoluteMin
    absoluteMin =
      benchmarkFamilyMinMax.min !== undefined && benchmarkFamilyMinMax.min < absoluteMin
        ? benchmarkFamilyMinMax.min
        : absoluteMin
    absoluteMin =
      targetFamilyMinMax.min !== undefined && targetFamilyMinMax.min < absoluteMin
        ? targetFamilyMinMax.min
        : absoluteMin

    let absoluteMax = cpFamilyMinMax.max
    absoluteMax = familyMinMax.max !== undefined && familyMinMax.max > absoluteMax ? familyMinMax.max : absoluteMax
    absoluteMax =
      benchmarkFamilyMinMax.max !== undefined && benchmarkFamilyMinMax.max > absoluteMax
        ? benchmarkFamilyMinMax.max
        : absoluteMax
    absoluteMax =
      targetFamilyMinMax.max !== undefined && targetFamilyMinMax.max > absoluteMax
        ? targetFamilyMinMax.max
        : absoluteMax

    absoluteMin = this.applyRulesToMin(absoluteMin)
    absoluteMax = this.applyRulesToMax(absoluteMax)

    return [absoluteMin, absoluteMax]
  }

  applyRulesToMax = max => {
    if (max <= 200) return 250
    if (max > 200 && max <= 450) return 450
    if (max > 450 && max <= 500) return 550

    return 600
  }

  applyRulesToMin = min => {
    if (min > 150) return 150
    return 0
  }

  popupChartMaxWidth = () => {
    const popupWidth = typeof window !== 'undefined' ? window.innerWidth - 110 : 0
    return popupWidth
  }

  popupChartMaxHeight = () => {
    const height = calculatePopupChartHeight() + 20
    return height
  }

  popupChartMinSize = () => {
    const width = this.popupChartMaxWidth()
    const height = this.popupChartMaxHeight()

    const size = width < height ? width : height
    return size
  }

  render() {
    const {
      loading,
      showPopup,
      productName,
      cpFamilyData,
      familyData,
      benchmarkFamilyData,
      benchmarkExists,
      benchmarkSelected,
      targetFamilyData,
      calculatedDomain,
      popupChartMinSize,
      colors,
      settings,
    } = this.state
    const { family, texts, scoringTree } = this.props
    const { chartContainerHeight } = settings
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const renderCustomizedTooltip = props => {
      const { active } = props
      if (active) {
        const { payload } = props
        const initial = payload[0]
        const detailed = payload[1]

        const { name } = initial.payload
        let familyName = ''
        switch (family) {
          case scorePanelPropsMap.interior:
            familyName = 'interior'
            break
          case scorePanelPropsMap.cargo:
            familyName = 'cargo'
            break
          default:
            familyName = 'exterior'
        }

        return (
          <div className={classNames({ custom_tooltip: true })}>
            <div className="tooltip_title">{name}</div>
            <div className="tooltip_text">{`${initial.name} ${familyName}: ${Math.round(initial.value)}`}</div>
            <div className="tooltip_text">{`${detailed.name} ${familyName}: ${Math.round(detailed.value)}`}</div>
          </div>
        )
      }
      return null
    }

    const renderCustomizedLabel = props => {
      const { x, y, value } = props

      return (
        <text x={x + 15} y={y + 5} fill="#000" textAnchor="left" dominantBaseline="middle">
          {value}
        </text>
      )
    }

    const renderCustomizedLabelPopupAxisY = props => {
      const { value, viewBox } = props

      return (
        <text
          fill="#000"
          x={-1 * (viewBox.height / 2)}
          y={viewBox.width / 2 - 10}
          className="recharts-text recharts-label"
          textAnchor="middle"
          transform="rotate(-90)"
          dominantBaseline="middle"
        >
          {value}
        </text>
      )
    }

    const renderCustomizedLabelPopupAxisX = props => {
      const { value, viewBox } = props

      return (
        <text
          fill="#000"
          x={viewBox.width / 2 + 70}
          y={popupChartMinSize - 4}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      )
    }

    const renderCustomLegend = () => {
      return (
        <div
          className={classNames({
            mapping_family_legend_container: true,
            popup_version: showPopup,
          })}
        >
          <div
            className={classNames({
              mapping_family_legend_title: true,
              popup_version: showPopup,
            })}
          >
            legend
          </div>
          <div
            className={classNames({
              mapping_family_legend_values: true,
              popup_version: showPopup,
            })}
          >
            <div
              className={classNames({
                mapping_family_legend_item: true,
                popup_version: showPopup,
              })}
            >
              <CurrentProductIcon />
              <span> Current Product</span>
            </div>
            {!benchmarkSelected && (
              <div
                className={classNames({
                  mapping_family_legend_item: true,
                  popup_version: showPopup,
                })}
              >
                <ReferenceProductIcon />
                <span>Reference</span>
              </div>
            )}
            <div
              className={classNames({
                mapping_family_legend_item: true,
                popup_version: showPopup,
              })}
            >
              <div
                className={classNames({
                  mapping_family_legend_icon_dot: true,
                })}
                style={{ backgroundColor: colors.sameSegment }}
              />
              <span>Same segment products</span>
            </div>
            {benchmarkExists && (
              <div
                className={classNames({
                  mapping_family_legend_item: true,
                  popup_version: showPopup,
                })}
              >
                <div
                  className={classNames({
                    mapping_family_legend_icon_dot: true,
                  })}
                  style={{ backgroundColor: colors.benchmark }}
                />
                <span>Benchmark</span>
              </div>
            )}
          </div>
        </div>
      )
    }

    const theChart = (width, height) => {
      return (
        <>
          <ResponsiveContainer
            className={classNames({
              mapping_family_responsive_container_in_popup: showPopup,
            })}
            width={width}
            height={height}
          >
            <ScatterChart>
              <CartesianGrid />
              {!showPopup && (
                <XAxis
                  type="number"
                  domain={calculatedDomain}
                  dataKey="x"
                  name="Initial"
                  label={{
                    value: 'Initial',
                    position: 'insideBottomLeft',
                    offset: -5,
                  }}
                />
              )}
              {showPopup && (
                <XAxis
                  type="number"
                  domain={calculatedDomain}
                  dataKey="x"
                  name="Initial"
                  label={{
                    value: 'Initial',
                    offset: 25,
                    content: renderCustomizedLabelPopupAxisX,
                  }}
                />
              )}
              {!showPopup && (
                <YAxis
                  type="number"
                  domain={calculatedDomain}
                  dataKey="y"
                  name="Detailed"
                  label={{
                    value: 'Detailed',
                    angle: -90,
                    position: 'insideBottomLeft',
                    offset: 20,
                  }}
                />
              )}

              {showPopup && (
                <YAxis
                  type="number"
                  domain={calculatedDomain}
                  dataKey="y"
                  name="Detailed"
                  label={{
                    value: 'Detailed',
                    offset: 20,
                    content: renderCustomizedLabelPopupAxisY,
                  }}
                />
              )}
              <Tooltip cursor={{ strokeDasharray: '3 3' }} content={renderCustomizedTooltip} />
              <Scatter name="Same Segment" shape={getFullDotShape} data={familyData} fill={colors.sameSegment} />
              <Scatter
                name="Current Product"
                className="scatter-current-product scatter-highlight"
                shape={getCurrentProductMarkerLarge}
                data={cpFamilyData}
                fill={colors.currentProduct}
              >
                <LabelList dataKey="model" content={renderCustomizedLabel} />
              </Scatter>
              {benchmarkExists && (
                <Scatter
                  name="Benchmark"
                  className="scatter-benchmark scatter-highlight"
                  shape={getBenchmarkProductMarkerLarge}
                  data={benchmarkFamilyData}
                  fill={colors.benchmark}
                >
                  <LabelList dataKey="model" content={renderCustomizedLabel} />
                </Scatter>
              )}
              {!benchmarkSelected && (
                <Scatter
                  name="Target"
                  className="scatter-target scatter-highlight"
                  shape={getTargetProductMarkerLarge}
                  data={targetFamilyData}
                  fill={colors.reference}
                />
              )}
            </ScatterChart>
          </ResponsiveContainer>
          {renderCustomLegend()}
        </>
      )
    }

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              mapping_family_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              mapping_family_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('80%', chartContainerHeight)}</div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            title={texts.mapping_overview}
            width={popupChartMinSize}
            height={popupChartMinSize}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(MappingFamilyChart)
