import React from 'react'
import { connect } from 'react-redux'

import { Collapse } from 'react-collapse'
import _ from 'lodash'
import * as actionCreators from '../../../store/actions'

import ExpandIcon from '../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../assets/svg/collapse-icon.svg'
import LoadingBar from '../../loading_bar'

import screenTypesMap from '../../../layout/_parts/screenTypesMap'
import {
  countChildrenCriteria,
  toFixed1IfDecimal,
  showPercentage,
  getTotalBonusDemeritScoreRecursively,
} from '../../../../va-corejs-v3/utils'
import ScoringModeMap from '../helper/scoringModeMap'
import analyticsViewMap from '../helper/analyticsViewMap'
import leftPanelModeMap from '../helper/leftPanelModeMap'
import analyticsRefsTypesMap from '../helper/analyticsRefsTypesMap'
import { scrollLeftPanel } from './commons'
import { isProjectModeEnabled } from './projectMode/utils'
import ProjectModeScore from './projectMode/projectModeScore'

const classNames = require('classnames')

export class LeftPanel extends React.Component {
  state = {
    leftPanelTreeMap: [],
    leftPanelScoresToShow: [],
    loading: true,
  }

  componentDidMount = async () => {
    this.setState({ loading: false })
  }

  executeCalculation = async () => {
    this.setState({ loading: true })

    const { doCalculation, scoringTree } = this.props
    const { scoring, nodeDefsObj } = scoringTree
    const { nodes } = scoring

    await doCalculation(scoring, nodeDefsObj, nodes)
    this.setState({ loading: false })
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { leftPanelViewMode } = this.props
    const { loading } = this.state

    if (prevProps.leftPanelViewMode !== leftPanelViewMode && leftPanelViewMode === leftPanelModeMap.full) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ loading: true })
      setTimeout(() => {
        this.executeCalculation()
      }, 500) // wait half second to give time to redux to update load all props
    }

    if (prevState.loading !== loading && loading === false) {
      const { scoringTree } = this.props
      const { itemsFather } = scoringTree
      if (itemsFather && itemsFather.id !== 0) {
        setTimeout(() => {
          scrollLeftPanel(itemsFather.id)
        }, 500)
      }
    }
  }

  componentWillReceiveProps = async () => {
    const leftPanelTreeMap = process.env.treeMap.split(',')
    const leftPanelScoresToShow = process.env.scoresToShow.split(',')
    leftPanelTreeMap.pop() // Remove criteria
    leftPanelTreeMap.pop() // Remove items
    this.setState({ leftPanelTreeMap, leftPanelScoresToShow })
  }

  handleNodeClick = async (node, e) => {
    const {
      authentication,
      updateScoringTreeItemsFather,
      updateMedia,
      loadCommentsFromStorageByItemIdAndUpdateRedux,
      initializeNewCommentsListForRedux,
      initNewCommentsForecast,
      scoringTree,
      environment,
      updateAnalyticsLastClickedNode,
      updateExpandedNodeIntoScoring,
    } = this.props

    const { leftPanelTreeMap } = this.state
    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    // if i click on a leaf of the tree i communicate the items to be displayed to the right panel
    // Else i expand or collapse sub nodes

    const isChildElement = e.target.classList.contains('slider') || e.target.type === 'checkbox'

    if (!isChildElement) {
      const nodeTypePositionInTreeMap = leftPanelTreeMap.indexOf(node.type)
      let relatedItemsFatherId = 0
      if (nodeTypePositionInTreeMap === 0) {
        const perimeter = scoringTree.nodeDefsObj[node.id]
        const familyId = perimeter.children_ids[0]
        const family = scoringTree.nodeDefsObj[familyId]
        const [subfamilyId] = family.children_ids
        relatedItemsFatherId = subfamilyId
      } else if (nodeTypePositionInTreeMap === 1) {
        const family = scoringTree.nodeDefsObj[node.id]
        const [subfamilyId] = family.children_ids
        relatedItemsFatherId = subfamilyId
      } else if (nodeTypePositionInTreeMap === 2) {
        relatedItemsFatherId = node.id
      }
      const itemsFatherNode = scoringTree.nodeDefsObj[relatedItemsFatherId]
      await updateExpandedNodeIntoScoring(scoringTree.scoring.id, node.id, scoringTree.itemView)
      await updateScoringTreeItemsFather(scoringTree.scoring, itemsFatherNode)
      await updateMedia(scoringTree.scoring.id, relatedItemsFatherId)

      const nodeType = scoringTree.nodeDefsObj[node.id].type
      if (analyticsViewMap[nodeType] !== analyticsViewMap.perimeter) {
        await updateAnalyticsLastClickedNode(scoringTree.scoring.id, {
          type: analyticsViewMap[nodeType],
          id: node.id,
        })
      }

      const currentLocale = _.find(environment.locales, locale => locale.code === environment.lang)

      await loadCommentsFromStorageByItemIdAndUpdateRedux(scoringTree.scoring.id, relatedItemsFatherId)
      await initializeNewCommentsListForRedux(
        scoringTree.scoring.id,
        scoringTree.nodeDefsObj,
        relatedItemsFatherId,
        currentLocale,
        authentication.user
      )

      if (isProjectMode) {
        await initNewCommentsForecast(
          scoringTree.scoring.id,
          scoringTree.nodeDefsObj,
          relatedItemsFatherId,
          currentLocale,
          authentication.user
        )
      }

      setTimeout(() => {
        scrollLeftPanel(relatedItemsFatherId)
      }, 500)
    }
  }

  handleEnableDisableClicked = async (node, e) => {
    const { updateEnableDisableNodes, scoringTree, device, processSyncQueue } = this.props
    const checkValue = e.target.checked
    await updateEnableDisableNodes(scoringTree, node, checkValue)

    if (device.isOnline) {
      processSyncQueue()
    }
  }

  normalizedScoreToShow = function(nodeDefObj, nodeObj, levelIndex, scoreSet) {
    const { leftPanelScoresToShow } = this.state
    const { config, scoringTree } = this.props
    const { maxScore } = nodeObj

    const { normalizedScore, score } = scoreSet ? nodeObj[scoreSet] : nodeObj

    if (nodeDefObj.bonus_demerit) {
      const bonusDemeritScore = getTotalBonusDemeritScoreRecursively(
        scoringTree.nodeDefsObj,
        scoringTree.scoring.nodes,
        nodeObj
      )
      const bonusDemeritPercentage = bonusDemeritScore * parseInt(config.demerit_behavior.value, 10)
      return `${bonusDemeritPercentage.toString()}%`
    }

    if (leftPanelScoresToShow[levelIndex] === 'normalized') {
      return `${toFixed1IfDecimal(normalizedScore)} / ${config.max_product_score}`
    }

    if (leftPanelScoresToShow[levelIndex] === 'none') {
      return ''
    }

    return showPercentage(score, maxScore)
  }

  drawNodeContent = (levelIndex, node) => {
    const { leftPanelTreeMap } = this.state
    const { authentication } = this.props
    const { scoringTree, texts, environment, leftPanelViewMode, device } = this.props
    const level = leftPanelTreeMap[levelIndex]
    const nodeDefObj = scoringTree.nodeDefsObj[node.id]
    const nodeObj = scoringTree.scoring.nodes[node.id]
    const itemsCount = countChildrenCriteria(nodeDefObj, scoringTree.nodeDefsObj)
    const isEnabled = nodeObj.is_enabled
    const counterLabel = texts.criterias
    const productIsLocked = scoringTree.scoring.is_locked
    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    let isSelectedNode = false

    if (leftPanelViewMode === leftPanelModeMap.full) {
      isSelectedNode = node.id === scoringTree.itemsFather.id
    } else if (leftPanelViewMode === leftPanelModeMap.light) {
      isSelectedNode =
        (scoringTree.analytics.lastClickedNode.type !== analyticsViewMap.overview &&
          scoringTree.analytics.lastClickedNode.id === node.id) ||
        scoringTree.itemsFather.id === node.id
    }

    const thisNodeHasScore = levelIndex > 0 || JSON.parse(process.env.perimeterHasScore) === true

    return (
      <div
        className={classNames({
          scoring_tree_left_panel_node: true,
          scoring_tree_left_panel_node_light: leftPanelViewMode === leftPanelModeMap.light,
          scoring_tree_left_panel_selected_node: isSelectedNode,
          scoring_tree_left_panel_node_0: levelIndex === 0,
          scoring_tree_left_panel_node_1: levelIndex === 1,
          scoring_tree_left_panel_node_2: levelIndex === 2,
        })}
        itemID={node.id}
        role="button"
        tabIndex={0}
        onClick={e => this.handleNodeClick(node, e)}
        onKeyPress={e => this.handleNodeClick(node, e)}
      >
        <div
          className={classNames({
            scoring_tree_left_panel_node_inner_container: true,
          })}
        >
          {leftPanelViewMode !== leftPanelModeMap.light && (
            <div
              className={classNames({
                scoring_tree_left_panel_pie_container: true,
              })}
            >
              <div
                className={classNames({
                  scoring_tree_score_check_mark: true,
                  scoring_tree_score_check_mark_checked: nodeObj.percentageScored >= 100,
                })}
              />
            </div>
          )}
          <div
            className={classNames({
              scoring_tree_left_panel_node_name: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
              scoring_tree_left_panel_node_name_2: levelIndex === 2,
            })}
          >
            {node.name[environment.lang] ? node.name[environment.lang] : node.name[environment.defaultLang]}
          </div>
          {level !== leftPanelTreeMap[leftPanelTreeMap.length - 1] && (
            <div
              className={classNames({
                scoring_tree_left_panel_expand_collapse_btn: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              {nodeObj.isExpanded ? <CollapseIcon /> : <ExpandIcon />}
            </div>
          )}
          {leftPanelViewMode !== leftPanelModeMap.light && (
            <div
              className={classNames({
                scoring_tree_left_panel_info_container: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
                scoring_tree_left_panel_info_container_2: levelIndex === 2,
              })}
            >
              {nodeObj.is_enabled && thisNodeHasScore && !isProjectMode && (
                <span
                  className={classNames({
                    scoring_tree_left_panel_normalized_score: true,
                    scoring_tree_left_panel_normalized_score_unscored:
                      !nodeObj.scored && nodeObj.percentageScored === 0,

                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                    theme_color: true,
                    theme_background_color_light: true,
                  })}
                >
                  {this.normalizedScoreToShow(nodeDefObj, nodeObj, levelIndex)}
                </span>
              )}
              {nodeObj.is_enabled && isProjectMode && (
                <ProjectModeScore
                  node={nodeObj}
                  scoreDefault={this.normalizedScoreToShow(nodeDefObj, nodeObj, levelIndex)}
                  scoreForecast={this.normalizedScoreToShow(nodeDefObj, nodeObj, levelIndex, 'forecast')}
                />
              )}
              {thisNodeHasScore && (
                <div
                  className={classNames({
                    scoring_tree_left_panel_children_container: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  {itemsCount} {counterLabel}
                </div>
              )}
              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    scoring_tree_left_panel_enable_disable_btn_container: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  <label htmlFor={node.id} className={classNames({ ios_toggle: true })}>
                    <input
                      id={node.id}
                      type="checkbox"
                      checked={isEnabled}
                      onChange={e => {
                        this.handleEnableDisableClicked(node, e)
                      }}
                    />
                    <span className={classNames({ slider: true })} />
                  </label>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  drawLevel = (levelIndex, nodeList) => {
    const { leftPanelTreeMap } = this.state
    const { scoringTree } = this.props

    if (levelIndex > leftPanelTreeMap.length - 1) {
      return <></>
    }

    // Create nodeList if not exists
    if (!nodeList) {
      nodeList = []
      for (let i = 0; i < scoringTree.defKeys.length; i += 1) {
        const node = scoringTree.nodeDefsObj[scoringTree.defKeys[i]]
        if (node.type === leftPanelTreeMap[0]) {
          nodeList.push(node.id)
        }
      }
    }

    // Create Html code
    const html = []

    if (scoringTree.scoringMode === ScoringModeMap.analytics && levelIndex === 0) {
      html.push(
        <div key={0}>
          <div>{this.drawOverviewNodeContent()}</div>
        </div>
      )
    }

    for (let i = 0; i < nodeList.length; i += 1) {
      const node = scoringTree.nodeDefsObj[nodeList[i]]
      const { isExpanded } = scoringTree.scoring.nodes[node.id]
      html.push(
        <div
          key={node.id}
          className={classNames({
            scoring_tree_left_panel_node_outer_wrapper: true,
          })}
        >
          <div>{this.drawNodeContent(levelIndex, node)}</div>
          <Collapse isOpened={isExpanded}>
            <div
              className={classNames({
                scoring_tree_left_panel_node_list_wrapper: true,
              })}
            >
              {this.drawLevel(levelIndex + 1, node.children_ids)}
            </div>
          </Collapse>
        </div>
      )
    }
    return html
  }

  handleOverviewClick = async () => {
    const { updateAnalyticsLastClickedNodeAndRefType, scoringTree } = this.props
    const lastClickedNode = {
      type: analyticsViewMap.overview,
      id: scoringTree.scoring.id,
    }
    const selectedReferenceType = analyticsRefsTypesMap.default
    await updateAnalyticsLastClickedNodeAndRefType(scoringTree.scoring.id, lastClickedNode, selectedReferenceType)
  }

  drawOverviewNodeContent = () => {
    const { device, texts, scoringTree } = this.props
    return (
      <div
        className={classNames({
          scoring_tree_left_panel_node: true,
          scoring_tree_left_panel_node_light: true,
          scoring_tree_left_panel_node_0: true,
          scoring_tree_left_panel_selected_node:
            scoringTree.analytics.lastClickedNode.type === analyticsViewMap.overview,
        })}
        role="button"
        tabIndex={0}
        onClick={() => this.handleOverviewClick()}
        onKeyPress={() => this.handleOverviewClick()}
      >
        <div
          className={classNames({
            scoring_tree_left_panel_node_inner_container: true,
          })}
        >
          <div
            className={classNames({
              scoring_tree_left_panel_node_name: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {texts.model_overview}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { loading } = this.state
    const { device } = this.props

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              scoring_tree_left_panel_loading_bar: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && (
          <div
            className={classNames({
              scoring_tree_left_panel: true,
              scoring_tree_left_panel_with_sticky: device.isStickyHeader,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {this.drawLevel(0)}
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    config: state.environment.config,
  }
}

export default connect(mapStateToProps, actionCreators)(LeftPanel)
