/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import * as actionCreators from '../../../../store/actions'
import MediaLightbox from './mediaLightbox'
import { createBreadcrumb } from '../../../../../va-corejs-v3/utils'
import CanvasImage from './canvasImage'
import DragAndDropSmallIcon from '../../../../assets/svg/drag-icon-small-white.svg'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import ConfirmationModal from '../../../commons/confirmationModal'
import PlayButton from './playButton'
import DownloadMediaButton from './downloadMediaButton'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

const DragHandle = SortableHandle(() => (
  <div
    className={classNames({
      drag_btn_small: true,
      sctree_rp_gallery_item_btn: true,
    })}
  >
    <DragAndDropSmallIcon />
  </div>
))

const SortableItem = SortableElement(({ value }) => (
  <li className="sctree_rp_gallery_sortable_list_element">
    <DragHandle />
    {value}
  </li>
))

const SortableList = SortableContainer(({ items }) => {
  return (
    <ol className="sctree_rp_gallery_sortable_list">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ol>
  )
})

export class MediaGallery extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showAllMedia: JSON.parse(process.env.showMoreMediaButtonEnabled) === false,
      showMediaLightbox: false,
      mediaIndex: 0,
      lightboxBreadcrumb: [],
      filteredMedia: [],

      // Media deletion variables
      mediaIdToDelete: null,
      confirmationDeleteModalOpen: false,
    }

    this.openCloseMediaGallery = this.openCloseMediaGallery.bind(this)
  }

  changeShowAllMediaValue = () => {
    const { showAllMedia } = this.state
    this.setState({ showAllMedia: !showAllMedia })
  }

  openCloseMediaGallery = (e, showMediaLightbox, mediaIndex) => {
    e.stopPropagation()
    this.setState({ showMediaLightbox, mediaIndex })
  }

  componentDidMount = async () => {
    const { nodeDefId, scoringTree, environment, list, collection } = this.props

    const mediaCollection = collection || mediaCollectionsMap.node_pictures

    const { nodeDefsObj } = scoringTree
    const filteredMedia = list
      .filter(x => x.node_definition_id === nodeDefId && x.collection === mediaCollection)
      .reverse()
    const breadcrumb = createBreadcrumb(nodeDefsObj, nodeDefId, environment.lang)
    this.setState({ filteredMedia, lightboxBreadcrumb: breadcrumb })
  }

  componentWillReceiveProps = async nextProps => {
    const { nodeDefId, list, collection } = this.props
    const mediaCollection = collection || mediaCollectionsMap.node_pictures

    if (nextProps.list !== list) {
      const filteredMedia = nextProps.list.filter(
        x => x.node_definition_id === nodeDefId && x.collection === mediaCollection
      )
      this.setState({ filteredMedia })
    }
  }

  onDeleteMediaClick = (e, id) => {
    e.stopPropagation()
    this.setState({
      mediaIdToDelete: id,
      confirmationDeleteModalOpen: true,
    })
  }

  onConfirmationDeleteModalClose = () => {
    this.setState({
      mediaIdToDelete: null,
      confirmationDeleteModalOpen: false,
    })
  }

  handleDeleteMedia = async () => {
    const { list, deleteMediaAndUpdateRedux, scoringTree, device, processSyncQueue } = this.props
    const { mediaIdToDelete } = this.state
    await deleteMediaAndUpdateRedux(scoringTree, mediaIdToDelete, [...list])

    this.setState({
      mediaIdToDelete: null,
      confirmationDeleteModalOpen: false,
    })

    if (device.isOnline) {
      processSyncQueue()
    }
  }

  render() {
    const {
      authentication,
      list,
      nodeDefId,
      scoringTree,
      device,
      placehodersMediaCount,
      processSyncQueue,
      changeMediaSubsetOrder,
      texts,
    } = this.props

    const {
      showMediaLightbox,
      lightboxBreadcrumb,
      mediaIndex,
      filteredMedia,
      showAllMedia,
      confirmationDeleteModalOpen,
    } = this.state

    const productIsLocked = scoringTree.scoring.is_locked

    const thisObject = this

    async function onSortEnd({ oldIndex, newIndex }) {
      await changeMediaSubsetOrder(filteredMedia, oldIndex, newIndex, [...list])

      if (device.isOnline) {
        processSyncQueue()
      }
    }

    function nodeMediaList() {
      const gallery = []

      const mediaToShow = showAllMedia ? filteredMedia.length : Math.min(filteredMedia.length, 3)

      // TODO: It shouldn't be necessary but the items aren't shown in the correct order without the following fix. Investigate for a more elegant solution.
      const sortedMedia = filteredMedia.sort((a, b) => {
        if (a.order < b.order) {
          return -1
        }
        if (a.order > b.order) {
          return 1
        }
        return 0
      })

      for (let i = 0; i !== mediaToShow; i += 1) {
        const media = sortedMedia[i]
        const mediaType = media.file_type.split('/')[0]

        if (media.node_definition_id === nodeDefId) {
          if ((!device.isOnline && mediaType !== 'video') || device.isOnline) {
            gallery.push(
              <div
                className={classNames({
                  sctree_rp_gallery_item_placeholder: true,
                  placeholder_image: true,
                })}
              >
                <CanvasImage
                  id={uuidv4()}
                  idx={i}
                  thumbUrl={media.file_thumb}
                  openCloseMediaGallery={thisObject.openCloseMediaGallery}
                />

                {mediaType === 'video' && (
                  <PlayButton idx={i} openCloseMediaGallery={thisObject.openCloseMediaGallery} />
                )}

                {device.isOnline && <DownloadMediaButton media={media} />}

                {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                  <div
                    className={classNames({
                      sctree_rp_gallery_item_btn: true,
                      sctree_rp_gallery_item_delete_btn: true,
                    })}
                    role="button"
                    tabIndex={0}
                    onClick={e => {
                      thisObject.onDeleteMediaClick(e, media.id)
                    }}
                    onKeyPress={e => {
                      thisObject.onDeleteMediaClick(e, media.id)
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20">
                      <path d="M12.53 20c1.256 0 2.1-.835 2.16-2.12l.606-13.057h1.054c.36 0 .65-.315.65-.683 0-.368-.29-.673-.65-.673h-4.013V2.119C12.337.826 11.486 0 10.133 0H6.85C5.497 0 4.654.826 4.654 2.12v1.347H.65c-.351 0-.65.314-.65.673 0 .368.299.683.65.683h1.062l.606 13.067c.062 1.284.887 2.11 2.16 2.11h8.052zM10.941 3.467H6.06V2.209c0-.512.36-.87.896-.87h3.082c.535 0 .904.358.904.87v1.258zm1.449 15.177H4.601c-.5 0-.878-.386-.904-.916L3.082 4.823h10.81l-.58 12.905c-.026.539-.395.916-.922.916zm-1.282-1.572c.29 0 .5-.242.51-.583l.263-9.421c.008-.332-.211-.584-.51-.584-.272 0-.5.26-.509.584L10.6 16.48c-.01.332.21.592.509.592zm-5.207 0c.298 0 .518-.26.51-.592l-.273-9.412c-.009-.324-.237-.584-.51-.584-.298 0-.517.252-.509.584l.264 9.42c.009.342.22.584.518.584zm2.599 0c.29 0 .536-.26.536-.583V7.068c0-.324-.246-.584-.536-.584-.281 0-.527.26-.527.584v9.42c0 .324.246.584.527.584z" />
                    </svg>
                  </div>
                )}
              </div>
            )
          }
        }
      }

      for (let i = 0; i !== placehodersMediaCount; i += 1) {
        gallery.push(
          <div
            className={classNames({
              placeholder_media_loader: true,
            })}
            // key={uuidv4()}
          >
            <img src="/ajax-loader.gif" alt="loading" />
          </div>
        )
      }

      if (!showAllMedia && filteredMedia.length > 3) {
        gallery.push(
          <div
            className={classNames({
              sctree_rp_gallery_show_more_less_btn: true,
            })}
            role="button"
            // key={uuidv4()}
            tabIndex={0}
            onClick={() => {
              this.changeShowAllMediaValue()
            }}
            onKeyPress={() => {
              this.changeShowAllMediaValue()
            }}
          >
            ...Show more
          </div>
        )
      }

      return gallery
    }

    return (
      <>
        <div className={classNames({ sctree_rp_gallery_container: true })}>
          {showMediaLightbox && (
            <MediaLightbox
              nodeDefId={nodeDefId}
              openCloseMediaGallery={this.openCloseMediaGallery}
              breadcrumb={lightboxBreadcrumb}
              list={filteredMedia}
              mediaIndex={mediaIndex}
            />
          )}
          <SortableList items={nodeMediaList()} onSortEnd={onSortEnd} axis="xy" useDragHandle />
        </div>

        <ConfirmationModal
          message={texts.msgbox_confirm_delete_scoring_tree_media}
          onConfirm={thisObject.handleDeleteMedia}
          onClose={thisObject.onConfirmationDeleteModalClose}
          open={confirmationDeleteModalOpen === true}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    list: state.media.list,
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(MediaGallery)
