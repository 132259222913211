import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

const classNames = require('classnames')

export class ScoringPie extends React.Component {
  render() {
    const { percentage } = this.props
    const completedDeg = (percentage * 360) / 100
    const remainingDeg = 360 - completedDeg

    return (
      <div
        className={classNames({
          scoring_pie_container: true,
        })}
      >
        <div
          className={classNames({
            scoring_pie_percentage: true,
          })}
          style={{
            backgroundImage: `conic-gradient(rgba(0, 0, 0, 0) ${completedDeg}deg, #fff ${completedDeg}deg ${remainingDeg}deg)`,
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringPie)
