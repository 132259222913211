import { Button, ButtonGroup } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

const classNames = require('classnames')

export class CalculationModeButtons extends React.Component {
  render() {
    const { scoringTree, texts, updateCalculationMode } = this.props
    const selectedFrecast =
      typeof scoringTree.calculationMode !== 'undefined' && scoringTree.calculationMode === 'forecast'

    return (
      <div className="calculation_mode_container ">
        <span className="calculation_mode_label">{texts.calculation_mode}</span>

        <ButtonGroup
          className="calculation_mode_forecast va_button_switch"
          disableElevation
          disableRipple
          variant="contained"
          aria-label="outlined button group"
        >
          <Button
            className={classNames({
              selected: !selectedFrecast,
            })}
            onClick={() => {
              updateCalculationMode('')
            }}
          >
            {texts.score}
          </Button>
          <Button
            className={classNames({
              selected: selectedFrecast,
            })}
            onClick={() => {
              updateCalculationMode('forecast')
            }}
          >
            {texts.forecast}
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(CalculationModeButtons)
