import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../../store/actions'

import LoadingBar from '../../../../loading_bar/index.js'

import ReferenceIcon from '../../../../../assets/svg/reference-icon.svg'
import { searchProductsByTemplate } from '../../../../../../va-corejs-v3/actions/elastic_search'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import analyticsViewMap from '../../../helper/analyticsViewMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const classNames = require('classnames')

export class ReferenceSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      segments: {},
    }
  }

  componentDidMount = async () => {
    const { scoringTree } = this.props
    const result = await searchProductsByTemplate(scoringTree.scoring.template_id)
    const { hits } = result.data.hits
    const segments = {}
    for (let i = 0; i !== hits.length; i += 1) {
      const hit = hits[i]
      const segment = hit._source.props[scopePropsMap.main_segment]
      if (segment) {
        const { slug } = segment
        if (!segments[slug]) {
          segments[slug] = segment.value
        }
      }
    }

    this.setState({ initialized: true, segments })
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  render() {
    const { texts, scoringTree, setAnalyticsRefTypeIntoStorageAndUpdateRedux } = this.props
    const { initialized, segments } = this.state
    const { lastClickedNode } = scoringTree.analytics

    function drawSegmentOptions() {
      const html = []

      const segmentsKeys = Object.keys(segments)
      for (let i = 0; i < segmentsKeys.length; i += 1) {
        const sKey = segmentsKeys[i]
        const sValue = segments[sKey]
        html.push(
          <option key={i} value={`${analyticsRefsTypesMap.segment}_${sKey}`}>
            {texts.average_segment} {sValue}
          </option>
        )
      }

      return html
    }

    function handleSelectedReference(reference) {
      setAnalyticsRefTypeIntoStorageAndUpdateRedux(scoringTree.scoring.id, reference)
    }

    return (
      <>
        {initialized === false && (
          <div
            className={classNames({
              reference_selector_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {initialized === true && (
          <div className={classNames({ reference_selector: true })}>
            <div className={classNames({ reference_selector_icon: true })}>
              <ReferenceIcon />
            </div>
            <div className={classNames({ reference_selector_label: true })}>{texts.add_reference}</div>
            <div className={classNames({ reference_selector_list: true })}>
              <select
                className={classNames({
                  va_select: true,
                  reference_select: true,
                })}
                onChange={e => {
                  const selectedReference = e.target.options[e.target.selectedIndex].value
                  handleSelectedReference(selectedReference)
                }}
                value={scoringTree.analytics.selectedReferenceType}
              >
                <option value={analyticsRefsTypesMap.default}>{texts.default}</option>
                {lastClickedNode.type !== analyticsViewMap.overview &&
                  [1, 2, 3, 4].map((item, i) => (
                    <option key={i} value={`${analyticsRefsTypesMap.scored}_${item}`}>
                      {texts.scored} {item}
                    </option>
                  ))}
                {drawSegmentOptions()}
                <option value={analyticsRefsTypesMap.best_of_segment}>{texts.best_of_segment}</option>
                <option value={analyticsRefsTypesMap.best_of_all}>{texts.best_of_all}</option>
                <option value={analyticsRefsTypesMap.benchmark}>{texts.benchmark}</option>
              </select>
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ReferenceSelector)
