import React from 'react'
import { connect } from 'react-redux'

import Grid from '@material-ui/core/Grid'

import * as actionCreators from '../../../../store/actions'
import scopePropsMap from '../../../scoring/_parts/helper/scopePropsMap'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import PropsTable from './propsTable'
import ScoringPicture from './scoringPicture'
import ProductModeBreadcrumbs from './breadcrumbs'
import ChartComments from './charts/comments'
import ChartMedia from './charts/media'
import AverageSubfamiliesScore from './charts/averageSubfamiliesScore'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ProductMode extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
    }
  }

  componentDidMount = async () => {
    this.setState({ loaded: true })
  }

  render() {
    const { texts, device } = this.props
    const { loaded } = this.state

    const picturesCollections = ['cover_pictures', 'full_exterior', 'full_interior']

    const mainProps = [
      scopePropsMap.main_segment,
      scopePropsMap.silhouette,
      scopePropsMap.level,
      scopePropsMap.energy,
      scopePropsMap.distance,
      scopePropsMap.price,
    ]

    const exteriorDetailsProps = [
      scopePropsMap.wheels_material,
      scopePropsMap.wheel_size,
      scopePropsMap.exterior_decor,
      scopePropsMap.lights_technology,
    ]

    const interiorDetailsProps = [
      scopePropsMap.trim,
      scopePropsMap.upholstery,
      scopePropsMap.screen_size,
      scopePropsMap.gearbox,
    ]

    if (!loaded) {
      return <></>
    }

    return (
      <div
        className={classNames({
          product_mode_container: true,
          isSafari: device.isSafari,
          isFirefox: device.isFirefox,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <AverageSubfamiliesScore />
            <ChartMedia />
            <ChartComments />
          </Grid>
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <div className="product_mode_identity_container">
              <ProductModeBreadcrumbs />

              <div className="product_mode_body_wrapper">
                <div className="product_mode_scoring_pictures_container">
                  {picturesCollections.map(collectionName => (
                    <ScoringPicture collection={collectionName} key={uuidv4()} />
                  ))}
                </div>

                <div className="product_mode_props_container">
                  <PropsTable propsList={mainProps} />
                  <PropsTable propsList={exteriorDetailsProps} title={texts.exterior_details} />
                  <PropsTable propsList={interiorDetailsProps} title={texts.interior_details} />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(ProductMode)
