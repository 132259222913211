import React from 'react'
import { connect } from 'react-redux'

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  Tooltip,
  ReferenceLine,
} from 'recharts'

import { getBestOfSegmentPerNodesList } from '../../../../../../va-corejs-v3/actions/charts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'
import {
  calculateChartWidthPerPopup,
  calculateInnerContainerWidth,
  calculatePopupChartHeight,
  calulateTickCharactersPerLine,
  doCalculationForAnalytics,
  getProductName,
} from './common/utils'
import { splitTextToLines } from '../../../../../../va-corejs-v3/utils'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const uuidv4 = require('uuid/v4')

const classNames = require('classnames')

export class AreaPerformanceChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.chartRef = React.createRef()

    this.state = {
      loading: false,
      showPopup: false,
      benchmarkExists: false,
      benchmarkSelected: false,
      productName: '',
      data: [],
      dataLabels: [],
      barColor: '',
      colorBenchmark: '#00BBFF',
      settings: {
        chartContainerHeight: 230,
        xAxisDataKey: 'area',
        xAxisHeight: 60,
        colors: {
          initial: {
            exterior: '#695186',
            interior: '#245967',
            cargo: '#B94A29',
          },
          detailed: {
            exterior: '#695186',
            interior: '#245967',
            cargo: '#B94A29',
          },
        },
      },
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  getChartColor = () => {
    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree

    const { settings } = this.state
    const { colors } = settings

    const currentNode = nodeDefsObj[root]
    const { type, name } = currentNode

    let familyName = name.en
    let perimeterName = ''

    function getParent(n) {
      const parentId = n.parent_id
      const p = nodeDefsObj[parentId]
      return p
    }

    if (type === nodeDefinitionTypeMap.family) {
      perimeterName = getParent(currentNode).name.en
    } else if (type === nodeDefinitionTypeMap.subfamily) {
      const family = getParent(currentNode)
      familyName = family.name.en
      perimeterName = getParent(family).name.en
    }

    const per = perimeterName.indexOf('Initial') === 0 ? 'initial' : 'detailed'

    let fam = ''
    if (familyName.indexOf('Exterior') === 0) fam = 'exterior'
    if (familyName.indexOf('Interior') === 0) fam = 'interior'
    if (familyName.indexOf('Cargo') === 0) fam = 'cargo'

    const barColor = colors[per][fam]
    this.setStateIfMounted({ barColor })
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, environment, root } = this.props
    const { nodeDefsObj } = scoringTree
    const { nodes, props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const duplicatedNodes = { ...nodes }
    await doCalculationForAnalytics(nodeDefsObj, duplicatedNodes)

    const areas = []
    const areaIdList = nodeDefsObj[root].children_ids
    for (let i = 0; i !== areaIdList.length; i += 1) {
      const areaId = areaIdList[i]
      const area = nodeDefsObj[areaId]
      const areaInstance = duplicatedNodes[areaId]
      if (!area.bonus_demerit && areaInstance?.is_enabled) {
        areas.push(area)
      }
    }

    const data = []
    const dataLabels = []

    for (let i = 0; i !== areas.length; i += 1) {
      const area = areas[i]
      const areaInstance = duplicatedNodes[area.id]
      const { percentage } = areaInstance

      const areaName = area.name[environment.defaultLang]
      dataLabels.push(areaName)

      data.push({
        area: '',
        performance: percentage,
      })
    }

    this.setStateIfMounted({ data, dataLabels })
    this.getChartColor()
    await this.updateTargetValueByBestOfSegment()
    await this.updateBenchmarkData()
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, root } = this.props
    const { data } = this.state
    const { nodeDefsObj } = scoringTree

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const children = nodeDefsObj[root].children_ids
    const bestValues = await getBestOfSegmentPerNodesList(
      scoringTree.scoring.template_id,
      mainSegment.value.slug,
      children
    )
    const valuesKeys = Object.keys(bestValues)

    data.map((currentData, index) => {
      const key = valuesKeys[index]
      currentData.reference = bestValues[key]
      return currentData
    })

    this.setState({ loading: false, data })
  }

  updateBenchmarkData = async () => {
    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree
    const { data } = this.state
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { nodes } = scoringTree.scoring

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })
      const duplicatedNodes = { ...benchmarkNodes }
      await doCalculationForAnalytics(nodeDefsObj, duplicatedNodes)

      const areas = []
      const areaIdList = nodeDefsObj[root].children_ids

      for (let i = 0; i !== areaIdList.length; i += 1) {
        const areaId = areaIdList[i]
        const area = nodeDefsObj[areaId]
        const areaInstanceCurrent = nodes[areaId]

        // If current product node are enabled then update benchmark data
        if (!area.bonus_demerit && areaInstanceCurrent.is_enabled) {
          areas.push(area)
        }
      }

      data.map((currentData, index) => {
        const area = areas[index]

        if (area) {
          const areaInstance = duplicatedNodes[area.id]

          // If benchmark data are enable then push percentage, if not update null
          const percentage = areaInstance?.is_enabled ? areaInstance.percentage : null
          currentData.benchmark = percentage
        }

        return currentData
      })

      this.setState({
        loading: false,
        data,
        benchmarkExists: true,
        benchmarkSelected: true,
      })
    } else if (selectedReferenceType === 'benchmark') {
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: false,
      })
  }

  componentDidUpdate = async prevProps => {
    const { root, scoringTree } = this.props

    const prevRoot = prevProps.root
    const reinitComponent = root !== prevRoot

    if (reinitComponent) {
      await this.initComponent()
    }

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    if (currRefType && currRefType !== prevRefType) {
      await this.updateBenchmarkData()
    }

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes
    if (benchmarkNodes !== prevBenchmarkNodes) {
      await this.updateBenchmarkData()
    }
  }

  handleShowPopupClicked = async () => {
    const { data } = this.state
    if (data.length === 0) return

    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    await this.initComponent()

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = async () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    await this.initComponent()
    this.setState({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      productName,
      data,
      benchmarkExists,
      benchmarkSelected,
      dataLabels,
      barColor,
      colorBenchmark,
      settings,
    } = this.state
    const { chartContainerHeight, xAxisDataKey, xAxisHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const renderCustomizedLabel = props => {
      const { x, y, width, value } = props
      return (
        <text x={x + width / 2} y={y + 10} fill="#fff" textAnchor="middle" dominantBaseline="middle">
          {Math.round(value)}
        </text>
      )
    }

    const renderCustomizedLabelOnFront = props => {
      const { x, y, width, value } = props
      const yPosition = (y || 0) - 15

      return (
        <g>
          <foreignObject className="front-label-container" x={x} y={yPosition} width={width} height={15}>
            <span className="front-label">{Math.round(value) || ''}</span>
          </foreignObject>
        </g>
      )
    }

    const renderCustomizedLabelPerRefLine = props => {
      const { x, y, index } = props

      const value = data[index].reference ? Math.round(data[index].reference) : ''

      return (
        <text x={x} y={y - 10} fill="#000" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      )
    }

    const renderCustomizedAxisTick = props => {
      const { x, y, payload } = props
      const { index } = payload

      const lineHeigh = showPopup === true ? '12px' : '10px'

      const ticksNumber = dataLabels.length
      const { offsetWidth } = this.chartRef.current.container
      const chartPadding = 70

      const maxLineNumber = showPopup === true ? parseInt(xAxisHeight / 12, 10) - 1 : parseInt(xAxisHeight / 10, 10) - 1

      const charactersPerLine = calulateTickCharactersPerLine(offsetWidth, chartPadding, showPopup, ticksNumber)
      const lines = splitTextToLines(dataLabels[index], charactersPerLine)

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {lines.map((line, idx) => (
              <tspan
                key={uuidv4()}
                className={classNames({
                  hidden: idx >= maxLineNumber,
                })}
                x={0}
                dy={lineHeigh}
              >
                {line}
              </tspan>
            ))}
          </text>
        </g>
      )
    }

    const renderLegend = props => {
      const { payload } = props

      function renderPayloads() {
        const html = []
        for (let i = 0; i !== payload.length; i += 1) {
          const itm = payload[i]
          const { color, value } = itm
          const renderValue = value === 'reference' ? 'Best of segment' : value
          html.push(
            <div key={uuidv4()} className={classNames({ legend_item: true })}>
              <div className={classNames({ legend_color: true })} style={{ backgroundColor: color }} />
              <div className={classNames({ legend_value: true })}>{renderValue}</div>
            </div>
          )
        }
        return html
      }

      const html = []
      html.push(
        <div key={uuidv4()} className={classNames({ area_performance_legends_container: true })}>
          {renderPayloads()}
        </div>
      )

      return html
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer ref={this.chartRef} width={width} height={height}>
          <ComposedChart data={data}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey={xAxisDataKey} height={xAxisHeight} tick={renderCustomizedAxisTick} />
            <YAxis type="number" domain={[0, 100]} />
            {!benchmarkSelected && (
              <Line type="monotone" dataKey="reference" stroke="#D9D9D9" strokeWidth="2.5">
                <LabelList dataKey="absolute" content={renderCustomizedLabelPerRefLine} />
              </Line>
            )}
            <Bar dataKey="performance" fill={barColor} radius={[0, 0, 0, 0]}>
              <LabelList
                dataKey="performance"
                content={benchmarkExists ? renderCustomizedLabelOnFront : renderCustomizedLabel}
              />
            </Bar>

            {benchmarkExists && data.length === 1 && (
              <ReferenceLine y={data[0].benchmark} stroke={colorBenchmark} strokeWidth={2.5} />
            )}

            {benchmarkExists && <Line connectNulls dataKey="benchmark" stroke={colorBenchmark} strokeWidth="2.5" />}

            {benchmarkExists && <Tooltip isAnimationActive={false} formatter={value => Math.round(value)} />}
            <Legend content={renderLegend} />
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    const renderNoData = () => {
      return <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
    }

    const innerContainerWidth = calculateInnerContainerWidth(data)

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              area_performance_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && showPopup === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              area_performance_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div
              style={{
                width: innerContainerWidth,
                height: chartContainerHeight,
              }}
              className={classNames({ chart_inner_container: true })}
            >
              {data.length > 0 ? theChart('100%', chartContainerHeight) : renderNoData()}
            </div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            title={texts.percentage_performance_per_area}
            width={calculateChartWidthPerPopup(data)}
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(AreaPerformanceChart)
