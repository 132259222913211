import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'
import scopePropsMap from '../../../scoring/_parts/helper/scopePropsMap'
import InfoIcon from '../../../../assets/svg/info.svg'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ProductModeBreadcrumbs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      commercialNameGrade: null,
      model: null,
      brand: null,
      brandLogoUrl: null,
    }
  }

  componentDidMount = async () => {
    await this.setProps()
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree, environment } = this.props

    if (prevProps.scoringTree !== scoringTree || prevProps.environment !== environment) {
      await this.setProps()
    }
  }

  setProps = async () => {
    const { scoringTree, environment } = this.props

    const commercialNameGradeProp = this.currentProp(scopePropsMap.comm_name_grade)
    const commercialNameGradeValue = commercialNameGradeProp
      ? commercialNameGradeProp.value.body[environment.defaultLang]
      : null

    const modelProp = this.currentProp(scopePropsMap.model)
    const modelValue = modelProp ? modelProp.value.body[environment.defaultLang] : null

    const brandDefinition = _.find(scoringTree.scopeProps, item => item.slug === scopePropsMap.brand)
    const brandValues = brandDefinition.values

    const brandProp = this.currentProp(scopePropsMap.brand)
    const brandSlug = brandProp ? brandProp.value.slug : null
    const brand = _.find(brandValues, item => item.slug === brandSlug)
    const brandName = brand ? brand.body[environment.defaultLang] : null
    const brandLogoUrl = brand && brand.media.length > 0 ? await getMediaBlobUrl(brand.media[0].url) : null

    this.setState({
      commercialNameGrade: commercialNameGradeValue,
      model: modelValue,
      brand: brandName,
      brandLogoUrl,
    })
  }

  currentProp = propSlug => {
    const { scoringTree } = this.props
    return _.find(scoringTree.scoring.props, item => item.slug === propSlug)
  }

  render() {
    const { commercialNameGrade, model, brand, brandLogoUrl } = this.state

    return (
      <div
        className={classNames({
          product_mode_breadcrumbs: true,
        })}
      >
        <div className="product_mode_breadcrumbs_icon">
          <InfoIcon height={20} width={20} />
        </div>
        <div className="product_mode_breadcrumbs_divider" />

        {brand && (
          <div className="product_mode_breadcrumbs_brand">
            {brandLogoUrl === null && <span>{brand}</span>}
            {brandLogoUrl && <img src={brandLogoUrl} alt={brand} width="38" />}
          </div>
        )}

        <div className="product_mode_breadcrumbs_label">
          {model && <span className="product_mode_breadcrumbs_label_model">{model}</span>}
          &nbsp;
          {commercialNameGrade && (
            <span className="product_mode_breadcrumbs_label_commercial_name_grade">{commercialNameGrade}</span>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ProductModeBreadcrumbs)
