import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import CheckIcon from '../../../assets/svg/button-desk-check.svg'

import * as actionCreators from '../../../store/actions'
import ScoringSpecifyProduct from '../../scoring/_parts/scoringSpecifyProduct'
import mediaCollectionsMap from '../../../../va-corejs-v3/utils/mediaCollectionsMap'
import screenTypesMap from '../../../layout/_parts/screenTypesMap'
import scoringPropsTypeMap from '../../scoring/_parts/helper/scoringPropsTypeMap'
import { SYNC_ACTION_UPDATE_PRODUCT } from '../../../../va-corejs-v3/sync/consts'
import syncQueue from '../../../../va-corejs-v3/sync/syncQueue'

const classNames = require('classnames')

export class EditScoring extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      initialProps: [],
      initialStatus: '',
      initialFeatureImageId: 0,
      updateEnabled: true,
    }
  }

  componentDidMount = async () => {
    const { scoringTree, templates, initializeScoringForEdit } = this.props
    const { scoring } = scoringTree

    const initialProps = [...scoringTree.scoring.props]
    const initialStatus = scoringTree.scoring.status
    const initialFeatureImageId = scoringTree.header.featuredImg.id

    const { scope } = templates.list.filter(x => x.id === scoring.template_id)[0]

    await initializeScoringForEdit(scoringTree, scope)
    this.setState({
      initialized: true,
      initialProps,
      initialStatus,
      initialFeatureImageId,
    })
  }

  componentWillReceiveProps = async nextProps => {
    const { scoring, environment } = nextProps
    let allFieldsValidated = true

    for (let i = 0; i !== scoring.mandatoryProps.length; i += 1) {
      const propSlug = scoring.mandatoryProps[i]
      const propFilteredList = scoring.scoringProps.filter(x => x.slug === propSlug)
      let hasValue = false

      if (propFilteredList.length > 0) {
        const propObj = propFilteredList[0]

        if (propObj.type === scoringPropsTypeMap.datalist) {
          hasValue = !!propObj.value.body[environment.lang]
        } else if (propObj.type === scoringPropsTypeMap.text) {
          hasValue = !!propObj.value.body[environment.lang]
        } else if (propObj.type === scoringPropsTypeMap.distance) {
          hasValue = !!propObj.props[0].value.body[environment.lang]
        } else if (propObj.type === scoringPropsTypeMap.price) {
          const priceMinProp = _.find(propObj.props, prop => prop.slug === 'price-minimum')
          const priceMin = priceMinProp.value.body[environment.lang] ?? ''

          const priceMaxProp = _.find(propObj.props, prop => prop.slug === 'price-maximum')
          const priceMax = priceMaxProp.value.body[environment.lang] ?? ''

          const priceOfModelProp = _.find(propObj.props, prop => prop.slug === 'price-of-model')
          const priceofModel = priceOfModelProp.value.body[environment.lang] ?? ''

          hasValue = !!((priceMin.length > 0 && priceMax.length > 0) || priceofModel.length > 0)
        } else if (propObj.type === scoringPropsTypeMap.text_or_image) {
          hasValue = !!(
            (propObj.props[0] && propObj.props[0].value.body[environment.lang]) ||
            (propObj.props[1] && propObj.props[1].value.body[environment.lang])
          )
        }
      }

      if (!hasValue) {
        allFieldsValidated = false
      }
    }

    this.setState({ updateEnabled: allFieldsValidated })
  }

  render() {
    const {
      texts,
      device,
      scoringTree,
      updateScrollDisabledStatus,
      onClose,
      resetScoringPanelForRedux,
      restoreScoringInitialProps,
      updateScoringFeaturedImageAfterEdit,
      reloadScoringAfterEdit,
      processSyncQueue,
    } = this.props
    const { initialized, initialProps, initialStatus, initialFeatureImageId, updateEnabled } = this.state

    function performActionOnClosingEditPopup() {
      resetScoringPanelForRedux()
      updateScrollDisabledStatus(false)
      onClose()
    }

    function handleCancelButtonClicked() {
      const scoringId = scoringTree.scoring.id
      restoreScoringInitialProps(scoringId, initialStatus, initialProps)
      updateScoringFeaturedImageAfterEdit(scoringId, initialFeatureImageId, mediaCollectionsMap.cover_pictures, false)
      performActionOnClosingEditPopup()
    }

    async function handleSaveProductClicked() {
      if (!updateEnabled) {
        return
      }

      const scoringId = scoringTree.scoring.id

      await reloadScoringAfterEdit(scoringId)
      await updateScoringFeaturedImageAfterEdit(
        scoringId,
        initialFeatureImageId,
        mediaCollectionsMap.cover_pictures,
        true
      )

      await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, { id: scoringId })

      performActionOnClosingEditPopup()

      if (device.isOnline) {
        processSyncQueue()
      }
    }
    return (
      <div className={classNames({ edit_scoring_container: true })}>
        {initialized && (
          <div>
            <div className={classNames({ popup_background_layer: true })} />
            <div className={classNames({ popup_outer_container: true })}>
              <div className={classNames({ popup_inner_container: true })}>
                <div className={classNames({ popup_head: true })}>
                  <span className={classNames({ popup_title: true })}>{texts.edit_scoring}</span>
                  <span
                    role="button"
                    tabIndex={0}
                    className={classNames({
                      popup_cancel_btn: true,
                    })}
                    onClick={handleCancelButtonClicked}
                    onKeyPress={handleCancelButtonClicked}
                  >
                    {texts.cancel}
                  </span>
                </div>
                <div
                  className={classNames({
                    popup_body_container: true,
                    popup_body_container_edit_scoring: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  <ScoringSpecifyProduct />
                </div>
                <div
                  className={classNames({
                    popup_footer: true,
                    isSafari: device.isSafari,
                    isFirefox: device.isFirefox,
                    desk: device.screenType === screenTypesMap.desk,
                    ipadh: device.screenType === screenTypesMap.iPadH,
                    ipadv: device.screenType === screenTypesMap.iPadV,
                    iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                    iphonese: device.screenType === screenTypesMap.iPhoneSE,
                  })}
                >
                  <button
                    type="button"
                    className={classNames({
                      sm_btn: true,
                      theme_primary_btn: true,
                      float_right_btn: true,
                      btn_200: true,
                      disabled_btn: !updateEnabled,
                    })}
                    onClick={handleSaveProductClicked}
                  >
                    <CheckIcon />
                    <span>{texts.save_changes}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    device: state.device,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    templates: state.templates,
  }
}

export default connect(mapStateToProps, actionCreators)(EditScoring)
