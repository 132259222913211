import React from 'react'
import { connect } from 'react-redux'

import ChartPopup from '../_parts/chartPopup'
import LoadingBar from '../../../../loading_bar/index.js'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import { getProductName, calculatePopupChartHeight, doCalculationForAnalytics } from './common/utils'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class ScoreAreaDistributionChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showPopup: false,
      productName: '',
      data: {},
      allCriterion: {},
      colors: {
        score: 'rgb(113 196 138 / opacity%)',
        achievement: 'rgb(51 96 145 / opacity%)',
      },
    }
  }

  componentDidMount = async () => {
    this.initComponent()
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  initComponent = async () => {
    this.setState({ loading: true })

    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree
    const { nodes, props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setState({ productName })
    const duplicatedNodes = { ...nodes }
    await doCalculationForAnalytics(nodeDefsObj, duplicatedNodes)

    const areas = {}
    const areaIdList = nodeDefsObj[root].children_ids

    for (let i = 0; i !== areaIdList.length; i += 1) {
      const areaId = areaIdList[i]
      const area = nodeDefsObj[areaId]
      if (!area.bonus_demerit) {
        areas[areaId] = area
      }
    }

    function parseChildren(list, children, all) {
      for (let i = 0; i !== children.length; i += 1) {
        const nId = children[i]
        const nDef = nodeDefsObj[nId]
        const nInst = duplicatedNodes[nId]
        if (!nDef.bonus_demerit) {
          const { type } = nDef
          if (type === nodeDefinitionTypeMap.criterion) {
            list.count += 1
            if (nInst.is_enabled && !nInst.is_default) {
              list.scored += 1
              const cNode = duplicatedNodes[nId]
              const { score } = cNode
              if (!list[score]) {
                list[score] = []
              }
              list[score].push(cNode)

              if (!all[score]) {
                all[score] = []
              }
              all[score].push(cNode)
            }
          } else {
            const childrenList = nDef.children_ids
            parseChildren(list, childrenList, all)
          }
        }
      }
    }

    const data = {}
    const areaKeys = Object.keys(areas)
    const allCriterion = {}
    for (let i = 0; i !== areaKeys.length; i += 1) {
      const key = areaKeys[i]
      const area = areas[key]
      const instance = nodes[key]

      const criterionList = {
        count: 0,
        scored: 0,
        enabled: instance.is_enabled,
        achieved: instance.percentage,
      }
      const children = area.children_ids
      parseChildren(criterionList, children, allCriterion)

      data[key] = criterionList
    }

    this.setState({ data, allCriterion, loading: false })
  }

  componentDidUpdate = async prevProps => {
    const { root } = this.props

    const prevRoot = prevProps.root
    const reinitComponent = root !== prevRoot

    if (reinitComponent) {
      await this.initComponent()
    }
  }

  handleShowPopupClicked = () => {
    const { allCriterion } = this.state
    if (Object.keys(allCriterion).length === 0) return
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setState({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setState({ showPopup: false })
  }

  drawAreas = () => {
    const { colors, showPopup } = this.state
    const { scoringTree, root } = this.props
    const { nodeDefsObj } = scoringTree
    const { data } = this.state
    const children = nodeDefsObj[root].children_ids

    const html = []

    for (let i = 0; i !== children.length; i += 1) {
      const child = children[i]
      const childData = data[child]

      if (childData && childData.enabled) {
        const { achieved } = childData
        const def = nodeDefsObj[child]
        const { name } = def

        const { scored } = childData
        // const achieved = ((scored / count) * 100).toFixed(0)
        const achievedOpacity = Math.ceil(achieved / 10) * 10
        const achievedBg = colors.achievement.replace('opacity', achievedOpacity)

        const innerHtml = []
        for (let j = 0; j <= 4; j += 1) {
          const dataCount = childData[j] ? childData[j].length : 0
          const percentage = scored > 0 ? ((dataCount / scored) * 100).toFixed(0) : 0
          const opacity = Math.ceil(percentage / 10) * 10
          const bg = colors.score.replace('opacity', opacity)
          innerHtml.push(
            <div
              key={uuidv4()}
              className={classNames({
                sm: true,
                percentage_value: true,
                popup_version: showPopup,
              })}
              style={{ backgroundColor: bg }}
            >
              {percentage}
            </div>
          )
        }

        html.push(
          <div key={uuidv4()} className={classNames({ row: true })}>
            <div
              className={classNames({
                md: true,
                name: true,
                popup_version: showPopup,
              })}
            >
              {name.en}
            </div>
            <div
              className={classNames({
                sm: true,
                percentage_value: true,
                popup_version: showPopup,
              })}
              style={{ backgroundColor: achievedBg }}
            >
              {achieved.toFixed(0)}
            </div>
            {innerHtml}
          </div>
        )
      }
    }

    return html
  }

  render() {
    const { texts, scoringTree, root } = this.props
    const { loading, showPopup, productName, allCriterion } = this.state
    const { benchmarkName, selectedReferenceType, breadcrumb } = scoringTree.analytics

    const { nodeDefsObj } = scoringTree

    const theChart = (width, height) => {
      return (
        <div
          className={classNames({
            area_distribution_chart_container: true,
            full_width: showPopup === true,
          })}
          style={{ width, height }}
        >
          <div className={classNames({ row: true })}>
            <div className={classNames({ md: true, section_title: true })}>Area</div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_achieved: true,
                popup_version: showPopup,
              })}
            >
              % achieved
            </div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_0: true,
                popup_version: showPopup,
              })}
            >
              % of 0
            </div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_1: true,
                popup_version: showPopup,
              })}
            >
              % of 1
            </div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_2: true,
                popup_version: showPopup,
              })}
            >
              % of 2
            </div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_3: true,
                popup_version: showPopup,
              })}
            >
              % of 3
            </div>
            <div
              className={classNames({
                sm: true,
                head: true,
                head_4: true,
                popup_version: showPopup,
              })}
            >
              % of 4
            </div>
          </div>
          <div className={classNames({ row: true, title: true })}>
            <div className={classNames({ lg: true, colspan: true })}>{nodeDefsObj[root].name.en}</div>
            <div className={classNames({ sm: true })} />
            <div className={classNames({ sm: true })} />
            <div className={classNames({ sm: true })} />
            <div className={classNames({ sm: true })} />
            <div className={classNames({ sm: true })} />
            <div className={classNames({ sm: true })} />
          </div>
          {this.drawAreas()}
        </div>
      )
    }

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              score_distribution_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && showPopup === false && Object.keys(allCriterion).length === 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              score_area_outer_container: true,
            })}
          >
            <div className={classNames({ chart_inner_container: true })} style={{ height: 216 }}>
              <div className={classNames({ no_data: true })}>{texts.no_data_to_render}</div>
            </div>
          </div>
        )}
        {loading === false && showPopup === false && Object.keys(allCriterion).length > 0 && (
          <div
            className={classNames({
              chart_outer_container: true,
              score_area_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', '100%')}</div>
          </div>
        )}
        {showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.score_distribution_per_area}
            width="100%"
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={selectedReferenceType === analyticsRefsTypesMap.benchmark ? benchmarkName : false}
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoreAreaDistributionChart)
