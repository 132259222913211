/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import * as actionCreators from '../../../../store/actions'
import ViewComment from './viewComment'
import AddComment from './addComment'
import EditComment from './editComment'
import DragAndDropSmallIcon from '../../../../assets/svg/drag-icon-small-white.svg'

const arrayMove = require('array-move')

const classNames = require('classnames')

export class NodeComments extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedComments: [],
    }
  }

  componentDidMount = () => {
    const { comments, scoreSet, nodeDefId } = this.props
    const sortedComments = this.sortComments(comments, nodeDefId, scoreSet)
    this.setState({ sortedComments })
  }

  componentDidUpdate(oldProps) {
    const { comments, nodeDefId, scoreSet } = this.props
    if (comments !== oldProps.comments) {
      const sortedComments = this.sortComments(comments, nodeDefId, scoreSet)
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sortedComments })
    }
  }

  sortComments = (comments, nodeDefId, scoreSet) => {
    let sortedComments = []
    if (comments[nodeDefId]) {
      sortedComments =
        scoreSet && scoreSet === 'forecast'
          ? _.sortBy(
              _.filter(comments[nodeDefId], item => item.is_forecast),
              'order'
            )
          : _.sortBy(
              _.filter(comments[nodeDefId], item => !item.is_forecast),
              'order'
            )
    }

    return sortedComments
  }

  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { device, processSyncQueue, changeCommentsOrder } = this.props
    const { sortedComments } = this.state
    this.setState({ sortedComments: arrayMove(sortedComments, oldIndex, newIndex) })
    await changeCommentsOrder(sortedComments, oldIndex, newIndex)

    if (device.isOnline) {
      processSyncQueue()
    }
  }

  render() {
    const { scoreSet, newComments, nodeDefId, authentication, productIsLocked } = this.props

    const { sortedComments } = this.state

    const DragHandle = SortableHandle(() => (
      <div
        className={classNames({
          drag_btn_small: true,
          sctree_rp_item_node_exp_comment_action_btn: true,
          sctree_rp_item_node_exp_comment_action_move: true,
        })}
      >
        <DragAndDropSmallIcon />
      </div>
    ))

    function commentsList() {
      const list = []
      sortedComments.map((comment, i) => {
        list.push(
          <div
            key={i}
            onClick={e => e.stopPropagation()}
            onKeyPress={e => e.stopPropagation()}
            className={classNames({
              sctree_rp_item_node_exp_comment_container: true,
              sctree_rp_item_node_exp_comment_view_container: !comment.editing,
              sctree_rp_item_node_exp_comment_edit_container: comment.editing,
            })}
          >
            {!comment.editing && <ViewComment comment={comment} DragHandle={DragHandle} />}
            {comment.editing && <EditComment comment={comment} />}
          </div>
        )

        return null
      })

      return list
    }

    const SortableItem = SortableElement(({ value }) => <>{value}</>)

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div
          className={classNames({
            sctree_rp_item_node_exp_comments_list: true,
          })}
          onClick={e => e.stopPropagation()}
          onKeyPress={e => e.stopPropagation()}
        >
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      )
    })

    return (
      <div>
        {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
          <div
            className={classNames({
              sctree_rp_item_node_exp_add_comment_container: true,
            })}
          >
            {newComments[nodeDefId] && <AddComment scoreSet={scoreSet || ''} nodeDefId={nodeDefId} />}
          </div>
        )}
        <div className="sortable_list_wrapper">
          <SortableList
            items={commentsList()}
            onSortEnd={this.onSortEnd}
            axis="y"
            lockAxis="y"
            distance={5}
            useDragHandle
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    authentication: state.authentication,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    newComments: state.comments.newComments,
    comments: state.comments.comments,
  }
}

export default connect(mapStateToProps, actionCreators)(NodeComments)
